import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { UserType } from '../core/userType.model';
import { DomSanitizer } from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
import { RoleGuardService } from "../core/auth/role-guard.service";
@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
})
export class MenuComponent implements OnInit {
  public userTypeId;
  _apiUrl: string;
  clientId: number;
  menuItemList = [];
  userbaseTypeId: number;
  userRole: string;
  encryptSecretKey: string;
  constructor(
    private authService: AuthService,
    private appConfig : AppConfig,
    private _changeDetectionRef: ChangeDetectorRef,
    protected sanitizer: DomSanitizer,
    @Inject('EncryptionKey') EncryptionKey: string,
    private roleGuard: RoleGuardService
  ) {
    this.userTypeId = atob(localStorage.getItem("USER_TYPE_ID"));
    this.userbaseTypeId = parseInt(atob(localStorage.getItem("USER_BASE_TYPE_ID")));
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userRole = atob(localStorage.getItem("userRole"));
    this.encryptSecretKey=EncryptionKey;
  }

  ngOnInit() {
    this.getMenuItemList();
  }

  getMenuItemList() {
    var isWorkshopUser = false;
    //check for workshopuser
    if (this.userbaseTypeId === UserType.WorkShop) {
      isWorkshopUser = true;
    }

    this.authService
      .get(this._apiUrl + "/api/v1/RouteConfigs/GetRouteDetails")
      .subscribe(
        (response) => {
          if (response) {
            this.menuItemList=response;
            this.roleGuard.setRouteData(response);
            // response.forEach((i) => this.menuQuerCheck(i));
            this._changeDetectionRef.detectChanges();
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }


  menuQuerCheck(item) {
    if (item.route.split('?').length > 1) {
      // if (item.route.split('?')[1].split('&').length > 1) {
      //   ///to-do logic when more query params exists
      // }
      item.routename = item.route.split('?')[1].split('=')[0];
      item.routepath = item.route.split('?')[1].split('=')[1];
    }
    this.menuItemList.push(item);
  }
}
