<div nxLayout='grid' class="c-header-container" [style.background-color]="backgroundColor">
    <div nxRow nxRowJustify="center">
      <div nxCol="12">
        <div class="c-header-title-container" [attr.id]="id" [style.padding]="padding">          
            <h1 nxHeadline="page"[style.color]="titleColor" [innerHTML]="desktopTitle" class="nx-margin-0"></h1>
            <h3 nxHeadline="subsection-xsmall" [style.color]="subTitleColor" [attr.id]="subId" class="nx-margin-top-2xs">{{ description}}</h3>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
 </div>
  