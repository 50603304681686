import { Component, OnInit, Inject, Input } from '@angular/core';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { FormGroup, FormControl } from '@angular/forms';
import { PolicyStatus } from '../core/policyStatus.model';
import * as XLSX from 'xlsx';
import { workshopPolicySaleReportModel } from './workshopPolicySaleReport.model';
import { SortEvent, SortDirection } from '@allianz/ngx-ndbx/table';
import { NxMessageToastService } from "@allianz/ngx-ndbx/message";
import * as moment from 'moment';

@Component({
  selector: 'app-workshopreport',
  templateUrl: './workshopreport.component.html',
  styleUrls: ['./workshopreport.component.css']
})
export class WorkshopreportComponent implements OnInit {
  /**Private variables */
  _apiUrl: string;

  userId: number;
  clientId: number;
  Models: any;
  workshopReportForm: FormGroup;
  policyStatus: any;
  exportdata: any = [];
  policyList: any;
  policyExcelList: any;
  reportType: string;
  fileName: string = "WorkshopPolicySaleReport_" + new Date(Date.now()).toLocaleString().split(',')[0] + '.xlsx';

  /**Pagination Start */
  count: number = 0;
  pageNumber: number = 1;
  pageSize: number = 10;

  prevPage() {
    this.pageNumber--;
    this.getWorkShopReport(this.reportType);
  }

  nextPage() {
    this.pageNumber++;
    this.getWorkShopReport(this.reportType);
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getWorkShopReport(this.reportType);
  }
  /**Pagination end */
  /** sorting starts here */

  sortTable(sort: SortEvent) {//Sorts the table data by a certain category.
    this.policyList = this.policyList.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection) {

    return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
  }
  /** sorting ends here */

  constructor(private authService: AuthService, private appConfig : AppConfig, private messageToastService: NxMessageToastService) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.getModelsList();
    this.policyStatus = PolicyStatus;
  }

  ngOnInit() {
    this.workshopReportFormBuilder();
  }

  workshopReportFormBuilder() {
    this.workshopReportForm = new FormGroup({
      startDatepicker: new FormControl(null),
      endDatepicker: new FormControl(null),
      policyNo: new FormControl(null),
      policyType: new FormControl(new Array<number>()),
      VehicleAge: new FormControl(-1),
      model: new FormControl(null),
      ClientId: new FormControl(this.clientId),
      WorkshopId: new FormControl(null),
    });
  }

  OnChangeReportType(value: string) {
    this.reportType = value;
  }

  getModelsList() {
    this.authService.get(this._apiUrl + '/api/v1/Models').subscribe(response => {
      if (response) {
        this.Models = response;
      }
    }, (error) => {
      console.error(error);
    });
  }

  getWorkShopReport(type: any) {
    if (type === undefined)
      this.messageToastService.open("Please select a report type !", { context: "info", duration: 5000 });
    else if (type == "policysales") {
      this.SearchPolicies();
    }
  }

  SearchPolicies(pagecount: number = 0) {
    this.workshopReportForm.value.startDatepicker != null ? this.workshopReportForm.patchValue({ "startDatepicker": this.workshopReportForm.value.startDatepicker }) : null;
    this.workshopReportForm.value.endDatepicker != null ? this.workshopReportForm.patchValue({ "endDatepicker": this.workshopReportForm.value.endDatepicker }) : null;
    this.authService.PostList(this._apiUrl + `/api/v1/Report/GetPolicySalesReport?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`, this.workshopReportForm.value)
      .subscribe(response => {
        if (response) {
          var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
          this.count = pagingProp.TotalCount;
          this.policyList = response.body;
          // this.policyList.forEach(element => this.setpoliciexportdata(element));
        }
      }, (error) => {
        console.error(error);
      });
  }

  reset() {
    this.workshopReportFormBuilder();
    this.getWorkShopReport(this.reportType);
  }

  exporttoexcel(): void {
    this.workshopReportForm.value.startDatepicker != null ? this.workshopReportForm.patchValue({ "startDatepicker": this.workshopReportForm.value.startDatepicker }) : null;
    this.workshopReportForm.value.endDatepicker != null ? this.workshopReportForm.patchValue({ "endDatepicker": this.workshopReportForm.value.endDatepicker }) : null;
    this.authService.PostList(this._apiUrl + `/api/v1/Report/GetPolicySalesExcelReport`, this.workshopReportForm.value)
      .subscribe(response => {
        if (response) {
          // var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
          // this.count = pagingProp.TotalCount;
          this.policyExcelList = response.body;
          this.policyExcelList.forEach(element => this.setpoliciexportdata(element));
          /* json data is passed over here */
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportdata);
          ws.A1.v = "SI NO";
          ws.B1.v = "Dealer/Worshop Code";
          ws.C1.v = "Dealer/Worshop Name";
          ws.D1.v = "ASM";
          ws.E1.v = "RSM";
          ws.F1.v = "Policy NO";
          ws.G1.v = "VIN NO";
          ws.H1.v = "License Plate NO";
          ws.I1.v = "Model";
          ws.J1.v = "Policy Start Date";
          ws.K1.v = "Policy End Date";
          ws.L1.v = "Policy Status";
          ws.M1.v = "Zone";
          ws.N1.v = "Price";
          ws.O1.v = "Customer Name";
          ws.P1.v = "Customer Mobile No";

          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'WorkshopReport');

          /* save to file */
          XLSX.writeFile(wb, this.fileName);
        }
      }, (error) => {
        console.error(error);
      });


  }
  setpoliciexportdata(item) {
    // console.log(item);
    var data: any = [];
    data.SINo = item.policyDetails.id
    data.DealerORWorkshopCode = item.workshopCode == null ? item.dealerCode : item.workshopCode
    data.DealerORWorkshopName = item.name
    data.Asm_Name = item.asmName
    data.Rsm_Name = item.rsmName
    data.policyNo = item.policyDetails.policyNo
    data.vinChassisNo = item.policyDetails.vinChassisNo
    data.LicensePlateNO = item.policyDetails.licensePlateNo
    data.model = item.policyDetails.model
    data.effectiveDate = item.policyDetails.effectiveDate
    data.expiryDate = item.policyDetails.expiryDate
    if (item.policyDetails.policyStatusId == PolicyStatus.Active) {
      data.policyStatus = "Active"
    }
    else if (item.policyDetails.policyStatusId == PolicyStatus.Cancel) {
      data.policyStatus = "Cancel"
    }
    else if (item.policyDetails.policyStatusId == PolicyStatus.Modified) {
      data.policyStatus = "Modified"
    }
    else if (item.policyDetails.policyStatusId == PolicyStatus.CancelationApprovalPending) {
      data.policyStatus = "Cancelation Approval Pending"
    }
    else if (item.policyDetails.policyStatusId == PolicyStatus.ModificationApprovalPending) {
      data.policyStatus = "Modification Approval Pending"
    }
    data.Zone = item.zonename
    data.unitPrice = item.policyDetails.unitPrice
    data.customerName = item.policyDetails.customerFirstName + " " + item.policyDetails.customerLastName
    data.MobileNumber = item.policyDetails.customerMobileNo

    // data.invoiceNo = item.policyDetails.invoiceNo
    // data.colorName = item.policyDetails.colorName
    // data.policyName = item.policyDetails.policyName

    // data.paymentdate = item.policyDetails.paymentDate


    this.exportdata.push(data);
  }

}


