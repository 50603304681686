import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { SortDirection, SortEvent } from '@allianz/ngx-ndbx/table';

@Component({
  selector: 'app-statemaster',
  templateUrl: './statemaster.component.html',
  styleUrls: ['./statemaster.component.css']
})
export class StatemasterComponent implements OnInit {
  stateModalOpen: boolean = false;
  confirmModalOpen: boolean = false;
  stateForm: FormGroup;
  _apiUrl: string;
  clientId: number;
  stateList = [];
  isSuccess: boolean = false;
  EDIT_FLAG: boolean;
  id;
  stateDetails ={};
/** sorting starts here */

sortTable(sort: SortEvent) {//Sorts the table data by a certain category.
  this.stateList = this.stateList.sort((a, b) => {
    if (sort.active in a && sort.active in b) {
      return this.compare(a[sort.active], b[sort.active], sort.direction);
    }
    return 0;
  });
}

compare(
  a: number | string | Date,
  b: number | string | Date,
  direction: SortDirection) {

  return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
}
/** sorting ends here */

/**Pagination Start */
count: number;
pageNumber: number = 1;
pageSize: number = 10;
searchTerm: any = "";
prevPage() {
  this.pageNumber--;
  this.getStateList();
}

nextPage() {
  this.pageNumber++;
  this.getStateList();
}

goToPage(n: number) {
  this.pageNumber = n;
    this.getStateList();
}
/**Pagination end */
  constructor(private authService: AuthService, private appConfig : AppConfig) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.stateFormBuilder();
  }

  ngOnInit() {
    this.getStateList();
  }

  stateFormBuilder() {
    this.stateForm = new FormBuilder().group({
      stateName: ['', Validators.required],
      clientId: this.clientId,
      id: '',
      isActive: 1,

    });
  }

  getStateList() {
    this.authService.getList(this._apiUrl + '/api/v1/States' + "?pageNumber=" + this.pageNumber + "&pageSize=" + this.pageSize + "&searchText=" + this.searchTerm).subscribe(response => {
      if (response) {
        this.stateList = response.body;
        var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
        this.count = pagingProp.TotalCount;
      }
    }, (error) => {
      console.error(error);
    });
  }
  toggleModal(EDIT: boolean, stateId) {
    this.EDIT_FLAG = EDIT;
    this.id = stateId;
    this.stateModalOpen = !this.stateModalOpen;
    this.isSuccess = false;
    if (this.EDIT_FLAG) {
      this.getStateDetails(stateId);
    }
    if (!this.stateModalOpen) {
      this.stateFormBuilder();
    }
  }
  addStateMaster() {

    this.authService.post(this._apiUrl + '/api/v1/States', this.stateForm.value).subscribe(response => {
      if (response) {
        this.getStateList();
        this.isSuccess = true;
      }
    }, (error) => {
      console.error(error);
    });
  }
  getStateDetails(stateId) {
    this.authService.get(this._apiUrl + '/api/v1/States/' + stateId).subscribe(response => {
      if (response) {
        // console.log(response);
        this.stateForm.setValue({
          stateName: response.stateName,
          id: response.id,
          clientId: response.clientId,
          isActive: response.isActive,

        })
      }
    }, (error) => {
      console.error(error);
    });
  }
  updateStateMaster() {

    this.authService.put(this._apiUrl + '/api/v1/States/' + this.id, this.stateForm.value).subscribe(response => {
      // if (response) {
      this.getStateList();
      this.isSuccess = true;
      // }
    }, (error) => {
      console.error(error);
    });
  }

  confirmModalClose() {
    this.confirmModalOpen = false;
    this.getStateList();
    this.stateFormBuilder();
  }

  confirmModal(stateDetails, isChecked: boolean) {
    let isActive;
    this.confirmModalOpen = true;

    if (isChecked) {
      isActive = 1;
    } else {
      isActive = 0;
    }

    this.stateForm.setValue({
      stateName: stateDetails.stateName,
      id: stateDetails.id,
      clientId: this.clientId,
      isActive: isActive,

    })
  }

  changeStateStatus(){
   this.stateForm.value
    this.authService.put(this._apiUrl + '/api/v1/States/' + this.stateForm.controls.id.value, this.stateForm.value).subscribe(response => {
      // if (response) {
      this.getStateList();
      this.confirmModalClose();

      // }
    }, (error) => {
      console.error(error);
    });
  }


}
