<nx-selectable-card>
  <nx-card-header>
    <app-title
      [desktopTitle]="'Customer Details'"
      [padding]="'30px 0px 5px 0px'"
    ></app-title>
    <hr />
  </nx-card-header>

  <div nxLayout="grid" class="card-content">
    <form [formGroup]="customerDetailForm">
      <div nxRow nxRowAlignItems="center,center,center,center">
        <div nxCol="12,12,4,2">
          <figure nxFigure="1by1">
            <img
              src="/assets/illustrations/illustrationServiceProtectionJuridiqueExpertise.eps.png"
              alt="Image Not Found"
            />
          </figure>
        </div>

        <div nxCol="12,12,4,2" style="text-align: -webkit-center">
          <button
            class="navigation"
            nxIconButton="tertiary"
            aria-label="prev page"
            type="button"
            (click)="PrevPage()"
            [routerLink]="['/sellpolicy']"
          >
            <ul class="iconlist iconlist__item">
              <nx-icon size="l" name="chevron-left-circle-o"></nx-icon
              ><span style="font-size: small">Prev</span>
            </ul>
          </button>
        </div>

        <div nxCol="12,12,6">
          <div
            nxRow
            nxRowJustify="start,start,around"
            nxRowAlignContent="center,center"
          >
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="First Name ">
                <nx-formfield-label class="required"
                  >First Name
                </nx-formfield-label>
                <input
                  nxInput
                  #FirstnameCount
                  maxlength="50"
                  formControlName="customerFirstName"
                />
                <span nxFormfieldHint>
                  <span *ngIf="FirstnameCount.value?.length === 0">
                    max 50 characters
                  </span>
                  <span *ngIf="FirstnameCount.value.length > 0">
                    {{ 50 - (FirstnameCount.value.length || 0) }} characters
                    remaining
                  </span>
                </span>
                <nx-error nxFormfieldError>
                  <div *ngIf="f.customerFirstName.errors?.required"><strong>Please note:</strong> This field is required!</div>
                  <div *ngIf="f.customerFirstName.errors?.pattern"><strong>Please note:</strong>Enter Valid Name.
                    <br>Allows Alphabets only</div>
                </nx-error>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="Middle Name">
                <input nxInput formControlName="customerMiddleName" />
                <nx-error nxFormfieldError>
                  <div *ngIf="f.customerMiddleName.errors?.pattern"><strong>Please note:</strong>Enter Valid Name.
                    <br>Allows Alphabets only</div>
                </nx-error>
              </nx-formfield>
            </div>
          </div>

          <div
            nxRow
            nxRowJustify="start,start,around"
            nxRowAlignContent="center,center"
          >
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="Last Name">
                <nx-formfield-label class="required"
                  >Last Name
                </nx-formfield-label>
                <input
                  nxInput
                  #LastnameCount
                  maxlength="50"
                  formControlName="customerLastName"
                />
                <span nxFormfieldHint>
                  <span *ngIf="LastnameCount.value?.length === 0">
                    max 50 characters
                  </span>
                  <span *ngIf="LastnameCount.value.length > 0">
                    {{ 50 - (LastnameCount.value.length || 0) }} characters
                    remaining
                  </span>
                </span>
                <nx-error nxFormfieldError>
                  <div *ngIf="f.customerLastName.errors?.required"><strong>Please note:</strong> This field is required!</div>
                  <div *ngIf="f.customerLastName.errors?.pattern"><strong>Please note:</strong>Enter Valid Name.
                    <br>Allows Alphabets only</div>
                </nx-error>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="Home Phone Number">
                <input nxInput formControlName="customerPhoneNo" />
              </nx-formfield>
            </div>
          </div>

          <div
            nxRow
            nxRowJustify="start,start,around"
            nxRowAlignContent="center,center"
          >
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="GST Number">
                <input
                  #custGstNumber
                  nxInput
                  formControlName="custGstNumber"
                  (keyup)="
                    custGstNumber.value = custGstNumber.value.toUpperCase()
                  "
                  (focusout)="
                    custGstNumber.value = custGstNumber.value.toUpperCase()
                  "
                />
                <nx-error nxFormfieldError>
                  <div *ngIf="f.custGstNumber.errors?.pattern">
                    <strong>Please note:</strong>Invalid GST number.(use block
                    letters only)
                  </div>
                </nx-error>
                <span nxFormfieldHint>
                  <span style="color: orange">warning: </span>Wrong GST Number
                  might <br />lead to non-issuance of Invoice
                </span>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="Mobile Number">
                <nx-formfield-label class="required"
                  >Mobile Number</nx-formfield-label
                >
                <input nxInput formControlName="customerMobileNo" />
                <nx-error nxFormfieldError>
                  <div *ngIf="f.customerMobileNo.errors?.required">
                    <strong>Please note:</strong> This field is required!
                  </div>
                  <div *ngIf="f.customerMobileNo.errors?.pattern">
                    <strong>Please note:</strong> Invalid mobile number!
                  </div>
                </nx-error>
              </nx-formfield>
            </div>
          </div>

          <div
            nxRow
            nxRowJustify="start,start,around"
            nxRowAlignContent="center,center"
          >
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="Email">
                <nx-formfield-label class="required">Email</nx-formfield-label>
                <input nxInput formControlName="customerEmail" maxlength="50" #Email type="email"/>
                <span nxFormfieldHint>
                  <span *ngIf="Email.value?.length === 0">
                    max. 50 characters
                  </span>
                  <span *ngIf="Email.value.length > 0">
                    {{ 50 - (Email.value.length || 0) }} characters
                    remaining
                  </span>
                </span>
                <nx-error nxFormfieldError>
                  <div *ngIf="f.customerEmail.errors?.required">
                    <strong>Please note:</strong> This field is required!
                  </div>
                  <div *ngIf="f.customerEmail.errors?.email">
                    <strong>Please note:</strong> Invalid email format!
                  </div>
                </nx-error>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6">
              <nx-formfield>
                <nx-formfield-label class="required"
                  >Customer Address</nx-formfield-label
                >
                <textarea
                  nxInput
                  cdkTextareaAutosize
                  #inputToCount
                  maxlength="45"
                  formControlName="customerAddress"
                ></textarea>
                <span nxFormfieldHint>
                  <span *ngIf="inputToCount.value?.length === 0">
                    max. 45 characters
                  </span>
                  <span *ngIf="inputToCount.value.length > 0">
                    {{ 45 - (inputToCount.value.length || 0) }} characters
                    remaining
                  </span>
                </span>
                <nx-error nxFormfieldError>
                  <div *ngIf="f.customerAddress.errors?.required">
                    <strong>Please note:</strong> This field is required!
                  </div>
                  <div *ngIf="f.customerAddress.errors?.pattern"><strong>Please note:</strong>Enter Valid Address.
                    <br>Allowed charcters are -_,/.&lt;space&gt; and Alpha numerics</div>
                </nx-error>
              </nx-formfield>
            </div>
          </div>

          <div
            nxRow
            nxRowJustify="start,start,around"
            nxRowAlignContent="center,center"
          >
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="State">
                <nx-formfield-label class="required">State</nx-formfield-label>
                <nx-dropdown
                  formControlName="state"
                  (selectionChange)="getStateWiseCityList($event.value)"
                >
                  <nx-dropdown-item
                    *ngFor="let item of stateList | isActiveCheck"
                    [nxValue]="item.id"
                  >
                    {{ item.stateName }}</nx-dropdown-item
                  >
                </nx-dropdown>
                <nx-error nxFormfieldError>
                  <div *ngIf="f.state.errors?.required">
                    <strong>Please note:</strong> This field is required!
                  </div>
                </nx-error>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="City">
                <nx-formfield-label class="required">City</nx-formfield-label>
                <nx-dropdown formControlName="city">
                  <nx-dropdown-item
                    *ngFor="let item of cityList | isActiveCheck"
                    [nxValue]="item.id"
                  >
                    {{ item.cityName }}
                  </nx-dropdown-item>
                </nx-dropdown>
                <nx-error nxFormfieldError>
                  <div *ngIf="f.city.errors?.required">
                    <strong>Please note:</strong> This field is required!
                  </div>
                </nx-error>
              </nx-formfield>
            </div>
          </div>

          <div
            nxRow
            nxRowJustify="start,start,around"
            nxRowAlignContent="center,center"
          >
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="PIN Code">
                <nx-formfield-label class="required"
                  >PIN Code</nx-formfield-label
                >
                <input nxInput formControlName="pincode" nxMask="000000" />
                <nx-error nxFormfieldError>
                  <div *ngIf="f.pincode.errors?.required">
                    <strong>Please note:</strong> This field is required!
                  </div>
                  <div *ngIf="f.pincode.errors?.pattern">
                    <strong>Please note:</strong> Invalid PIN Code
                  </div>
                </nx-error>
                <span nxFormfieldHint> eg: 002215 </span>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="Company Name" *ngIf="false">
                <input nxInput formControlName="customerCompany" />
              </nx-formfield>
            </div>
          </div>
        </div>

        <div nxCol="12,12,4,2" style="text-align: -webkit-center">
          <button
            class="navigation"
            nxIconButton="tertiary"
            aria-label="next page"
            type="button"
            [disabled]="customerDetailForm.invalid"
            (click)="saveCustomerDetails()"
          >
            <ul class="iconlist iconlist__item">
              <nx-icon size="l" name="chevron-right-circle-o"></nx-icon
              ><span style="font-size: small">Next</span>
            </ul>
          </button>
        </div>
      </div>
    </form>
  </div>

  <nx-card-footer>
    <nx-link>
      <a>
        <nx-icon name="play"></nx-icon>
        Step 2/5
      </a>
    </nx-link>
  </nx-card-footer>
</nx-selectable-card>
