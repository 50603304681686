import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { sellPolicyData } from "../addpolicy/sellpolicyData.model";
import { DatastorageService } from "../datastorage.service";
import { MESSAGE_CONSTS } from "../ValidationMessages";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import { Router } from "@angular/router";
import { Step } from "../core/step.model";

@Component({
  selector: "app-customerdetails",
  templateUrl: "./customerdetails.component.html",
  styleUrls: ["./customerdetails.component.css"],
})
export class CustomerdetailsComponent implements OnInit {
  customerDetailForm: FormGroup;
  sellPolicyData: sellPolicyData = new sellPolicyData();
  _apiUrl: string;
  clientId: number;
  stateList = [];
  cityList = [];
  step: Step = new Step();
  allcityList: any;

  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,
    private dataService: DatastorageService,
    private router: Router
  ) {
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this._apiUrl = appConfig.baseUrl;
  }

  ngOnInit() {
    //this.getStateList();
    //this.getCityList();
    this.customerDetailFormBuild();
    this.initializeNav(0);
  }
  ngAfterViewInit() {
    this.getStateList();
    this.getCustomerDetails();
  }

  initializeNav(compltedStep: number) {
    for (let i = 0; i <= compltedStep; i++) {
      this.step.stepNumber = i;
      this.step.completed = true;
      this.dataService.nextClick.next(this.step);
    }
  }

  customerDetailFormBuild() {
    this.customerDetailForm = new FormBuilder().group({
      customerFirstName: new FormControl(
        this.sellPolicyData.customerFirstName,
        [Validators.required,
        Validators.pattern(MESSAGE_CONSTS.NAME_REGEX)
      ]),
      customerMiddleName: new FormControl(this.sellPolicyData.customerMiddleName,[Validators.pattern(MESSAGE_CONSTS.NAME_REGEX)]),
      customerLastName: new FormControl(
        this.sellPolicyData.customerLastName,
        [Validators.required,
        Validators.pattern(MESSAGE_CONSTS.NAME_REGEX)
      ]),
      customerPhoneNo: this.sellPolicyData.customerPhoneNo,
      custGstNumber: new FormControl(
        this.sellPolicyData.custGstNumber?.toUpperCase(),
        [Validators.pattern(MESSAGE_CONSTS.GSTNO_REGEX)]
      ),
      customerMobileNo: new FormControl(this.sellPolicyData.customerMobileNo, [
        Validators.required,
        Validators.pattern(MESSAGE_CONSTS.MOBILE_REGEX),
      ]),
      customerEmail: new FormControl(this.sellPolicyData.customerEmail, [
        Validators.required,
        Validators.email,
      ]),
      customerCompany: this.sellPolicyData.customerCompany,
      state: [this.sellPolicyData.stateId, Validators.required],
      city: [this.sellPolicyData.cityId, Validators.required],
      pincode: [
        this.sellPolicyData.pincode,
        [Validators.required, Validators.pattern(MESSAGE_CONSTS.PINCODE_REGEX)],
      ],
      customerAddress: new FormControl(
        this.sellPolicyData.customerAddress,
        [Validators.required,
        Validators.pattern(MESSAGE_CONSTS.ADDRESS_REGEX)
      ]),
    });
  }

  get f() {
    return this.customerDetailForm.controls;
  }

  saveCustomerDetails() {
    this.sellPolicyData.customerFirstName =
      this.customerDetailForm.get("customerFirstName").value;
    this.sellPolicyData.customerMiddleName =
      this.customerDetailForm.get("customerMiddleName").value;
    this.sellPolicyData.customerLastName =
      this.customerDetailForm.get("customerLastName").value;
    this.sellPolicyData.customerPhoneNo =
      this.customerDetailForm.get("customerPhoneNo").value;
    this.sellPolicyData.custGstNumber =
      this.customerDetailForm.get("custGstNumber").value;
    this.sellPolicyData.customerMobileNo =
      this.customerDetailForm.get("customerMobileNo").value;
    this.sellPolicyData.customerEmail =
      this.customerDetailForm.get("customerEmail").value;
    this.sellPolicyData.customerCompany =
      this.customerDetailForm.get("customerCompany").value;
    this.sellPolicyData.customerAddress =
      this.customerDetailForm.get("customerAddress").value;
    this.sellPolicyData.cityId = this.customerDetailForm.get("city").value;
    this.sellPolicyData.stateId = this.customerDetailForm.get("state").value;
    this.sellPolicyData.pincode = this.customerDetailForm.get("pincode").value;
    this.dataService.setData(this.sellPolicyData);
    //this.router.navigate(['/sellpolicy/customerdetails']);
    this.router.navigate(["/sellpolicy/plandetails"]);
    this.step.stepNumber = 1;
    this.step.completed = true;
    this.dataService.nextClick.next(this.step);
  }

  getStateList() {
    this.authService
      .get(this._apiUrl + `/api/v1/States?pageNumber=-1`)
      .subscribe(
        (response) => {
          if (response) {
            this.stateList = response;

            if (
              !parseInt(this.sellPolicyData.state) &&
              parseInt(this.sellPolicyData.state) != 0 &&
              this.sellPolicyData.state != undefined
            ) {
              var selectedState = this.stateList.filter((obj) => {
                if (
                  obj.stateName.toLowerCase() ==
                  this.sellPolicyData.state.toLowerCase()
                )
                  return obj;
              });
              this.sellPolicyData.stateId =
                selectedState.length == 0 ? null : selectedState[0].id;
              this.customerDetailForm.patchValue({
                state: selectedState.length == 0 ? null : selectedState[0].id,
              });
            }
            this.getStateWiseCityList(this.sellPolicyData.stateId);
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getCityList() {
    this.authService
      .get(this._apiUrl + `/api/v1/Cities?pageNumber=-1`)
      .subscribe(
        (response) => {
          if (response) {
            this.cityList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getStateWiseCityList(stateId) {
    if (stateId == undefined || stateId == null) {
      this.customerDetailForm.patchValue({
        city: null,
      });
      return;
    }

    this.authService
      .get(this._apiUrl + "/api/v1/Cities/state/" + stateId)
      .subscribe(
        (response) => {
          if (response) {
            this.cityList = response;
            if (
              !parseInt(this.sellPolicyData.city) &&
              parseInt(this.sellPolicyData.city) != 0 &&
              this.sellPolicyData.city != undefined
            ) {
              let selectedcity = this.cityList.filter((obj) => {
                if (
                  obj.cityName.toLowerCase() ==
                  this.sellPolicyData.city.toLowerCase()
                )
                  return obj;
              });
              this.sellPolicyData.cityId =
                selectedcity.length == 0 ? null : selectedcity[0].id;
              this.customerDetailForm.patchValue({
                city: selectedcity.length == 0 ? null : selectedcity[0].id,
              });
            }
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getCustomerDetails() {
    let resp = this.dataService.getData();
    if (resp) {
      this.sellPolicyData = this.dataService.getData();
      this.sellPolicyData.customerMobileNo = (
        this.sellPolicyData.customerMobileNo ?? ""
      ).trim();
      this.sellPolicyData.pincode = (this.sellPolicyData.pincode ?? "").trim();
      this.customerDetailFormBuild();
      if (resp.customerFirstName != undefined) {
        //trigger validations
        this.customerDetailForm.markAllAsTouched();
      }
    }
  }

  PrevPage() {
    this.step.stepNumber = 0;
    this.step.completed = false;
    this.dataService.nextClick.next(this.step);
  }
}
