import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  _apiUrl;
  userName;
  userEmail;
  userRole;
  userId;
  walletBalance: number;
  private isAuthorizedSubscription: Subscription = new Subscription();
  public isAuthorized = false;
  DealerReg: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private appConfig : AppConfig
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.userName = localStorage.getItem("USER_NAME");
    this.userEmail = localStorage.getItem("USER_EMAIL");
    this.userRole = atob(localStorage.getItem("userRole"));
    this.DealerReg = (localStorage.getItem("DealerReg")=='true' && this.userRole=='Dealer');
  }

  ngOnInit() {}
  public logOutUser() {
    this.authService.Logout(localStorage.getItem("IDENTIFIER"));
    this.authService.resetcredentials();
    this.router.navigate([""]);
  }
}
