<div class="container" [ngClass]="branding()">
  <div nxLayout="grid">
    <div nxRow nxRowJustify="around" *ngIf="showNotification">
      <div nxCol="10" class="nx-margin-x-xl">
        <nx-message nxContext="info">
          <span class="nx-font-weight-bold">Notification:</span><br />
          {{marqueetext}}
        </nx-message>
      </div>
    </div>
  </div>
  <div nxLayout="grid">
    <div nxRow>
      <div nxCol="12,12,6,4" class="form-signin nx-margin-top-5xl" style="overflow-x:hidden">
        <form [formGroup]="loginForm">
          <div nxRow nxRowJustify="around" class="nx-margin-top-2m">
            <nx-header-brand>
              <nx-link>
                <a href="#">
                  <figure nxFigure>
                    <img src='assets/APlogo.png' alt="Allianz Global Brand Logo" role="none">
                  </figure>
                </a>
              </nx-link>
            </nx-header-brand>
          </div>
          <div nxRow nxRowJustify="around" class="nx-margin-top-2m">
            <div nxCol='12,10'>
              <nx-formfield nxLabel="Username">
                <input nxInput formControlName="username" />
                <nx-error nxFormfieldError>
                  <b>Please note: </b> you have to fill in your username to continue.
                </nx-error>
              </nx-formfield>
            </div>
          </div>
          <div nxRow nxRowJustify="around">
            <div nxCol='12,10'>
              <nx-formfield nxLabel="Password">
                <input nxInput #testInput type="password" formControlName="password" />
                <nx-password-toggle [control]="testInput" ariaLabel="Your custom show password label" nxFormfieldSuffix>
                </nx-password-toggle>
                <nx-error nxFormfieldError>
                  <b>Please note: </b> you have to fill in your password to continue.
                </nx-error>
              </nx-formfield>
            </div>
          </div>
          <div nxRow nxRowJustify="around">
            <div nxCol='12,10'>
              <re-captcha #captchaRef="reCaptcha" 
                (resolved)="$event && submit($event)"
                [siteKey]="GCaptcha" [size]="Gsize"></re-captcha>
            </div>
          </div>
          <div nxRow nxRowJustify="around">
            <div nxCol='12,10,8,6'>
              <button nxButton="primary  block" type="button" [disabled]="loginForm.invalid"
                (click)="captchaRef.execute()">Login</button>
            </div>

          </div>
          <div nxRow nxRowJustify="around">
            <div nxCol='12,10,8' style="text-align: center;">
              <nx-link nxStyle='block'>
                <a routerLink='/forgotpwd'>
                  <nx-icon name='lock-o'></nx-icon>
                  Forgot password?
                </a>
              </nx-link>
            </div>

          </div>
          <div nxRow nxRowJustify="around" class="nx-margin-bottom-2m">
            <div nxCol='12,10,8' style="text-align: center;">
              <div *ngIf="this.ClientConfig.branding.isDealerLogin; else workshoptemplate">
                <nx-link nxStyle='block'>
                  <a [routerLink]="['/registration']" [queryParams]="{ login: 'dealer'}">
                    <nx-icon name='user-business-o'></nx-icon>
                    NEW DEALER REGISTER HERE
                  </a>
                </nx-link>
              </div>

              <ng-template #workshoptemplate>
                <nx-link nxStyle='block'>
                  <a [routerLink]="['/registration']" [queryParams]="{ login: 'workshop'}">
                    <nx-icon name='user-business-o'></nx-icon>
                    NEW WORKSHOP REGISTER HERE
                  </a>
                </nx-link>
              </ng-template>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>

<ng-template #sessiontemplate>
  <div nxModalContent>
    <div  class="concurrentsession"></div>
    <h2 nxHeadline="subsection-medium" class="nx-font-weight-light">You are logged in some other Browser/Tab,Please logout from there or take action from here!!!</h2>
  </div>
  <div nxModalActions>
    <button nxModalClose="cancel" class="nx-margin-bottom-0 nx-margin-right-xs" nxButton="small secondary" type="button">Cancel</button>
    <button nxModalClose="logout" class="nx-margin-bottom-0" nxButton="small" type="button" (click)="captchaRef.reset()">Logout from everywhere</button>
  </div>
</ng-template>
<ng-template #dateerrortemplate>
  <div nxModalContent>
    <div  class="dateerrorwaring"></div>
    <h2 nxHeadline="subsection-medium" class="nx-font-weight-light">This device date may be errored or is not in IST Time.<br> Please Correct to avoid date issues.</h2>
  </div>
  <div nxModalActions>
    <button nxModalClose="cancel" class="nx-margin-bottom-0" nxButton="small secondary" type="button">OK</button>
  </div>
</ng-template>
<ng-template #rsaewtemplate>
  <div nxModalContent>
    <h3 nxHeadline="subsection-small" class="nx-font-weight-bold" style="text-align: justify;">Since 6 July 2023 onwards, Road Side Assistance (RSA) is also available as a combo package with the Extended Warranty (EW) policy. Dealers are requested to check the start & end dates of the Extended Warranty policy (if any) of the particular VIN and accordingly book the standalone RSA Retail policy on that VIN. Customers can purchase the standalone RSA Retail policy through Hyundai authorized dealerships OR through myHyundai app only.  
    </h3>
  </div>
  <div nxModalActions>
    <button nxModalClose="Agree"  class="nx-margin-bottom-0" nxButton="small" type="button" (click)="closeDialog($event)">I Understand</button>
    <button nxModalClose="Cancel" class="nx-margin-bottom-0 nx-margin-right-xs" nxButton="small secondary" type="button" (click)="logOutUser()">Cancel</button>
  </div>
</ng-template>