import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {
  policyNo: string;
  clientId: number;
  _apiUrl: string;
  blob: Blob;

  constructor(private _activatedroute:ActivatedRoute,private authService: AuthService,
    private appConfig : AppConfig, private router: Router,) {

    this._apiUrl = appConfig.baseUrl;
    this.policyNo=this._activatedroute.snapshot.paramMap.get("policyNo");
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
  }

  ngOnInit() {

  }

  viewPolicy() {
    window.open(this._apiUrl +'/api/v1/DownLoadPage/policyNo/'  + this.policyNo);
   }

  downloadPolicy() {
      this.authService.export(this._apiUrl + '/api/v1/DownLoadPage/policyNo/'+ this.policyNo)
      .subscribe(data => saveAs(data, this.policyNo + `_report.pdf`));
  }
}
