import { HttpRequest, HttpHandler, HttpInterceptor, HTTP_INTERCEPTORS, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Injector, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, Observable, throwError, BehaviorSubject, of } from "rxjs";
import { catchError, switchMap, tap, finalize, take, filter, mergeMap, map } from "rxjs/operators";
import { AuthService } from './core/auth/auth.service';
import { LoaderService } from './loader.service';
import * as CryptoJS from 'crypto-js';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    refreshTokenInProgress = false;

    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
    private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private authService: AuthService, private router: Router, private loaderService: LoaderService) {
        
     }

    addAuthHeader(request) {
        const authHeader = this.getAuthorizationHeader();
        if (authHeader) {
            return request.clone({
                setHeaders: {
                    "JWToken": authHeader,
                    "X-AWP-TOKEN" : this.getCookieValue("AWP-TOKEN")
                }
            });
        }
        return request;
    }

    getAuthorizationHeader() {
        return localStorage.getItem("API_ACCESS_TOKEN");
    }

    logout() {
        this.authService.Logout(localStorage.getItem("IDENTIFIER"));
        this.authService.resetcredentials();
        this.router.navigate([""]);
    }

    addHeaders(request: HttpRequest<any>): HttpRequest<any> {
        const headers = new HttpHeaders({
            'hostname': window.location.host,
            'Strict-Transport-Security': 'max-age=31536000',
            'X-Frame-Options': 'SAMEORIGIN',
            'X-Content-Type-Options': 'nosniff',
            'Content-Security-Policy': "default-src 'self' *.awpassistance.in",
            'HashBody': CryptoJS.HmacMD5(request.body,'AllianzAWP').toString(CryptoJS.enc.Hex)
        });

        if (request.url.includes('/api')) {
            //For Antiforgery token to set in client side set withCredentials
            return request.clone({ headers, withCredentials : true });
            
        }
    }
    
    addContentTypeHeaders(request: HttpRequest<any>): HttpRequest<any> {
        if (!request.url.includes('/api/v1/Upload') && !request.url.includes('/api/v1/BulkUpload')) {
            return request.clone({
                headers: request.headers.set('Content-Type', 'application/json')
            });
        }
        return request;
    }

    // addtoken(request: HttpRequest<any>): HttpRequest<any> {

    //     if (request.url.includes('/api')) {
    //         request = this.addAuthHeader(request);
    //     }

    //     return request.clone();
    // }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        this.loaderService.show();

        // Handle request
        if (!request.url.includes('appconfig.json')) {
            request = this.addHeaders(request);
            request = this.addContentTypeHeaders(request);
            if (request.url.includes('/api')) {
                request = this.addAuthHeader(request);
            }
        }

        // Handle response
        return next.handle(request)
            .pipe(
                finalize(() => this.loaderService.hide()),
                catchError(err => {
                    return this.handleResponseError(err, request, next);
                }));
    }

    handleResponseError(error, request?, next?) {
        // Business error
        if (error.status === 400) {
            // Show message
        }

        // Invalid token error
        else if (error.status === 401) {
            if(this.authService.isAuthenticated())
            {
                return this.handleUnauthorized(request, next);
            }
            else
            {
                this.logout();
            }
        }

        // Access denied error
        else if (error.status === 403) {
            // Show message
            // Logout
            this.logout();
        }

        // Server error
        else if (error.status === 500) {
            // Show message
        }

        // Maintenance error
        else if (error.status === 503) {
            // Show message
            // Redirect to the maintenance page
        }
        else if (error.status === 0) {
            // Show message
            // Redirect to the maintenance page
            //this.router.navigate(['/unavailable']);
        }

        return throwError(error);
    }

    handleUnauthorized(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            var newToken  = null;
            var newReq : any;
            
            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);
            // get a new token via userService.refreshToken
            return this.authService.refreshToken()
            .pipe(
                // Handle first message
                map((refreshToken : string) => {
                    if(refreshToken){
                        this.authService.renewToken(refreshToken);
                        newToken  = refreshToken;
                    }
                    // If we don't get a new token, we are in trouble so logout.
                    else{ 
                        this.logout();
                        return '';
                    }                   
                })
                // Trigger second call -need to reset antiforgery token since its breaking due to refreshtoken implemetaion
                //Workaround solution need to work more on this for a stable fix
                , mergeMap(() => this.authService.resetAntiforgeryToken()) 
                , map((response : any) => {
                    this.tokenSubject.next(newToken);
                    newReq = this.addAuthHeader(req);
                })

                // Trigger third call and handle data
                , mergeMap(() => { return next.handle(newReq)})
                //, map((message) => console.log(message))

                , catchError(error => {
                    // If there is an exception calling 'refreshToken', bad news so logout.
                    this.logout();
                    return throwError('');
                })
                , finalize(() => {
                    this.refreshTokenInProgress = false;
                })
            );

        } else {
            return this.tokenSubject
                .pipe(
                    filter(token => token != null)
                    , take(1)
                    , switchMap(token => {
                        return next.handle(this.addAuthHeader(req));
                    })
                );
        }
    }

    private getCookieValue(cookieName: string) {
        const allCookies = decodeURIComponent(document.cookie).split("; ");
        for (let i = 0; i < allCookies.length; i++) {
            const cookie = allCookies[i];
            if (cookie.startsWith(cookieName + "=")) {
                return cookie.substring(cookieName.length + 1);
            }
        }
        return "";
    }
    
    private deleteCookie(name) {
        this.setCookie(name, '', -1);
    }

    private setCookie(name: string, value: string, expireDays: number, path: string = '') {
        let d:Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        let expires:string = `expires=${d.toUTCString()}`;
        let cpath:string = path ? `; path=${path}` : '';
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }
}
