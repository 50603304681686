<header nx-header>

  <nx-header-row>
    <nx-header-brand>
      <nx-link>
        <a href="#">
          <figure nxFigure>
            <img src='assets/APlogo.png' alt="Allianz Global Brand Logo" role="none">
          </figure>
        </a>
      </nx-link>
    </nx-header-brand>
    <nx-header-actions>
      <button nxButton="secondary small" class="login-button nx-margin-bottom-0" type="button"
        routerLink="/">Login</button>
    </nx-header-actions>
  </nx-header-row>
</header>


<div nxLayout='grid' class="nx-margin-top-2m" >
  <div nxRow class="nx-margin-top-2xl">
    <div nxCol="5" class="nx-margin-left-2xl">
      <div class="passworddoubt"></div>
    </div>

    <div nxCol="5" class="nx-margin-left-2xl">
      <form [formGroup]="forgotPwdForm">
        <div nxRow class="nx-margin-left-5xl" >
          <h1 nxHeadline="subsection-small">Forgot Password</h1>
        </div>
        <div nxRow class="nx-margin-left-3m">
          <div nxCol="8">
            <nx-formfield nxLabel="Email Id">
              <input nxInput formControlName="emailId" />
              <nx-error nxFormfieldError>
                <b>Please note: </b> you have to fill in your email to continue.
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow class="nx-margin-left-2xl">
          <div nxCol="6">
            <button nxButton="primary  block" type="button" [disabled]="forgotPwdForm.invalid"
              (click)="forgotPassword();">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
