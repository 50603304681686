import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { loginRoutingModule } from './login-routing.module'
import { LoginComponent } from './login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { NxExpertModule } from '@allianz/ngx-ndbx/config';
import { NxInputModule } from '@allianz/ngx-ndbx/input';
import { NxFormfieldModule } from '@allianz/ngx-ndbx/formfield';
import { NxGridModule } from '@allianz/ngx-ndbx/grid';
import { NxLabelModule, NxErrorModule } from '@allianz/ngx-ndbx/base';
import { NxCardModule } from '@allianz/ngx-ndbx/card';
import { NxButtonModule } from '@allianz/ngx-ndbx/button';

import { NxLinkModule } from '@allianz/ngx-ndbx/link';
import { NxHeaderModule } from '@allianz/ngx-ndbx/header'
import { NxImageModule } from '@allianz/ngx-ndbx/image';
import { NxIconModule } from '@allianz/ngx-ndbx/icon';
import { NxMessageModule } from '@allianz/ngx-ndbx/message';
import { TitleModule } from '../title/title.module';
//import { LoaderComponent } from '../loader/loader.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import{ NxModalModule } from '@allianz/ngx-ndbx/modal';



import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotpasswordComponent,
    //LoaderComponent
  ],
  imports: [
    CommonModule,
    loginRoutingModule,
    //NxExpertModule,
    NxInputModule,
    NxFormfieldModule,
    FormsModule,
    ReactiveFormsModule,
    NxGridModule,
    NxLabelModule,
    NxErrorModule,
    NxCardModule,
    NxButtonModule,
    NxLinkModule,
    NxHeaderModule,
    NxImageModule,
    NxIconModule,
    NxMessageModule,
    TitleModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NxModalModule
  ],
  exports: [LoginComponent, ForgotpasswordComponent]
})
export class LoginModule { }
