<div nxLayout="grid">
  <div nxRow>
    <div nxCol="12" style="overflow-x: hidden">
      <div nxRow nxRowJustify="start,around">
        <div nxCol="12" class="walletheaderillustration"></div>
        <h1 nxHeadline="page">Wallet Payment</h1>
      </div>
      <form
        #onePayuForm
        ngNoForm
        [action]="payuUrl"
        method="POST"
        target="_blank"
        *ngIf="viewPaymentModal; else paymentConfirm"
      >
        <div>
          <div nxRow>
            <div nxCol="12" style="margin-top: 5%">
              <nx-formfield nxLabel="Amount">
                <nx-formfield-label class="required">
                  Recharge Amount
                </nx-formfield-label>
                <input
                  name="amount"
                  required
                  nxInput
                  type="number"
                  [(ngModel)]="rechargeAmount"
                />
                <nx-error nxFormfieldError>
                  <strong> Please note: </strong>You have to fill out this field
                  to continue.
                </nx-error>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12" style="margin-top: 5%">
              <nx-formfield nxLabel="Mobile Number">
                <nx-formfield-label class="required"
                  >Mobile Number</nx-formfield-label
                >
                <input
                  nxInput
                  name="phone"
                  [(ngModel)]="mobileNo"
                  [formControl]="phoneFormControl"
                />
                <nx-error
                  *ngIf="phoneFormControl.hasError('required')"
                  nxFormfieldError
                >
                  <strong> Please note: </strong>This field is required.
                </nx-error>
                <nx-error
                  *ngIf="phoneFormControl.hasError('pattern')"
                  nxFormfieldError
                >
                  Please Enter a Mobile Number.
                </nx-error>
              </nx-formfield>
            </div>
          </div>
          <!-- HiddenFields -->
          <input type="hidden" name="key" [(ngModel)]="this.payuinput.key" />
          <input
            type="hidden"
            name="txnid"
            [(ngModel)]="this.payuinput.txnid"
          />
          <input
            type="hidden"
            name="productinfo"
            [(ngModel)]="this.payuinput.productinfo"
          />

          <input
            type="hidden"
            name="email"
            [(ngModel)]="this.payuinput.email"
          />
          <input
            type="hidden"
            name="firstname"
            [(ngModel)]="this.payuinput.firstname"
          />
          <!-- <input type="hidden" name="lastname" [(ngModel)]="this.payuinput.key" /> -->
          <input type="hidden" name="surl" [(ngModel)]="this.payuinput.surl" />
          <input type="hidden" name="furl" [(ngModel)]="this.payuinput.furl" />
          <input type="hidden" name="hash" [(ngModel)]="this.payuinput.hash" />
        </div>
        <div nxRow class="nx-margin-top-m" nxRowJustify="center">
          <div nxCol="12" class="nx-margin-top-2m" style="text-align: center">
            <button
              nxButton
              type="button"
              (click)="bindPaymentInfo()"
              [disabled]="!(mobileNo && this.phoneFormControl.valid)"
            >
              Proceed To Pay
            </button>
            <button
              nxButton="emphasis"
              type="button"
              (click)="onModalClose(false)"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #paymentConfirm>
  <div>
    <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-3m">
      <nx-icon
        name="exclamation-circle-warning"
        size="s"
        class="nx-margin-right-xs"
      ></nx-icon
      >Please pay and close this window to see updated balance!
    </h3>
    <button
      nxButton="block secondary cta"
      type="button"
      (click)="onPaymentConfirm()"
    >
      Close
    </button>
  </div>
</ng-template>
