import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SortEvent, SortDirection } from '@allianz/ngx-ndbx/table';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';


@Component({
  selector: 'app-cesstaxmaster',
  templateUrl: './cesstaxmaster.component.html',
  styleUrls: ['./cesstaxmaster.component.css']
})
export class CesstaxmasterComponent implements OnInit {
  cessTaxModalOpen: boolean = false;
  confirmModalOpen: boolean = false;
  cessTaxForm: FormGroup;
  _apiUrl: string;
  clientId: number;
  cessTaxList = [];
  isSuccess: boolean = false;
  EDIT_FLAG: boolean;
  id;
  cessTaxDetails = {};
  stateList: any;
  /** sorting starts here */

  sortTable(sort: SortEvent) {//Sorts the table data by a certain category.
    this.cessTaxList = this.cessTaxList.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection) {

    return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
  }
  /** sorting ends here */

  /**Pagination Start */
  count: number;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
    this.pageNumber--;
    this.getCessTaxList();
  }

  nextPage() {
    this.pageNumber++;
    this.getCessTaxList();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getCessTaxList();
  }
  /**Pagination end */
  constructor(private authService: AuthService, private appConfig : AppConfig) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.cessTaxFormBuilder();
  }

  ngOnInit() {
    this.getCessTaxList();
    this.getStateDetails();
  }

  cessTaxFormBuilder() {
    this.cessTaxForm = new FormBuilder().group({
      cessName: ['', Validators.required],
      cessPercentage: ['', Validators.required],
      stateId: ['', Validators.required],
      clientId: this.clientId,
      id: '',
      isActive: true,
    });
  }

  getStateDetails() {
    this.authService
      .get(this._apiUrl + "/api/v1/States/GetActiveStates/client/" + this.clientId)
      .subscribe(
        (response) => {
          if (response) {
            this.stateList = response;
            //console.log("state list", this.stateList);
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getCessTaxList() {
    this.authService.getList(this._apiUrl + '/api/v1/CessTaxes/' + "?pageNumber=" + this.pageNumber + "&pageSize=" + this.pageSize + "&searchText=" + this.searchTerm).subscribe(response => {
      if (response) {
        this.cessTaxList = response.body;
        var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
        this.count = pagingProp.TotalCount;
      }
    }, (error) => {
      console.error(error);
    });
  }
  toggleModal(EDIT: boolean, cessTaxId) {
    this.EDIT_FLAG = EDIT;
    this.id = cessTaxId;
    this.cessTaxModalOpen = !this.cessTaxModalOpen;
    this.isSuccess = false;
    if (this.EDIT_FLAG) {
      this.getCessTaxDetails(cessTaxId);
    }
    if (!this.cessTaxModalOpen) {
      this.cessTaxFormBuilder();
    }
  }
  addCessTaxMaster() {
    this.authService.post(this._apiUrl + '/api/v1/CessTaxes', this.cessTaxForm.value).subscribe(response => {
      if (response) {
        this.getCessTaxList();
        this.isSuccess = true;
      }
    }, (error) => {
      console.error(error);
    });
  }
  getCessTaxDetails(cessTaxId) {
    this.authService.get(this._apiUrl + '/api/v1/CessTaxes/' + cessTaxId).subscribe(response => {
      if (response) {
        console.log(response);
        this.cessTaxForm.setValue({
          cessName: response.cessName,
          cessPercentage: response.cessPercentage,
          stateId: response.stateId,
          id: response.id,
          clientId: response.clientId,
          isActive: response.isActive,

        })
      }
    }, (error) => {
      console.error(error);
    });
  }
  updateCessTaxMaster() {
    this.authService.put(this._apiUrl + '/api/v1/CessTaxes/' + this.id, this.cessTaxForm.value).subscribe(response => {
      // if (response) {
      this.getCessTaxList();
      this.isSuccess = true;
      // }
    }, (error) => {
      console.error(error);
    });
  }

  confirmModalClose() {
    this.confirmModalOpen = false;
    this.getCessTaxList();
    this.cessTaxFormBuilder();
  }

  confirmModal(cessTaxDetails, isChecked: boolean) {
    let isActive;
    this.confirmModalOpen = true;

    if (isChecked) {
      isActive = 1;
    } else {
      isActive = 0;
    }

    this.cessTaxForm.setValue({
      cessName: cessTaxDetails.cessName,
      cessPercentage: cessTaxDetails.cessPercentage,
      stateId: cessTaxDetails.stateId,
      id: cessTaxDetails.id,
      clientId: cessTaxDetails.clientId,
      isActive: cessTaxDetails.isActive,

    })
  }

  changeCessTaxStatus() {
    let statusDto: object;

    statusDto = {
      "id": this.cessTaxForm.controls.id.value,
      "statusId": this.cessTaxForm.controls.isActive.value ? 0 : 1
    }
    this.authService.put(this._apiUrl + '/api/v1/CessTaxes/StatusUpdate', statusDto).subscribe(response => {
      // if (response) {
      this.getCessTaxList();
      this.confirmModalClose();

      // }
    }, (error) => {
      console.error(error);
    });
  }




}
