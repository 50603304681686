<header nx-header>
  <!-- <nx-header-row>
           <nx-header-actions>
        <button nxButton="secondary small" class="login-button" type="button">Login</button>
      </nx-header-actions>
    </nx-header-row> -->
  <nx-header-row>
    <nx-header-brand>
      <nx-link>
        <a href="/dashboard">
          <figure nxFigure>
            <img src='assets/APlogo.png' alt="Allianz Global Brand Logo" role="none">
          </figure>
        </a>

      </nx-link>

    </nx-header-brand>
    <!-- <button nxButton="tertiary small" class="nx-margin-bottom-0" [nxPopoverTriggerFor]="userInfoSelect"
      #popoverTrigger="nxPopoverTrigger" (nxPopoverShowChange)="popoverClosed($event)" nxPopoverDirection="bottom"
      nxPopoverTrigger="click" class="user-info-label">
      Welcome, {{userName}}
      <nx-icon name="chevron-down" class="nx-margin-left-2xs" [class.user-arrow-opened]="_versionSelectIsOpened"
        [class.user-arrow-closed]="!_versionSelectIsOpened"></nx-icon>
    </button> -->
    <nx-header-actions>
      <app-walletbalance></app-walletbalance>
      <!-- <button nxButton="tertiary small" class="nx-margin-bottom-0" [nxPopoverTriggerFor]="userInfoSelect"
        #popoverTrigger="nxPopoverTrigger" nxPopoverDirection="bottom" nxPopoverTrigger="click" class="user-info-label">
        Welcome, {{userName}}
        <nx-icon name="chevron-down" class="nx-margin-left-2xs"></nx-icon>
      </button> -->
      <button nxButton="tertiary small" class="nx-margin-bottom-0" [nxContextMenuTriggerFor]="menu" type="button">
        Welcome, {{userName}}
        <nx-icon name="chevron-down" class="nx-margin-left-2xs"></nx-icon>
      </button>

    </nx-header-actions>
  </nx-header-row>
</header>


<nx-popover #userInfoSelect>
  <div class="user-login-info">
    <div>
      <div class="user-info-details-label" role="heading"> Email
        Address </div>
      <p class="user-info-data-label user-data" id="textID1">
        {{userEmail}} </p>
      <span class="user-info-details-label" role="heading"> Role

      </span>
      &nbsp;&nbsp;
      <span class="user-info-data-label user-data" id="textID1">
        {{userRole}}</span>
      <!-- <button *ngIf="userRole=='WorkShop'" nxButton="tertiary small-medium block" type="button"
        class="user-info-details-label">
        <a [routerLink]="['/workshopedit']" routerLinkActive="router-link-active">Profile</a>
      </button> -->
      <!-- <button *ngIf="userRole=='WorkShop'" nxButton="tertiary small-medium block" type="button"
        class="user-info-details-label" nxPopoverDirection="left" [nxPopoverTriggerFor]="walletMenuSelect">
        <a>Wallet</a>
      </button> -->
    </div>
    <!-- <button (click)="logOutUser()" nxButton="small-medium block" class="nx-margin-top-3m" type="button">Logout</button> -->
  </div>
</nx-popover>

<nx-context-menu #menu="nxContextMenu">
  <button nxContextMenuItem type="button" routerLinkActive="router-link-active">
    <nx-icon aria-hidden="true" name="mail-o"></nx-icon>
    {{userEmail}}
  </button>
  <button nxContextMenuItem type="button" routerLinkActive="router-link-active">
    <nx-icon aria-hidden="true" name="star-o"></nx-icon>
    {{userRole}}
  </button>
  <button nxContextMenuItem type="button" [nxContextMenuTriggerFor]="profileSubMenu">
    <nx-icon aria-hidden="true" name="user-o"></nx-icon>
    Profile
  </button>
  <button nxContextMenuItem type="button" [nxContextMenuTriggerFor]="walletSubMenu"
    *ngIf="userRole=='WorkShop' || DealerReg">
    <nx-icon aria-hidden="true" name="product-coins-money"></nx-icon>
    Wallet
  </button>
  <!-- <button nxContextMenuItem type="button" *ngIf="userRole=='Dealer' || DealerReg">
    <nx-icon aria-hidden="true" name='user-business-o'></nx-icon>
    <a routerLinkActive="router-link-active" target="_blank" [routerLink]="['/registration']"
      [queryParams]="{ login: 'workshop'}">
      WorkShop Registration
    </a>
  </button> -->
  <button (click)="logOutUser()" nxButton="small-medium block" class="nx-margin-top-3m logout-button"
    type="button">Logout</button>
</nx-context-menu>
<nx-context-menu #walletSubMenu="nxContextMenu">
  <button nxContextMenuItem type="button" routerLink='/walletrequestlist' routerLinkActive="router-link-active">Wallet
    Recharge</button>
  <button nxContextMenuItem type="button" routerLink='/walletstatement' routerLinkActive="router-link-active">Wallet
    Statement</button>
</nx-context-menu>
<nx-context-menu #profileSubMenu="nxContextMenu">
  <button nxContextMenuItem type="button" routerLink='/workshopedit' routerLinkActive="router-link-active" *ngIf="userRole=='WorkShop' || DealerReg">View
    Profile</button>
  <button nxContextMenuItem type="button" [routerLink]="['/changepassword']" [queryParams]="{ forcechangepwd: 'true'}"
    routerLinkActive="router-link-active">Change Password</button>
</nx-context-menu>