import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import {SharedModule } from '../shared/shared.module';
import { ManageusersComponent } from '../manageusers/manageusers.component';  
import { UserlistComponent } from '../userlist/userlist.component';  
import { ColormanagementComponent } from '../colormanagement/colormanagement.component';
import { ModelmanagementComponent } from '../modelmanagement/modelmanagement.component';  
import { PlanComponent } from '../plan/plan.component';  
import { MasterComponent } from '../master/master.component';
import { PolicymasterComponent } from '../policymaster/policymaster.component';


import{ NxCardModule } from '@allianz/ngx-ndbx/card'; 
import{ NxLinkModule } from '@allianz/ngx-ndbx/link';
import{ NxGridModule } from '@allianz/ngx-ndbx/grid';  
import{ NxFormfieldModule } from '@allianz/ngx-ndbx/formfield'; 
import{ NxInputModule } from '@allianz/ngx-ndbx/input';
import{ NxDropdownModule } from '@allianz/ngx-ndbx/dropdown';  
import{ NxPopoverModule } from '@allianz/ngx-ndbx/popover';  
import{ NxIconModule } from '@allianz/ngx-ndbx/icon';  
import { NxLabelModule,NxErrorModule } from '@allianz/ngx-ndbx/base'; 
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import{ NxButtonModule } from '@allianz/ngx-ndbx/button';
import{ NxTabsModule } from '@allianz/ngx-ndbx/tabs';
import{ NxTableModule } from '@allianz/ngx-ndbx/table';  
import{ NxBadgeModule } from '@allianz/ngx-ndbx/badge';  
import{ NxSwitcherModule } from '@allianz/ngx-ndbx/switcher';
import{ NxModalModule } from '@allianz/ngx-ndbx/modal';
import { NxDatefieldModule } from '@allianz/ngx-ndbx/datefield';
import { NxNativeDateModule } from '@allianz/ngx-ndbx/datefield';
import { NxMomentDateModule } from '@allianz/ngx-ndbx/moment-date-adapter';
import { NxSpinnerModule } from '@allianz/ngx-ndbx/spinner'; 
import { NxPaginationModule } from '@allianz/ngx-ndbx/pagination'; 
import { NxMessageModule } from '@allianz/ngx-ndbx/message'; 
import { NxCopytextModule } from '@allianz/ngx-ndbx/copytext'; 
import { ZonemanagementComponent } from '../zonemanagement/zonemanagement.component';
import { ClientmanagementComponent } from '../clientmanagement/clientmanagement.component';
import { StatemasterComponent } from '../statemaster/statemaster.component';
import { CitymasterComponent } from '../citymaster/citymaster.component';
import { PageRoutingConfigComponent } from '../page-routing-config/page-routing-config.component';
import { TitleModule } from '../title/title.module';
import { NxCheckboxModule } from '@allianz/ngx-ndbx/checkbox';
import { NxHeadlineModule } from '@allianz/ngx-ndbx/headline';
import { NxContextMenuModule } from '@allianz/ngx-ndbx/context-menu';
import { ManageContentComponent } from '../manage-content/manage-content.component';
import { NxTooltipModule } from '@allianz/ngx-ndbx/tooltip';
import { CesstaxmasterComponent } from '../cesstaxmaster/cesstaxmaster.component';
import { NxMaskModule } from '@allianz/ngx-ndbx/mask';
import { NxFileUploaderModule } from '@allianz/ngx-ndbx/file-uploader';
import { ConfigEncryptionComponent } from '../config-encryption/config-encryption.component';
import { PortaltransactionoverviewComponent } from '../portaltransactionoverview/portaltransactionoverview.component';
import { NxRadioModule } from '@allianz/ngx-ndbx/radio-button';
import { NxAccordionModule } from '@allianz/ngx-ndbx/accordion';


@NgModule({
  declarations: [
    ManageusersComponent, 
    UserlistComponent, 
    ColormanagementComponent, 
    ModelmanagementComponent,
    PlanComponent,
    MasterComponent,
    PolicymasterComponent,
    ZonemanagementComponent,
    ClientmanagementComponent,
    StatemasterComponent,
    CitymasterComponent,
    PageRoutingConfigComponent,
    ManageContentComponent,
    CesstaxmasterComponent,
    ConfigEncryptionComponent,
    PortaltransactionoverviewComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    NxCardModule,
    NxLinkModule,
    NxGridModule,
    NxFormfieldModule,
    NxInputModule,
    NxDropdownModule,
    NxPopoverModule,
    NxIconModule,
    NxLabelModule,
    NxErrorModule,
    FormsModule,
    ReactiveFormsModule,
    NxButtonModule,
    NxTabsModule,
    NxTableModule,
    NxBadgeModule,
    NxSwitcherModule,
    NxModalModule,
    NxModalModule.forRoot(),
    NxDatefieldModule,
    NxNativeDateModule,
    NxMomentDateModule,
    NxSpinnerModule,
    NxPaginationModule,
    NxMessageModule,
    NxCopytextModule,
    TitleModule,
    NxCheckboxModule,
    NxHeadlineModule,
    NxContextMenuModule,
    NxTooltipModule,
    NxMaskModule,
    NxFileUploaderModule,   
    NxRadioModule,
    NxAccordionModule,
  ],

  exports:[ManageusersComponent,UserlistComponent,ColormanagementComponent,ModelmanagementComponent,PlanComponent,MasterComponent,PolicymasterComponent]
})
export class AdminModule { }
