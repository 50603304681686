import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { NxMessageToastService } from "@allianz/ngx-ndbx/message";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: "app-forgotpassword",
  templateUrl: "./forgotpassword.component.html",
  styleUrls: ["./forgotpassword.component.css"],
})
export class ForgotpasswordComponent implements OnInit {
  forgotPwdForm: FormGroup;
  _apiUrl: string;
  firstlogin: number;

  constructor(
    private authService: AuthService,
    private appConfig : AppConfig,
    private messageToastService: NxMessageToastService,
    private router: ActivatedRoute,
    private route: Router
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.forgotPwdForm = new FormBuilder().group({
      emailId: ["", Validators.required],
      clientId: parseInt(atob(localStorage.getItem("CLIENT_ID"))),
    });
  }

  ngOnInit() {}

  forgotPassword() {
    this.authService.post(this._apiUrl + '/api/v1/Password/forgotPassword', this.forgotPwdForm.value).subscribe(response => {
      if (response) {
        if (response.success) {
          this.messageToastService.open(response.responsemsg, { context: 'info', duration: 5000 });
          localStorage.setItem("ISFIRSTLOGIN", "0");
          this.firstlogin = 0;
          this.route.navigate(['']);
        }
        else {
          this.messageToastService.open(response.responsemsg, { context: 'info', duration: 5000 });
        }
      }
    }, (error) => {
      console.error(error);
    });
  }
}
