import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { NxMessageToastService } from '@allianz/ngx-ndbx/message';
import { ActivatedRoute, Router } from '@angular/router';
import { MESSAGE_CONSTS } from '../ValidationMessages';
import { RequestIntegrity } from '../core/request-integrety';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  firstlogin: number;
  forcechangepwd: boolean;
  changepassform: FormGroup;
  _apiUrl: string;
  clientid: number;
  userId: number;

  constructor(private authService: AuthService, private appConfig : AppConfig, 
    private messageToastService: NxMessageToastService, private router: ActivatedRoute,private route: Router,
    private requestIntegrity :RequestIntegrity) {
    this._apiUrl = appConfig.baseUrl;
    this.changepassformBuilder();
  }

  ngOnInit() {
    this.router.queryParams.subscribe(params => {
      this.forcechangepwd = params.forcechangepwd;
      this.firstlogin = params.firstlogin;
    });
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
  changepassformBuilder() {
    this.changepassform = new FormBuilder().group({
      currentpass: new FormControl(null, [Validators.required]),
      newpass: new FormControl(null, [Validators.required,Validators.pattern(MESSAGE_CONSTS.PASSWORD_REGEX)]),
      confirmpass: new FormControl(null, [Validators.required])
    }, { validator: this.MustMatch('newpass', 'confirmpass') })
  }
  get f() { return this.changepassform.controls; }

  changepassword() {
    //Encrypt request body
    let encrypted = this.requestIntegrity.encryptUsingAES256(this.changepassform.value);

    this.authService.post(this._apiUrl + '/api/v1/Workshops/changePassword', encrypted).subscribe(response => {
      if (response) {
        if (response.success) {
          this.messageToastService.open(response.responsemsg, { context: 'info', duration: 5000 });
          localStorage.setItem("ISFIRSTLOGIN", "0")
          this.firstlogin = 0;
          this.route.navigate(['/dashboard']);
        }
        else {
          this.messageToastService.open(response.responsemsg, { context: 'info', duration: 5000 });
        }
      }
    }, (error) => {
      console.error(error);
    });
  }

}
