<nx-modal
  #policyEdit
  [nxHideOnClickOutside]="false"
  [nxHideOnEsc]="false"
  [nxShowCloseIcon]="false"
  [nxBody]="policyEditBody"
>
</nx-modal>

<ng-template #policyEditBody>
  <div nxLayout="grid">
    <div *ngIf="!isSuccess">
      <div nxRow nxRowJustify="start,around">
        <h2 nxHeadline="section">Edit Policy</h2>
      </div>
      <form [formGroup]="editPolicyForm">
        <nx-tab-group mobileAccordion="false">
          <nx-tab label="Vehicle Details">
            <div class="tab__content">
              <div nxRow>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="VIN(Chasis Number)">
                    <input
                      nxInput
                      formControlName="vinChassisNo"
                      nxMask="AAAAAAAAAAAAAAAAA"
                      nxConvertTo="upper"
                    />
                    <nx-error nxFormfieldError>
                      <div *ngIf="f.vinChassisNo.errors?.pattern">
                        <strong>Please note:</strong> Enter a 17 character
                        VIN(Chasis Number).
                      </div>
                      <div *ngIf="f.vinChassisNo.errors?.required">
                        <strong>Please note:</strong> This field is required!
                      </div>
                    </nx-error>
                    <span nxFormfieldHint>
                      HINT : VIN(Chasis Number) in 17 characters
                    </span>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Licence Plate NO">
                    <input
                      #licensePlateNo
                      nxInput
                      formControlName="licensePlateNo"
                      (keyup)="
                        licensePlateNo.value =
                          licensePlateNo.value.toUpperCase()
                      "
                      (focusout)="
                        licensePlateNo.value =
                          licensePlateNo.value.toUpperCase()
                      "
                    />
                    <nx-error nxFormfieldError>
                      <div *ngIf="f.licensePlateNo.errors?.pattern">
                        <strong>Please note:</strong> Invalid Vehicle
                        Registration Number.
                      </div>
                    </nx-error>
                    <span nxFormfieldHint> eg: KL07CR0007 </span>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Select Model">
                    <nx-dropdown formControlName="model">
                      <nx-dropdown-item
                        *ngFor="let item of modelList"
                        [nxValue]="item.modelName"
                      >
                        {{ item.modelName }}
                      </nx-dropdown-item>
                    </nx-dropdown>
                    <nx-error nxFormfieldError>
                      <div *ngIf="f.model.errors?.required">
                        <strong>Please note:</strong> This field is required!
                      </div>
                    </nx-error>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Select Color">
                    <nx-dropdown formControlName="colorName">
                      <nx-dropdown-item
                        *ngFor="let item of colorList"
                        [nxValue]="item.colorName"
                      >
                        {{ item.colorName }}
                      </nx-dropdown-item>
                    </nx-dropdown>
                    <nx-error nxFormfieldError>
                      <div *ngIf="f.colorName.errors?.required">
                        <strong>Please note:</strong> This field is required!
                      </div>
                    </nx-error>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Vehicle Sale date">
                    <input
                      nxDatefield
                      nxInput
                      [nxDatepicker]="VehicleSaledatepicker"
                      formControlName="vehicleSaleDate"
                    />
                    <nx-datepicker-toggle
                      [for]="VehicleSaledatepicker"
                      nxFormfieldSuffix
                    >
                    </nx-datepicker-toggle>
                    <nx-datepicker #VehicleSaledatepicker></nx-datepicker>
                    <nx-error nxFormfieldError>
                      <strong>Please note:</strong> this field is required!
                    </nx-error>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Variant">
                    <input nxInput formControlName="variant" />
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Invoice date">
                    <input
                      nxDatefield
                      nxInput
                      [nxDatepicker]="invoiceDatepicker"
                      formControlName="invoiceDate"
                      readonly
                    />
                    <!-- <span nxFormfieldHint>MM/DD/YYYY</span> -->
                    <nx-datepicker-toggle
                      [for]="invoiceDatepicker"
                      nxFormfieldSuffix
                    >
                    </nx-datepicker-toggle>
                    <nx-datepicker #invoiceDatepicker></nx-datepicker>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6" *ngIf="false">
                  <nx-formfield nxLabel="Other Details">
                    <textarea nxInput formControlName="otherDetails"></textarea>
                  </nx-formfield>
                </div>
              </div>
            </div>
          </nx-tab>

          <nx-tab label="Customer Details">
            <div class="tab__content">
              <div nxRow>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="First Name ">
                    <input nxInput formControlName="customerFirstName" />
                    <nx-error nxFormfieldError>
                      <strong>Please note:</strong> this field is required!
                    </nx-error>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6" *ngIf="false">
                  <nx-formfield nxLabel="Middle Name">
                    <input nxInput formControlName="customerMiddleName" />
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Last Name">
                    <input nxInput formControlName="customerLastName" />
                    <nx-error nxFormfieldError>
                      <strong>Please note:</strong> this field is required!
                    </nx-error>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Home Phone Number">
                    <input nxInput formControlName="customerPhoneNo" />
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Gst Number">
                    <input nxInput formControlName="custGstNumber" />
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Mobile Number">
                    <input nxInput formControlName="customerMobileNo" />
                    <nx-error nxFormfieldError>
                      <div *ngIf="f.customerMobileNo.errors?.required">
                        <strong>Please note:</strong>this field is required!
                      </div>
                      <div *ngIf="f.customerMobileNo.errors?.pattern">
                        <strong>Please note:</strong>Invalid mobile number!
                      </div>
                    </nx-error>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Email">
                    <input nxInput formControlName="customerEmail" />
                    <nx-error nxFormfieldError>
                      <!-- <strong>Please note:</strong> this field is required! -->
                      <div *ngIf="f.customerEmail.errors?.required">
                        <strong>Please note:</strong>this field is required!
                      </div>
                      <div *ngIf="f.customerEmail.errors?.email">
                        <strong>Please note:</strong>Invalid email format!
                      </div>
                    </nx-error>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Company Name">
                    <input nxInput formControlName="customerCompany" />
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6" *ngIf="false">
                  <nx-formfield nxLabel="State">
                    <nx-dropdown
                      formControlName="stateId"
                      (selectionChange)="getStateWiseCityList($event.value)"
                      disabled
                    >
                      <nx-dropdown-item
                        *ngFor="let item of stateList | isActiveCheck"
                        [nxValue]="item.id"
                      >
                        {{ item.stateName }}</nx-dropdown-item
                      >
                    </nx-dropdown>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6" *ngIf="false">
                  <nx-formfield nxLabel="City">
                    <nx-dropdown formControlName="cityId" disabled>
                      <nx-dropdown-item
                        *ngFor="let item of cityList | isActiveCheck"
                        [nxValue]="item.id"
                      >
                        {{ item.cityName }}
                      </nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Address">
                    <input
                      nxInput
                      formControlName="customerAddress"
                      #inputToCount
                      maxlength="45"
                    />
                    <span nxFormfieldHint>
                      <span *ngIf="inputToCount.value?.length === 0">
                        max. 45 characters
                      </span>
                      <span *ngIf="inputToCount.value.length > 0">
                        {{ 45 - (inputToCount.value.length || 0) }} characters
                        remaining
                      </span>
                    </span>
                    <nx-error nxFormfieldError>
                      <div *ngIf="f.customerAddress.errors?.required">
                        <strong>Please note:</strong>this field is required!
                      </div>
                    </nx-error>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="PIN Code">
                    <input nxInput formControlName="pincode" />
                    <nx-error nxFormfieldError>
                      <div *ngIf="f.pincode.errors?.required">
                        <strong>Please note:</strong>this field is required!
                      </div>
                    </nx-error>
                  </nx-formfield>
                </div>
              </div>
            </div>
          </nx-tab>

          <nx-tab label="Plan Details">
            <div class="tab__content">
              <div nxRow>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Plan Type">
                    <nx-dropdown formControlName="planId">
                      <nx-dropdown-item
                        *ngFor="let item of planMasterList | isActiveCheck"
                        [nxValue]="item.unityPlanId"
                        >{{ item.planName }}</nx-dropdown-item
                      >
                    </nx-dropdown>
                    <nx-error nxFormfieldError>
                      Please select an option.
                    </nx-error>
                  </nx-formfield>
                </div>

                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Effective date">
                    <nx-formfield-label class="required"
                      >Effective date</nx-formfield-label
                    >
                    <input
                      nxDatefield
                      nxInput
                      [nxDatepicker]="effectiveDatepicker"
                      formControlName="effectiveDate"
                      (dateChange)="onEffectiveDateChange()"
                    />
                    <nx-datepicker-toggle
                      [for]="effectiveDatepicker"
                      nxFormfieldSuffix
                    >
                    </nx-datepicker-toggle>
                    <nx-datepicker #effectiveDatepicker></nx-datepicker>
                    <nx-error
                      nxFormfieldError
                      *ngIf="f.effectiveDate.errors?.required"
                    >
                      <strong>Please note:</strong> this field is required!
                    </nx-error>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Expiry date">
                    <nx-formfield-label class="required"
                      >Expiry date</nx-formfield-label
                    >
                    <input
                      nxDatefield
                      nxInput
                      [nxDatepicker]="expiryDatepicker"
                      formControlName="expiryDate"
                      disabled
                    />
                    <nx-datepicker-toggle
                      [for]="expiryDatepicker"
                      nxFormfieldSuffix
                    >
                    </nx-datepicker-toggle>
                    <nx-datepicker #expiryDatepicker></nx-datepicker>
                    <nx-error
                      nxFormfieldError
                      *ngIf="f.expiryDate.errors?.required"
                    >
                      <strong>Please note:</strong> this field is required!
                    </nx-error>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Price">
                    <input nxInput formControlName="price" />
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Gst Price">
                    <input nxInput formControlName="gstPrice" />
                  </nx-formfield>
                </div>
              </div>
            </div>
          </nx-tab>
          <nx-tab label="Payment Details">
            <div class="tab__content">
              <div nxRow>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Sold By">
                    <input nxInput formControlName="soldBy" />
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Payment Date">
                    <input
                      nxInput
                      value="{{ currentDate | date: 'MM/dd/yyyy' }}"
                      disabled
                    />
                  </nx-formfield>
                </div>

                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Unit Price">
                    <input nxInput formControlName="unitPrice" />
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                  <nx-formfield nxLabel="Outstanding Balance">
                    <input nxInput formControlName="outstandingBal" />
                  </nx-formfield>
                </div>
              </div>
            </div>
          </nx-tab>
        </nx-tab-group>

        <div>
          <hr />
          <div nxRow>
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="Remark">
                <nx-formfield-label class="required"
                  >Remarks</nx-formfield-label
                >
                <input nxInput formControlName="remarks" />
                <nx-error nxFormfieldError>
                  <strong>Note: </strong> Please Enter your remark to modify
                  this policy.
                </nx-error>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6">
              <button (click)="close()" nxButton="small emphasis" type="button">
                <nx-icon name="close" class="nx-margin-right-2xs"></nx-icon
                >Close
              </button>
              <button
                (click)="updatePolicy()"
                nxButton="small cta"
                type="button"
                [disabled]="f.remarks.invalid || f.customerAddress.invalid"
              >
                <nx-icon name="check" class="nx-margin-right-2xs"></nx-icon
                >Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="isSuccess">
      <div nxRow nxRowJustify="start,around">
        <div nxCol="12" style="text-align: -webkit-center">
          <nx-icon *ngIf="EnableClose" name="check-circle" size="m" style="color: green"></nx-icon>
          <nx-spinner *ngIf="!EnableClose" nxSize="large"></nx-spinner>
        </div>
      </div>
      <div
        nxRow
        nxRowJustify="center"
        class="nx-margin-top-m nx-margin-bottom-s"
      >
        <div nxCol="12">
          <!-- <nx-message nxContext="success">User is added successfully</nx-message> -->
          <h3
            class="u-text-align-center"
            *ngIf="IsModified"
            nxHeadline="subsection-xsmall"
          >
            {{modificationResponse}}
          </h3>
          <h3
            class="u-text-align-center"
            *ngIf="!IsModified"
            nxHeadline="subsection-xsmall"
          >
            Policy modification request submitted
          </h3>
        </div>
      </div>
      <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
        <div nxCol="12">
          <button *ngIf="EnableClose" (click)="close()" nxButton="block primary">close</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
