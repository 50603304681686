import { NxMessageToastService } from '@allianz/ngx-ndbx/message';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { AppConfig } from 'src/config/app.config';
import { AuthService } from '../core/auth/auth.service';
import * as XLSX from "xlsx";
import { SortDirection, SortEvent } from '@allianz/ngx-ndbx/table';
import { WalletStatus } from '../core/status.model';

@Component({
  selector: 'app-offline-wallet-report',
  templateUrl: './offline-wallet-report.component.html',
  styleUrls: ['./offline-wallet-report.component.css']
})
export class OfflineWalletReportComponent implements OnInit {
  @Input() policyStartDate: moment.Moment;
  @Input() policyEndDate: moment.Moment;
  @Input() SelectedWalletRequestStatus: typeof WalletStatus;

  private _apiUrl: string;
  errorMessage: string;
  ReportData: any;
  exportdata: any[];
  fileName: string;
  WalletStatuses: any;

  constructor(private authService: AuthService, private appConfig : AppConfig, private messageToastService: NxMessageToastService) { 
    this._apiUrl = appConfig.baseUrl;
    this.WalletStatuses = WalletStatus;
  }

  /**Pagination Start */
  count: number=0;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
      this.pageNumber--;
      this.getReportData();
  }

  nextPage() {
      this.pageNumber++;
      this.getReportData();
  }

  goToPage(n: number) {
      this.pageNumber = n;
      this.getReportData();
  }
  /**Pagination end */
  /** sorting starts here */

  sortTable(sort: SortEvent) {//Sorts the table data by a certain category.
    this.ReportData = this.ReportData.sort((a, b) => {
        if (sort.active in a && sort.active in b) {
            return this.compare(a[sort.active], b[sort.active], sort.direction);
        }
        return 0;
    });
}

compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection) {

    return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
}
/** sorting ends here */

  ngOnInit(): void {
  }

  loadReport() {
    this.pageNumber = 1;
    this.getReportData();
  }

  getReportData() {
    this.ReportData =null;
    var startdate = this.policyStartDate == undefined ? "" : this.policyStartDate.format("LL");
    var enddate = this.policyEndDate == undefined ? "" : this.policyEndDate.format("LL");
    var status = this.SelectedWalletRequestStatus == undefined ? "" : this.SelectedWalletRequestStatus;
    this.authService
      .getList(
        this._apiUrl +
          `/api/v2/Report/OfflineWalletReport?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}
          &searchText=${this.searchTerm}&startDatepicker=${startdate}&endDatepicker=${enddate}&udf1=${status}`
      )
      .subscribe(
        (response) => {
          if (response) {
            this.ReportData = response.body;
            let pagingProp = JSON.parse(response.headers.get("X-Pagination"));
            this.count = pagingProp.TotalCount;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getExportData() {
    var startdate = this.policyStartDate == undefined ? "" : this.policyStartDate.format("LL");
    var enddate = this.policyEndDate == undefined ? "" : this.policyEndDate.format("LL");
    var status = this.SelectedWalletRequestStatus == undefined ? "" : this.SelectedWalletRequestStatus;
    this.authService
      .getList(
        this._apiUrl +
          `/api/v2/Report/OfflineWalletReport?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}
          &searchText=${this.searchTerm}&startDatepicker=${startdate}&endDatepicker=${enddate}&udf1=${status}`
      )
      .subscribe(
        (response) => {
          if (response) {
            this.exportdata = [];
            response.body.forEach((element) => this.setExportData(element));
            this.bindexcel();
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  setExportData(item: any) {
    var data: any = [];
    data.id = item.id;
    data.dealerCode = item.dealerCode;
    data.dealerName = item.dealerName;
    data.requestDate = new Date(item.requestDate).toLocaleDateString();
    data.approvedDate = new Date(item.approvedDate).toLocaleDateString();
    data.paymentMethod = item.paymentMethod;
    data.approvedRemarks = item.approvedRemarks;
    data.requestedAmount = item.requestedAmount;
    data.status = WalletStatus[item.status];
    data.paymentMethodDetails = item.paymentMethodDetails;
    
    this.exportdata.push(data);
  }

  exporttoexcel(){
    this.pageNumber = -1;
    this.getExportData();
  }

  bindexcel(){
    /* json data is passed over here */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportdata);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    ws.A1.v = "Request Id";
    ws.B1.v = "Dealer Code";
    ws.C1.v = "Dealer Name";
    ws.D1.v = "Request Date";
    ws.E1.v = "Approved Date";
    ws.F1.v = "Payment Method";
    ws.G1.v = "Remarks";
    ws.H1.v = "Amount";
    ws.I1.v = "Status";
    ws.J1.v = "Transaction Details";
    XLSX.utils.book_append_sheet(wb, ws, "Wallet_Usage_Report");
    /* save to file */
    this.fileName =
      "OfflineWalletReport_" +
      new Date(Date.now()).toLocaleString().split(",")[0] +
      ".xlsx";
    XLSX.writeFile(wb, this.fileName);
  }

}
