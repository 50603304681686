import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { SortDirection, SortEvent } from '@allianz/ngx-ndbx/table';
import { CustomErrorHandler } from '../core/custom-error-handler';
import { MESSAGE_CONSTS } from '../ValidationMessages';

@Component({
  selector: 'app-colormanagement',
  templateUrl: './colormanagement.component.html',
  styleUrls: ['./colormanagement.component.css']
})
export class ColormanagementComponent implements OnInit {

  colorModalOpen: boolean = false;
  confirmModalOpen: boolean = false;
  colorForm: FormGroup;
  _apiUrl: string;
  clientId: number;
  colorList = [];
  isSuccess: boolean = false;
  EDIT_FLAG: boolean;
  colorId;
  colorDetails = {};

   /** sorting starts here */
sortTable(sort: SortEvent) {//Sorts the table data by a certain category.
  this.colorList = this.colorList.sort((a, b) => {
    if (sort.active in a && sort.active in b) {
      return this.compare(a[sort.active], b[sort.active], sort.direction);
    }
    return 0;
  });
}

compare(
  a: number | string | Date,
  b: number | string | Date,
  direction: SortDirection) {

  return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
}
/** sorting ends here */

/**Pagination Start */
count: number;
pageNumber: number = 1;
pageSize: number = 10;
searchTerm: any = "";
prevPage() {
  this.pageNumber--;
  this.getColorList();
}

nextPage() {
  this.pageNumber++;
  this.getColorList();
}

goToPage(n: number) {
  this.pageNumber = n;
    this.getColorList();
}
/**Pagination end */

  constructor(private authService: AuthService, private appConfig : AppConfig, private errorHandler: CustomErrorHandler) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.colorFormBuilder();
  }

  ngOnInit() {
    this.getColorList();
  }

  colorFormBuilder() {
    this.colorForm = new FormBuilder().group({
      colorName: new FormControl('', [Validators.required,Validators.pattern(MESSAGE_CONSTS.ALLOWED_SPECIALCHAR_REGEX)]),
      clientId: this.clientId,
      colorId: '',
      isActive: 1
    });
  }
  get f() {
    return this.colorForm.controls;
}
  getColorList() {
    this.authService.getList(this._apiUrl + "/api/v1/Colors" + "?pageNumber=" + this.pageNumber + "&pageSize=" + this.pageSize + "&searchText=" + this.searchTerm).subscribe(response => {
      if (response) {
        this.colorList = response.body;
        var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
        this.count = pagingProp.TotalCount;
      }
    }, (error) => {
      this.errorHandler.handleError(error);
    });
  }
  toggleModal(EDIT: boolean, colorId) {
    this.EDIT_FLAG = EDIT;
    this.colorId = colorId;
    this.colorModalOpen = !this.colorModalOpen;
    this.isSuccess = false;
    if (this.EDIT_FLAG) {
      this.getColorDetails(colorId);
    }
    if (!this.colorModalOpen) {
      this.colorFormBuilder();
    }
  }
  addColorMaster() {

    this.authService.post(this._apiUrl + '/api/v1/Colors', this.colorForm.value).subscribe(response => {
      if (response) {
        this.getColorList();
        this.isSuccess = true;
      }
    }, (error) => {
      this.errorHandler.handleError(error);
    });
  }
  getColorDetails(colorId) {
    this.authService.get(this._apiUrl + '/api/v1/Colors/' + colorId).subscribe(response => {
      if (response) {
        // console.log(response);
        this.colorForm.setValue({
          colorName: response.colorName,
          colorId: response.colorId,
          clientId: response.clientId,
          isActive: response.isActive
        })
      }
    }, (error) => {
      this.errorHandler.handleError(error);
    });
  }
  updateColorMaster() {

    this.authService.put(this._apiUrl + '/api/v1/Colors/' + this.colorId, this.colorForm.value).subscribe(response => {
      // if (response) {
      this.getColorList();
      this.isSuccess = true;
      // }
    }, (error) => {
      this.errorHandler.handleError(error);
    });
  }

  confirmModalClose() {
    this.confirmModalOpen = false;
    this.getColorList();
    this.colorFormBuilder();
  }

  confirmModal(colorDetails, isChecked: boolean) {
    let isActive;
    this.confirmModalOpen = true;

    if (isChecked) {
      isActive = 1;
    } else {
      isActive = 0;
    }

    this.colorForm.setValue({
      colorName: colorDetails.colorName,
      colorId: colorDetails.colorId,
      clientId: this.clientId,
      isActive: isActive
    })
  }

  changeColorStatus() {

    this.authService.put(this._apiUrl + '/api/v1/Colors/' + this.colorForm.controls.colorId.value, this.colorForm.value).subscribe(response => {
      // if (response) {
      this.getColorList();
      this.confirmModalClose();

      // }
    }, (error) => {
      this.errorHandler.handleError(error);
    });
  }


}
