<header nx-header>
    <nx-header-row>
      <nx-header-brand>
        <nx-link>
          <a href="#">
            <figure nxFigure>
              <img
                src="assets/APlogo.png"
                alt="Allianz Global Brand Logo"
                role="none"
                style="width: 175px"
              />
            </figure>
  
            &nbsp; &nbsp;
            <div class="headerDivider"></div>
            &nbsp; &nbsp;
  
            <figure nxFigure>
              <img
                src="assets/hyundai-logo.png"
                alt="Allianz Global Brand Logo"
                role="none"
                style="width: 150px; height: 20px"
              />
            </figure>
          </a>
        </nx-link>
      </nx-header-brand>
    </nx-header-row>
</header>
<div>
    <nx-small-stage style="background-color: #cfe9ee;height:400px;">
      <br>
        <nx-card style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;">
            <nx-card-header>
                <nx-icon *ngIf="transactionStatus" name="check-circle" size="m" style="color: green;"></nx-icon>
                <nx-icon *ngIf="!transactionStatus" name="close-circle" size="m" style="color: red;"></nx-icon>
                <h3 nxHeadline="subsection-small" class="nx-margin-bottom-2m">{{responsemessage}}</h3>
            </nx-card-header>
            <p nxHeadline="subsection-xsmall" class="nx-margin-bottom-m">Policy txn id : {{transactionId}}</p>
            <p nxCopytext="normal" *ngIf="transactionStatus">
                * Check your mail for RSA document and invoice.
                Please contact admin if not received. Close all windows to complete process
            </p>
            <p nxCopytext="normal" *ngIf="!transactionStatus">
                * if amount is debited, Do not retry payment again.
                Please contact admin and try again.
            </p>
        </nx-card>
      <br>
      <nx-small-stage-image *ngIf="!transactionStatus" 
        nxSmallStageImageStart
        src="assets/illustrations/man_plugging_in_dishwasher.png"
      ></nx-small-stage-image>
      <nx-small-stage-image *ngIf="transactionStatus" 
        nxSmallStageImageStart
        src="assets/illustrations/Coverage-Business-Vehicles.png"
      ></nx-small-stage-image>
      <nx-small-stage-image *ngIf="transactionStatus" 
        nxSmallStageImageEnd
        src="assets/illustrations/Capital-solutions.png"
      ></nx-small-stage-image>
      <nx-small-stage-image *ngIf="!transactionStatus" 
        nxSmallStageImageEnd
        src="assets/illustrations/man_with_tablet_wondering.png"
      ></nx-small-stage-image>
      <nx-small-stage-image *ngIf="transactionStatus" 
        nxSmallStageImageBottom
        class="nx-hidden-s nx-hidden-xs"
        src="assets/illustrations/Capital-solutions.png"
      ></nx-small-stage-image>
      <nx-small-stage-image *ngIf="!transactionStatus" 
        nxSmallStageImageBottom
        class="nx-hidden-s nx-hidden-xs"
        src="assets/illustrations/man_with_tablet_wondering.png"
      ></nx-small-stage-image>
    </nx-small-stage>

    
    <app-footer></app-footer>
</div>