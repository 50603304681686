<app-header></app-header>
<app-menu></app-menu>
<div nxLayout="grid" style="margin: 2%;">

  <div nxRow>
    <div nxCol="2" nxColOffset="1">
      <nx-formfield nxLabel="Select Client">
        <nx-dropdown #selectedClient (selectionChange)="onClientChanges($event.value)">
          <nx-dropdown-item *ngFor="let item of clientList  | isActiveCheck" [nxValue]="item.clientId">
            {{item.clientName}}
          </nx-dropdown-item>
        </nx-dropdown>

      </nx-formfield>
    </div>

    <div nxCol="6">
      <button *ngIf="addPermission" (click)="toggleModal(false,0)" nxButton="secondary small-medium" type="button"
        style="float: right;">Add User Permission</button>
    </div>
  </div>

  <div nxRow>
    <div nxCol="10" nxColOffset="1">
      <table nxTable zebra>
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell>
              UserType
            </th>
            <th nxHeaderCell>
              User Role
            </th>
            <!-- <th nxHeaderCell>
                View
              </th>
              <th nxHeaderCell>
                Add
              </th>
              <th nxHeaderCell>
                Edit
              </th>
              <th nxHeaderCell>
                Approve/Reject
              </th>
              <th nxHeaderCell>
                Delete/Cancel
              </th> -->
            <th nxHeaderCell>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr nxTableRow *ngFor="let item of userPermissionList">
            <td nxTableCell>{{item.userRights.userTypeId}}</td>
            <td nxTableCell>{{item.userRole}}</td>
            <!-- <td nxTableCell>
                <nx-checkbox [checked]="item.userRights.view" [disabled]="true"></nx-checkbox>
              </td>
              <td nxTableCell>
                <nx-checkbox [checked]="item.userRights.add" [disabled]="true"></nx-checkbox>
              </td>
              <td nxTableCell>
                <nx-checkbox [checked]="item.userRights.edit" [disabled]="true"></nx-checkbox>
              </td>
              <td nxTableCell>
                <nx-checkbox [checked]="item.userRights.approveReject" [disabled]="true"></nx-checkbox>
              </td>
              <td nxTableCell>
                <nx-checkbox [checked]="item.userRights.delete" [disabled]="true"></nx-checkbox>
              </td> -->
            <td nxTableCell>
              <button nxButton="tertiary medium">
                <nx-icon name="edit" size="s" (click)="toggleModal(true,item.userRights.id)"></nx-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-footer></app-footer>


<nx-modal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="modalOpen" [nxShowCloseIcon]="false">
  <div *ngIf="!isSuccess">
    <form name='add-form' [formGroup]="userPermissionForm">

      <div nxLayout="grid">
        <div nxRow nxRowJustify="start,around">
          <h1 nxHeadline="page" *ngIf="!EDIT_FLAG">Add User Permission</h1>
          <h1 nxHeadline="page" *ngIf="EDIT_FLAG">Edit User Permission</h1>
        </div>

        <div nxRow>
          <div nxCol="12" nxAlignSelf="center" [ngClass]="{'disabledbutton': EDIT_FLAG}">
            <nx-formfield nxLabel="User Role">
              <nx-dropdown formControlName="userTypeId">
                <nx-dropdown-item *ngFor="let item of userTypeList | isActiveCheck" [nxValue]="item.userTypeId">
                  {{item.userType}}
                </nx-dropdown-item>
              </nx-dropdown>
              <nx-error nxFormfieldError>
                <strong>Please note:</strong> this field is required!
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12" nxAlignSelf="center">
            <nx-label>Assign Permissions</nx-label>
            <nx-checkbox-group>
              <div class="horizontal-checkboxes">
                <nx-checkbox formControlName="view">View</nx-checkbox>
                <nx-checkbox formControlName="add" class="nx-margin-left-s">Add</nx-checkbox>
                <nx-checkbox formControlName="edit" class="nx-margin-left-s">Edit</nx-checkbox>
                <nx-checkbox formControlName="approveReject" class="nx-margin-left-s">Approve/Reject</nx-checkbox>
              </div>
            </nx-checkbox-group>
            <nx-checkbox-group>
              <div class="horizontal-checkboxes">
                <nx-checkbox formControlName="delete" >Delete/Cancel</nx-checkbox>
                <nx-checkbox formControlName="acceptWallet" class="nx-margin-left-s">Approve wallet</nx-checkbox>
                <nx-checkbox formControlName="rejectWallet" class="nx-margin-left-s">Reject wallet</nx-checkbox>
                <nx-checkbox formControlName="acceptUser" class="nx-margin-left-s">Approve user</nx-checkbox>
              </div>
            </nx-checkbox-group>
            <nx-checkbox-group>
              <div class="horizontal-checkboxes">
                <nx-checkbox formControlName="rejectUser">Reject user</nx-checkbox>
                <nx-checkbox formControlName="isOfflinePayment" class="nx-margin-left-s">Offline Payment</nx-checkbox>
                <nx-checkbox formControlName="isOnlinePayment" class="nx-margin-left-s">Online Payment</nx-checkbox>
              </div>
            </nx-checkbox-group>
            <nx-checkbox-group>
              <div class="horizontal-checkboxes">
                <nx-checkbox formControlName="isPlanManagae">Manage Plan Master</nx-checkbox>
                <nx-checkbox formControlName="cancelPolicy" class="nx-margin-left-s">Cancel Policy</nx-checkbox>
                <nx-checkbox formControlName="editPolicy" class="nx-margin-left-s">Edit Policy</nx-checkbox>
              </div>
            </nx-checkbox-group>
            <nx-checkbox-group>
              <div class="horizontal-checkboxes">
                <nx-checkbox formControlName="cancelPolicyApprove">Policy Cancellation Approval</nx-checkbox>
                <nx-checkbox formControlName="editPolicyApprove" class="nx-margin-left-s">Policy Modification Approval</nx-checkbox>
              </div>
            </nx-checkbox-group>
            <nx-checkbox-group>
              <div class="horizontal-checkboxes">
                <nx-checkbox formControlName="isEmailNotification">Enable Email Notification</nx-checkbox>
                <nx-checkbox formControlName="isSMSNotification" class="nx-margin-left-s">Enable SMS Notification</nx-checkbox>
              </div>
            </nx-checkbox-group>
          </div>
        </div>

        <div nxRow style="margin-top: 5%;">
          <div nxCol="12">
            <button (click)="addUserPermissionMaster()" [disabled]="userPermissionForm.invalid" nxButton="primary"
              type="button" *ngIf="!EDIT_FLAG">Add</button>
            <button (click)="updateUserPermissionMaster()" [disabled]="userPermissionForm.invalid" nxButton="primary"
              type="button" *ngIf="EDIT_FLAG">Update</button>
            <button (click)="toggleModal(false,0)" class="nx-margin-left-2xs" nxButton="secondary" type="button">Close</button>
          </div>
        </div>

      </div>
    </form>
  </div>
  <div *ngIf="isSuccess">
    <div nxRow nxRowJustify='start,around'>
      <div nxCol="12" id="successModalImage" class="success_image">
      </div>
    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="!EDIT_FLAG">Permissions added successfully
        </h3>
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="EDIT_FLAG">Permissions updated
          successfully</h3>
      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="12">
        <button (click)="toggleModal(false,0)" nxButton="block primary" id="returnButton">
          close
        </button>
      </div>
    </div>
  </div>
</nx-modal>


