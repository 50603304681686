import { NxMessageToastService } from '@allianz/ngx-ndbx/message';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable()
export class CustomErrorHandler {
    errorMessage: string;

    constructor(private messageToastService: NxMessageToastService
      ) { }

    handleError(error) {
        if (error.error instanceof ErrorEvent) {
          // client-side error
          this.errorMessage = `Error : ${error.error.message}`;
        } else {
          // server-side error
          this.errorMessage = `Error : ${error.error}`;
        }
        this.messageToastService.open(this.errorMessage, { context: 'info', duration: 5000 });
        return throwError(this.errorMessage);
      }

      handleCustomError(customError) {
        this.errorMessage = `Error : ${customError}`;
        this.messageToastService.open(this.errorMessage, { context: 'info', duration: 5000 });
        return throwError(this.errorMessage);
      }

      handleCustomInfo(customError) {
        this.errorMessage = `Info : ${customError}`;
        this.messageToastService.open(this.errorMessage, { context: 'info', duration: 5000 });
        return throwError(this.errorMessage);
      }
}
