<header nx-header>
  <nx-header-row>
    <nx-header-brand>
      <nx-link>
        <a href="#">
          <figure nxFigure>
            <img src='assets/APlogo.png' alt="Allianz Global Brand Logo" role="none">
          </figure>
        </a>
      </nx-link>
    </nx-header-brand>
    <nx-header-actions>
      <button nxButton="secondary small" *ngIf="!navigation" class="login-button nx-margin-bottom-0" type="button"
        routerLink="/">Login</button>
      <button nxButton="secondary small" *ngIf="navigation" class="login-button nx-margin-bottom-0" type="button"
        routerLink="/dashboard">Go Back</button>
    </nx-header-actions>
  </nx-header-row>
</header>


<!-- <nx-card> -->
<app-title *ngIf="login=='workshop'" [desktopTitle]="'Workshop Registration'"></app-title>
<app-title *ngIf="login=='dealer'" [desktopTitle]="'Dealer Registration'"></app-title>


<div class="color-container">
<div class="wrapper">
  <div class="nx-margin-s margin-box">
    <nx-card class="card">

        <form [formGroup]="workShopForm" (ngSubmit)="onSubmit()">
          <div nxLayout='grid'>

            <div nxRow>
              <div nxCol="2,8" nxColOffset="2">
                <nx-message nxContext="error" *ngIf="!closed" closable="true" (close)="closed = true">{{errorMessage}}
                </nx-message>
              </div>
            </div>
            <div nxRow >
              <div nxCol="10,10" nxColOffset="1" style="margin-bottom:1%;">
                <nx-link>
                  <a >
                    <nx-icon name='user-o'></nx-icon>
                    <h3 nxHeadline="section" class="nx-margin-bottom-0">Personal Details</h3>
                  </a>
                </nx-link>
              </div>


              <div nxCol="5,5" nxColOffset="1" [ngClass]="{'disabledbutton': userId}">
                <nx-formfield nxLabel="Dealer Code" >
                  <input nxInput formControlName="dealerCode" />
                  
                  <nx-error nxFormfieldError>
                    <strong>Please note:</strong> Dealer code is required!
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="5,5">
                <nx-formfield *ngIf="login=='workshop'" nxLabel="Workshop code">
                  <input nxInput formControlName="workshopCode" />
                  <nx-error nxFormfieldError>
                    <div *ngIf="f.workshopCode.errors?.required"><strong>Please note:</strong> Workshop code is required.</div>
                    <div *ngIf="f.workshopCode.errors?.minlength"><strong>Please note:</strong> Workshop code should be 5
                      character.</div>
                    <div *ngIf="f.workshopCode.errors?.maxlength"><strong>Please note:</strong> Workshop code should be 5
                      character.</div>
                    <div *ngIf="f.workshopCode.errors?.pattern"><strong>Please note:</strong> Workshop code should be alpha
                      numeric.</div>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="5,5" nxColOffset="1" >
                <nx-formfield *ngIf="login=='workshop'" nxLabel="Workshop Name">
                  <input nxInput formControlName="workshopName" />
                  <nx-error nxFormfieldError>
                    <div *ngIf="f.workshopName.errors?.required"><strong>Please note:</strong> Workshop name is required.</div>
                    <div *ngIf="f.workshopName.errors?.pattern">
                      <strong>Please note:</strong> Workshop name is not valid.
                      <br>Allows Alphabets only
                    </div>
                  </nx-error>
                </nx-formfield>
                <nx-formfield *ngIf="login=='dealer'" nxLabel="Dealer Name">
                  <input nxInput formControlName="workshopName" />
                  <nx-error nxFormfieldError>
                    <div *ngIf="f.workshopName.errors?.required">
                      <strong>Please note:</strong> Dealer name is required.
                    </div>
                    <div *ngIf="f.workshopName.errors?.pattern">
                      <strong>Please note:</strong> Dealer name is not valid.
                      <br>Allows Alphabets only
                    </div>
                  </nx-error>
                </nx-formfield>
              </div>
              <!-- <div nxCol="4,4">
              <nx-formfield nxLabel="Dealer Name">
                <input nxInput  formControlName="dealerName"/>

              </nx-formfield>
            </div>
            <div nxCol="4,4" nxColOffset="2">
              <nx-formfield nxLabel="Regional Sales Manager">
                <input nxInput formControlName="RSManager"/>

              </nx-formfield>

            </div> -->
              <div nxCol="5,5">
                <nx-formfield *ngIf="login=='workshop'" nxLabel="Workshop Address">
                  <textarea nxInput formControlName="workshopAddress"></textarea>
                  <nx-error nxFormfieldError>
                    <div *ngIf="f.workshopAddress.errors?.required">
                      <strong>Please note:</strong> Workshop address is required.
                    </div>
                    <div *ngIf="f.workshopAddress.errors?.pattern"><strong>Please note:</strong> Enter Valid Address.
                      <br>Allowed charcters are -_,@/.&lt;space&gt; and Alpha numerics</div>
                  </nx-error>
                </nx-formfield>
                <nx-formfield *ngIf="login=='dealer'" nxLabel="Dealer Address">
                  <input nxInput formControlName="workshopAddress" />
                  <nx-error nxFormfieldError>
                    <div *ngIf="f.workshopAddress.errors?.required">
                      <strong>Please note:</strong> Dealer address is required.
                    </div>
                    <div *ngIf="f.workshopAddress.errors?.pattern"><strong>Please note:</strong> Enter Valid Address.
                      <br>Allowed charcters are -_,@/.&lt;space&gt; and Alpha numerics</div>
                  </nx-error>
                </nx-formfield>
              </div>


              <div nxCol="5,5" nxColOffset="1" >
                <nx-formfield nxLabel="Contact Person">
                  <input nxInput formControlName="contactPerson" />
                  <nx-error nxFormfieldError>
                    <div *ngIf="f.contactPerson.errors?.required">
                      <strong>Please note:</strong> Please enter contact person
                      name.
                    </div>
                    <div *ngIf="f.contactPerson.errors?.pattern">
                      <strong>Please note:</strong> Contact Person is not valid.
                      <br>Allows Alphabets only
                    </div>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="5,5" >
                <nx-formfield nxLabel="Contact Number">
                  <input nxInput formControlName="contactNumber" />
                  <nx-error nxFormfieldError>
                    <div *ngIf="f.contactNumber.errors?.required"><strong>Please note:</strong>Contact Number is required.
                    </div>
                    <div *ngIf="f.contactNumber.errors?.pattern"><strong>Please note:</strong>Invalid mobile number.</div>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="5,5" nxColOffset="1" >
                <div></div>
                <nx-formfield nxLabel="Service Tax Number" *ngIf="false">
                  <input nxInput formControlName="serviceTaxNumber" />
                </nx-formfield>
                <nx-formfield nxLabel="Select Region">
                  <nx-dropdown formControlName="zoneId" (selectionChange)="onzoneChanges($event.value)">
                    <nx-dropdown-item *ngFor="let item of zoneList | isActiveCheck" [nxValue]="item.id">{{item.zone}}
                    </nx-dropdown-item>
                  </nx-dropdown>
                  <nx-icon nxFormfieldAppendix name="info-circle-o" [nxPopoverTriggerFor]="popoverZone"
                    nxPopoverTrigger="hover">
                  </nx-icon>
                  <nx-error nxFormfieldError>
                    <strong>Please note:</strong> this field is required!
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="5,5"  >
                <nx-formfield nxLabel="Select BD">
                  <nx-dropdown formControlName="superUserId">
                    <nx-dropdown-item *ngFor="let item of asmUsers |isActiveCheck" [nxValue]="item.userId">{{item.firstName}} {{item.lastName}}
                    </nx-dropdown-item>
                  </nx-dropdown>
                  <nx-error nxFormfieldError>
                    <strong>Please note:</strong> this field is required!
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="5,5" nxColOffset="1" >
                <nx-formfield nxLabel="PAN Number">
                  <input nxInput formControlName="panNo" />
                  <nx-error nxFormfieldError>
                    <!-- <div *ngIf="f.panNo.errors?.required"><strong>Please note:</strong>PAN Number is required.</div> -->
                    <div *ngIf="f.panNo.errors?.pattern"><strong>Please note:</strong> Invalid PAN Number.</div>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="5,5"  >
                <nx-formfield nxLabel="GST Number">
                  <input #gstNumber nxInput formControlName="gstNumber" (keyup)="gstNumber.value = gstNumber.value.toUpperCase()"
                  (focusout)="gstNumber.value = gstNumber.value.toUpperCase()"/>
                  <nx-error nxFormfieldError>
                    <div *ngIf="f.gstNumber.errors?.required"><strong>Please note:</strong>GSTIN Number is required.</div>
                    <div *ngIf="f.gstNumber.errors?.pattern"><strong>Please note:</strong>Invalid GSTIN Number.(Use Block Letters only)</div>
                  </nx-error>
                </nx-formfield>
              </div>

              <div nxCol="5,5" nxColOffset="1" >
                <nx-formfield nxLabel="Primary Email">
                  <input nxInput formControlName="primaryEmailIdContactPerson" />
                  <nx-icon nxFormfieldAppendix name="info-circle-o" [nxPopoverTriggerFor]="popoverEmail"
                    nxPopoverTrigger="hover">
                  </nx-icon>

                  <nx-error nxFormfieldError>
                    <div *ngIf="f.primaryEmailIdContactPerson.errors?.required"><strong>Please note:</strong>Email id is
                      required.</div>
                    <div *ngIf="f.primaryEmailIdContactPerson.errors?.pattern"><strong>Please note:</strong>Invalid email
                      format.</div>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="5,5" >
                <nx-formfield nxLabel="Secondary Email">
                  <input nxInput formControlName="contactPersonEmailId" />
                  <nx-error nxFormfieldError>
                    <div *ngIf="f.contactPersonEmailId.errors?.pattern"><strong>Please note:</strong>Invalid email format.
                    </div>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="5,5" nxColOffset="1" >
                <nx-formfield nxLabel="State">
                  <nx-dropdown formControlName="stateId" (selectionChange)="getStateWiseCityList($event.value)">
                    <nx-dropdown-item *ngFor="let item of stateList | isActiveCheck" [nxValue]="item.id">{{item.stateName}}
                    </nx-dropdown-item>
                  </nx-dropdown>
                  <nx-error nxFormfieldError>
                    <strong>Please note:</strong> this field is required!
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="5,5" >
                <nx-formfield nxLabel="City">
                  <nx-dropdown formControlName="cityId">
                      <nx-dropdown-item *ngFor="let item of cityList | isActiveCheck" [nxValue]="item.id">
                          {{item.cityName}}
                      </nx-dropdown-item>
                  </nx-dropdown>
                  <nx-error nxFormfieldError>
                    <strong>Please note:</strong> this field is required!
                  </nx-error>
              </nx-formfield>
              </div>
              <div nxCol="5,5" nxColOffset="1" >
                <nx-formfield nxLabel="PIN Code">
                  <input nxInput formControlName="pincode" nxMask="000000" />
                  <nx-error nxFormfieldError>
                      <div *ngIf="f.pincode.errors?.required"><strong>Please note:</strong> This
                          field is
                          required!</div>
                      <div *ngIf="f.pincode.errors?.pattern"><strong>Please note:</strong> Invalid
                          PIN Code</div>
                  </nx-error>
                  <span nxFormfieldHint>
                      eg: 002215
                  </span>
              </nx-formfield>
              </div>
              <div nxCol="5,5" ></div>

              <!-- Bank Details -->

              <div nxCol="10,10" nxColOffset="1" style="margin-bottom:1%;margin-top:2%">
                <nx-link>
                  <a >
                    <nx-icon name='product-bank-monument-info'></nx-icon>
                    <h3 nxHeadline="section" class="nx-margin-bottom-0">Bank Details</h3>
                  </a>
                </nx-link>
              </div>

              <div nxCol="5,5" nxColOffset="1" >
                <nx-formfield nxLabel="Bank Account Number">
                  <input nxInput formControlName="bankAccountNo" />
                  <nx-error nxFormfieldError>
                    <div *ngIf="f.bankAccountNo.errors?.required"><strong>Please note:</strong> Enter account number.</div>
                    <div *ngIf="f.bankAccountNo.errors?.pattern"><strong>Please note:</strong> Accepts only numeric values.
                    </div>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="5,5" >
                <nx-formfield nxLabel="Bank Name">
                  <input nxInput formControlName="bankName" />
                  <nx-error nxFormfieldError>
                    <div *ngIf="f.bankName.errors?.required">
                      <strong>Please note:</strong> Enter bank name.
                    </div>
                    <div *ngIf="f.bankName.errors?.pattern"><strong>Please note:</strong> Enter Valid data.
                      <br>Allowed Alpha numerics only</div>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="5,5" nxColOffset="1" >
                <nx-formfield nxLabel="IFSC Code">
                  <input nxInput formControlName="ifscCode" />
                  <nx-error nxFormfieldError>
                    <div *ngIf="f.ifscCode.errors?.required">
                      <strong>Please note:</strong> Enter IFSC code.
                    </div>
                    <div *ngIf="f.ifscCode.errors?.pattern"><strong>Please note:</strong> Enter Valid data.
                      <br>Allowed Alpha numerics only</div>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="5,5"  >
                <nx-formfield nxLabel="Beneficiary Name">
                  <input nxInput formControlName="beneficiaryName" />
                  <nx-error nxFormfieldError>
                    <div *ngIf="f.beneficiaryName.errors?.required">
                      <strong>Please note:</strong> Enter beneficiary name.
                    </div>
                    <div *ngIf="f.beneficiaryName.errors?.pattern"><strong>Please note:</strong> Enter Valid beneficiary name.
                      <br>Allowed Alpha numerics only</div>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="5,5" nxColOffset="1" >
                <nx-formfield nxLabel="Bank Branch Address">
                  <textarea nxInput formControlName="bankBranchAddress"></textarea>
                  <nx-error nxFormfieldError>
                    <div *ngIf="f.bankBranchAddress.errors?.required"><strong>Please note:</strong> Enter branch address.</div>
                    <div *ngIf="f.bankBranchAddress.errors?.pattern"><strong>Please note:</strong> Enter Valid branch address.
                      <br>Allowed charcters are -_@,/.&lt;space&gt; and Alpha numerics</div>
                  </nx-error>
                </nx-formfield>
              </div>
              <!-- <nx-radio-group [(ngModel)]="templateModel" name="templateTest">
                    <nx-label class="nx-margin-bottom-m">What do you prefer?</nx-label>
                    <nx-radio nxValue="apples" class="nx-margin-bottom-m">I like Apples</nx-radio>
                    <nx-radio nxValue="oranges">I like Oranges</nx-radio>
                  </nx-radio-group>
                  <p>Current Value: {{templateModel}}</p> -->

            </div>

            <div nxRow>
              <div nxCol="5,5" nxColOffset="1" >
                <nx-label>Upload Cancel Cheque(*.pdf files only)</nx-label>
                <app-upload (onUploadFinished)="checkUploadFinished($event)" [acceptedfiles]="accpetedTypes"></app-upload>
              </div>
              <div nxCol="5,5"  >
                <nx-label>Upload Pancard(*.pdf files only)</nx-label>
                <app-upload (onUploadFinished)="panUploadFinished($event)" [acceptedfiles]="accpetedTypes"></app-upload>
              </div>
            </div>

            <div nxRow style="margin-top: 2%;">
              <div nxCol="5,5" nxColOffset="1" >
                <nx-label>Upload GST Certificate(*.pdf files only)</nx-label>
                <app-upload [acceptedfiles]="accpetedTypes" (onUploadFinished)="gstUploadFinished($event)" ></app-upload>
              </div>
            </div>

            <div nxRow style="margin-top: 2%;">
              <div nxCol="5,5" nxColOffset="1" >
              <button nxButton="emphasis block secondary large" type="button" (click)="reload()">
                <nx-icon size="s" name="refresh" class="nx-margin-right-s"></nx-icon> Reset
              </button>
              </div>
              <div nxCol="5,5">
              <button nxButton="cta block primary large" [disabled]="!workShopForm.valid"> 
                <nx-icon size="s" name="user-business" class="nx-margin-right-s"></nx-icon> Register
              </button>
            </div>
            </div>
          </div>
        </form>
      <!-- </nx-card-footer> -->
    </nx-card>
  </div>
</div>
</div>

<div class="loader" [hidden]="spinner">
  <!-- <nx-spinner nxSize="large"></nx-spinner> -->
</div>

<nx-popover #popoverEmail>
  <div style="max-width: 200px;">
    This will be your user name for login.
  </div>
</nx-popover>
<nx-popover #popoverZone>
  <div style="max-width: 200px;">
    Zone Loaded based on ASM.
  </div>
</nx-popover>
<!-- </nx-card> -->
<app-footer></app-footer>
