export class sellPolicyData {
  id: number;
  policyStatusId: number;
  workshopId: number;
  policyNo: string;
  vinChassisNo: string;
  licensePlateNo: string;
  model: string;
  colorName: string;
  vehicleSaleDate: Date;
  variant: string;
  invoiceDate: Date;
  otherDetails: string;
  policyName: string;
  effectiveDate: Date;
  expiryDate: Date;
  price: number;
  gstPrice: number;
  customerFirstName: string;
  customerMiddleName: string;
  customerLastName: string;
  customerPhoneNo: string;
  customerFaxNo: string;
  customerMobileNo: string;
  customerEmail: string;
  customerCompany: string;
  paymentMethod: string;
  paymentDate: Date;
  soldBy: string;
  unitPrice: number;
  status: number;
  updatedDate: string;
  state: string;
  city: string;
  stateId: number;
  cityId: number;
  pincode: string;
  priceWcomm: number;
  outstandingBal: number;
  brand: string;
  unityStatus: number;
  planIdentifier: string;
  invoiceNo: string;
  custGstNumber: string;
  awpAmount: number;
  awpAmountGst: number;
  clientId: number;
  ageOfVehicle: number;
  soldByNumber: string;
  customerAddress: string;
  royalityAmount: number;
  offerIdentifier: string;
  planId:number;
}

export class policyPosttData {
  policyStatusId: number;
  workshopId: number;
  vinChassisNo: string;
  licensePlateNo: string;
  model: string;
  colorName: string;
  vehicleSaleDate: Date;
  variant: string;
  invoiceDate: Date;
  otherDetails: string;
  policyName: string;
  effectiveDate: Date;
  expiryDate: Date;
  customerFirstName: string;
  customerMiddleName: string;
  customerLastName: string;
  customerPhoneNo: string;
  customerFaxNo: string;
  customerMobileNo: string;
  customerEmail: string;
  customerCompany: string;
  paymentMethod: string;
  paymentDate: Date;
  soldBy: string;
  soldByNumber: string;
  stateId: number;
  cityId: number;
  pincode: string;
  brand: string;
  planIdentifier: string;
  custGstNumber: string;
  ageOfVehicle: number;
  clientId: number;
  customerAddress: string;
  offerId: string;
  transactionId : string;
  hash : string;
}
