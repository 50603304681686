
<nx-selectable-card>
  <nx-card-header>
  <app-title [desktopTitle]="'Payment Details'" [padding]="'30px 0px 5px 0px'"></app-title>
  <hr>
  </nx-card-header>

  <div nxLayout="grid" class="card-content">
    <form [formGroup]="paymentDetailForm">
      <div nxRow nxRowAlignItems="center,center,center,center">
        <div nxCol="12,12,4,2" >
          <figure nxFigure="rounded">
            <img src="/assets/illustrations/illustration_service_plan_pay.png" alt="Image Not Found"/>
            </figure>
        </div>

        <div nxCol="12,12,4,2"  style="text-align: -webkit-center;">
          <button nxIconButton="tertiary" class="navigation" aria-label="prev page" type="button" (click)="PrevPage()" [routerLink]="['/sellpolicy/plandetails']">
            <ul class="iconlist iconlist__item ">
                <nx-icon size="l" name="chevron-left-circle-o" ></nx-icon><span style="font-size: small;">Prev</span>
            </ul>
          </button>
        </div>

        <div  nxCol="12,12,6" >
          <div nxRow nxRowJustify="start,start,around" nxRowAlignContent="center,center">
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="Payment Method">
                <nx-dropdown  formControlName="paymentMethod">
                  <nx-dropdown-item nxValue="wallet">Wallet</nx-dropdown-item>
                </nx-dropdown>
                <nx-error nxFormfieldError>
                  Please select an option.
                </nx-error>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="Selling Advisor">
                <nx-formfield-label class="required">Selling Advisor</nx-formfield-label>
                <input nxInput formControlName="soldBy" />
                <nx-error nxFormfieldError>
                  <div *ngIf="f.soldBy.errors?.required">
                    <strong>Please note:</strong> Sold by is required!
                  </div>
                  <div *ngIf="f.soldBy.errors?.pattern"><strong>Please note:</strong>Enter Valid Name.
                    <br>Allows Alphabets only</div>
                </nx-error>
              </nx-formfield>              
            </div>
          </div>

          <div nxRow nxRowJustify="start,start,around" nxRowAlignContent="center,center">
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="Selling Advisor Phone Number">
                <input nxInput formControlName="soldByNumber" />
                <nx-error nxFormfieldError>
                  <div *ngIf="f.soldByNumber.errors?.pattern">
                    <strong>Please note:</strong>Invalid mobile format.
                  </div>
                </nx-error>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="Payment Date">
                <input
                  nxInput
                  value="{{ currentDate | date: 'MM/dd/yyyy' }}"
                  readonly
                />
              </nx-formfield>              
            </div>
          </div>

          <div nxRow nxRowJustify="start,start,around" nxRowAlignContent="center,center">
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="Unit Price">
                <input nxInput formControlName="unitPrice" readonly />
              </nx-formfield>
            </div>
            <div nxCol="12,12,6">
              <nx-formfield nxLabel="Outstanding Balance">
                <input nxInput formControlName="outstandingBal" readonly />
              </nx-formfield>              
            </div>
          </div>

          <div nxRow nxRowJustify="start,start,around" nxRowAlignContent="end">
            <div nxCol="12" style="text-align: end;">
              <button nxButton="cta" type="button"  routerLink='/walletrequestlist' *ngIf='isWalletlow'>
                <nx-icon size="s" name="product-dollar" class="nx-margin-right-2xs"></nx-icon>RECHARGE NOW
              </button>
              <button *ngIf='!isWalletlow'
                nxButton="secondary"
                type="button"
                (click)="savePaymentDetails()"
                [disabled]="checkDisable">
                {{BuyNowText}}
                <nx-spinner nxSize="medium" class="nx-margin-x-2xs" *ngIf="buyPolicyInProgress"></nx-spinner>
              </button>
            </div>

          </div>
        </div>
      </div>

    </form>
  </div>


  <nx-card-footer>
      <nx-link>
        <a >
          <nx-icon name='play'></nx-icon>
          Step 4/5
        </a>
      </nx-link>
  </nx-card-footer>
</nx-selectable-card>
