<!-- <app-header></app-header>
<app-menu></app-menu> -->

<div nxLayout="grid" >
    <div nxRow>
        <div nxCol="2" nxColOffset="1">
            <nx-formfield>
              <input nxInput placeholder="Enter Keyword" [(ngModel)]="searchTerm" type="search">
              <span nxFormfieldPrefix>
                <nx-icon name="search" size="s"></nx-icon>
              </span>
              <span nxFormfieldSuffix *ngIf="searchTerm" aria-hidden="true" (click)="searchTerm='';getPlanMasterList()">
                <nx-icon name="close" size="s"></nx-icon>
              </span>
            </nx-formfield>
          </div>
          <div nxCol="2" class="nx-margin-top-2xs">
            <button nxButton="primary small-medium" type="button" (click)="getPlanMasterList();">Search</button>
          </div>
        <div nxCol="6" class="nx-margin-top-2xs">

            <button  *ngIf="userRights.isPlanManagae" (click)="toggleOfferModal()" hidden nxButton="secondary" type="button" style="float: right;">Add
                Offer</button>
            <button *ngIf="userRights.isPlanManagae" (click)="toggleModal(false,false,0)" nxButton="secondary" type="button" style="float: right;">Add
                Plan</button>
                <button *ngIf="userRights.isPlanManagae" (click)="toggleminBalModal()" nxButton="secondary" type="button" style="float: right;">Edit
                    Min Wallet Balance</button>
        </div>


    </div>
    <div nxRow>
        <div nxCol="10" nxColOffset="1">
            <table nxTable condensed nxSort (sortChange)="sortTable($event)">
                <thead>
                    <tr nxTableRow>
                        <th nxHeaderCell nxSortHeaderCell="planName">
                            Plan Name
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="durationOfPlan">
                            Duration of Policy
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="totalPrice">
                            Amount
                        </th>

                        <th nxHeaderCell nxSortHeaderCell="isActive">
                            Status
                        </th>
                        <th nxHeaderCell *ngIf="userRights.isPlanManagae">
                            Edit
                        </th>
                        <th nxHeaderCell *ngIf="!userRights.isPlanManagae">
                          View
                        </th>
                        <th nxHeaderCell *ngIf="userRights.isPlanManagae">
                            Acivate/Deactivate
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr nxTableRow *ngFor="let item of planMasterList">
                        <td nxTableCell>{{item.planName}}</td>
                        <td nxTableCell>{{item.durationOfPlan}}</td>
                        <td nxTableCell>{{item.totalPrice}}</td>
                        <td nxTableCell *ngIf="item.isActive =='1' else inActive">
                            <nx-badge vibrant type="positive">Active</nx-badge>
                        </td>
                        <ng-template #inActive>
                            <td nxTableCell>
                                <nx-badge vibrant type="critical">In Active</nx-badge>
                            </td>
                        </ng-template>

                        <td nxTableCell *ngIf="userRights.isPlanManagae">
                            <button nxIconButton="tertiary  small" (click)="toggleModal(true,false,item.planId)">
                              <nx-icon name="edit" size="s">
                              </nx-icon>
                            </button>
                        </td>
                        <td nxTableCell *ngIf="!userRights.isPlanManagae">
                          <button nxIconButton="tertiary  small" (click)="toggleModal(false,true,item.planId)">
                            <nx-icon name="password-show-o" size="s">
                            </nx-icon>
                          </button>
                        </td>
                        <td nxTableCell *ngIf="userRights.isPlanManagae && item.isActive =='1'">
                            <nx-switcher  [checked]="true" (change)='confirmModal(item.planId,$event.target.checked)'>
                            </nx-switcher>
                        </td>

                        <td nxTableCell *ngIf="userRights.isPlanManagae && item.isActive =='0'">
                            <nx-switcher [checked]="false" (change)='confirmModal(item.planId,$event.target.checked)'>
                            </nx-switcher>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right;">
      <div nxCol="6" >
        <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced"
          (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
        </nx-pagination>
      </div>
    </div>
</div>
<nx-modal #addPlan [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="planModalOpen" [nxShowCloseIcon]="false">
    <form name='addplan-form' [formGroup]="planForm">
        <div *ngIf="!isSuccess">
            <div nxLayout="grid">
                <div nxRow nxRowJustify="start,around">
                  <h1 nxHeadline="page" *ngIf='!EDIT_FLAG && !VIEW_FLAG'>Add Plan</h1>
                  <h1 nxHeadline="page" *ngIf='EDIT_FLAG'>Edit Plan</h1>
                  <h1 nxHeadline="page" *ngIf='VIEW_FLAG'>View Plan</h1>
                </div>
                <div nxRow>
                    <div nxCol="12,6" nxAlignSelf="center">
                        <nx-formfield nxLabel="Policy Name">
                            <input nxInput formControlName="planName" />
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> Policy name is required!
                            </nx-error>
                        </nx-formfield>
                    </div>
                    <div nxCol="12,6" nxAlignSelf="center">
                        <nx-formfield nxLabel="Duration(Years)">
                            <input nxInput formControlName="durationOfPlan" />
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> Duration is required!
                            </nx-error>
                        </nx-formfield>
                    </div>
                    <div nxCol="12,6" nxAlignSelf="center">
                        <nx-formfield nxLabel="Max veichle age">
                            <input nxInput formControlName="maximumVehicleAge" />
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> Max veichle age field is required!
                            </nx-error>
                        </nx-formfield>
                    </div>

                    <div nxCol="12,6" nxAlignSelf="center">
                        <nx-formfield nxLabel="AWP Risk Price">
                            <input type="number" nxInput formControlName="awpRiskPrice" (change)="updatePrice()"/>
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> AWP Risk Price field is required!
                            </nx-error>
                        </nx-formfield>
                    </div>
                    <div nxCol="12,6" nxAlignSelf="center">
                        <nx-formfield nxLabel="Dealer Margin">
                            <input type="number" nxInput formControlName="dealerMargin" (change)="updatePrice()"/>
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> Dealer Margin field is required!
                            </nx-error>
                        </nx-formfield>
                    </div>
                    <div nxCol="12,6" nxAlignSelf="center">
                        <nx-formfield nxLabel="Customer Engagement Fund">
                            <input type="number" nxInput formControlName="customerEngementFund" (change)="updatePrice()"/>
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> Customer Engagement Fund field is required!
                            </nx-error>
                        </nx-formfield>
                    </div>

                    <div nxCol="12,6" nxAlignSelf="center">
                        <nx-formfield nxLabel="{{CLIENT_NAME}} Margin">
                            <input type="number" nxInput formControlName="royality" (change)="updatePrice()"/>
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> {{CLIENT_NAME}} Margin field is required!
                            </nx-error>
                        </nx-formfield>
                    </div>
                    <div nxCol="12,6" nxAlignSelf="center">
                        <nx-formfield nxLabel="Customer Price">
                            <input type="number" nxInput formControlName="totalPrice" />
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> Total Price field is required!
                            </nx-error>
                        </nx-formfield>
                    </div>
                    <div nxCol="12,6" nxAlignSelf="center" >
                        <nx-formfield nxLabel="Unity/APD PlanId">
                            <input type="number" nxInput formControlName="unityPlanId" />
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> Unity PlanId is required!
                            </nx-error>
                        </nx-formfield>
                    </div>
                    <div nxCol="12,6" nxAlignSelf="center" >
                        <nx-formfield nxLabel="Unity/APD Brand">
                            <input type="text" nxInput formControlName="unityBrand" />
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> Unity/APD Brand is required!
                            </nx-error>
                        </nx-formfield>
                    </div>
                    <div nxCol="12,6" nxAlignSelf="center" >
                        <nx-formfield nxLabel="Unity/APD Product Name">
                            <input type="text" nxInput formControlName="unityProductName"/>
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> Unity/APD Product Name is required!
                            </nx-error>
                        </nx-formfield>
                    </div>
                    <div nxCol="12,12" nxAlignSelf="center">
                      <nx-checkbox   formControlName="isDiscountedPlan" (checkedChange)="isDiscountedPlanChanged(isDiscountedPlanCheck)">Plan discount is applicable.</nx-checkbox>
                    </div>

                    <div nxCol="12,6" nxAlignSelf="center" *ngIf="isDiscountedPlanCheck">
                      <nx-formfield nxLabel="No Of Policies after discount applicable?">
                          <input type="number" nxInput formControlName="discountPolicyCount" />
                          <nx-error nxFormfieldError>
                              <strong>Please note:</strong> Total Price field is required!
                          </nx-error>
                      </nx-formfield>
                  </div>

                  <div nxCol="12,6" nxAlignSelf="center" *ngIf="isDiscountedPlanCheck">
                    <nx-formfield nxLabel="Choose discount Plan">
                        <nx-dropdown formControlName="discountedPlanId" >
                            <nx-dropdown-item *ngFor="let item of planList"
                                [nxValue]="item.planId">{{item.planName}}</nx-dropdown-item>
                        </nx-dropdown>
                        <nx-error nxFormfieldError>
                            Please select an option.
                        </nx-error>
                    </nx-formfield>
                  </div>

                </div>
                <div nxRow >
                    <div nxCol="12" style="text-align: center;">
                        <button (click)="addPlanMaster()" nxButton="primary" type="button" *ngIf='userRights.isPlanManagae && !EDIT_FLAG'
                            [disabled]="planForm.invalid">Add</button>
                        <button (click)="updatePlanMaster()" nxButton="primary" type="button" *ngIf='userRights.isPlanManagae && EDIT_FLAG'
                            [disabled]="planForm.invalid">Update</button>
                        <button (click)="toggleModal(false,false,0)" nxButton="secondary" type="button">Close</button>

                    </div>
                </div>
            </div>
        </div>
    </form>
    <div *ngIf="isSuccess">
        <div nxRow nxRowJustify='start,around'>
        </div>
        <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
            <div nxCol="12">
                <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf='!EDIT_FLAG'>Plan added
                    successfully</h3>
                <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf='EDIT_FLAG'>Plan updated
                    successfully</h3>
            </div>
        </div>
        <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
            <div nxCol="12">
                <button (click)="toggleModal(false,false,0)" nxButton="block primary" id="returnButton">
                    close
                </button>
            </div>
        </div>
    </div>
</nx-modal>

<nx-modal #addOffer [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="offerModalOpen"
    [nxShowCloseIcon]="false">
    <form name='offer-form' [formGroup]="offerForm">

        <div *ngIf="!isOfferSuccess" nxLayout="grid">
            <div nxRow nxRowJustify="start,around">
                <h1 nxHeadline="page">Add Offer</h1>
            </div>
            <div nxRow>
                <div nxCol="12,6" nxAlignSelf="center">
                    <nx-formfield nxLabel="Offer Name">
                        <input nxInput formControlName="offerName" />
                        <nx-error nxFormfieldError>
                            <strong>Please note:</strong> this field is required!
                        </nx-error>
                    </nx-formfield>
                </div>
                <div nxCol="12,6" nxAlignSelf="center">
                    <nx-formfield nxLabel="Offer Discount Percentage">
                        <input nxInput type="number" formControlName="discountPercent" />
                        <span nxFormfieldSuffix>
                            %
                        </span>
                        <nx-error nxFormfieldError>
                            <strong>Please note:</strong> this field is required!
                        </nx-error>
                    </nx-formfield>
                </div>
                <div nxCol="12,12" nxAlignSelf="center">
                    <nx-formfield nxLabel="Select Zone">
                        <nx-dropdown formControlName="zoneIds" [nxIsMultiselect]="true">
                            <nx-dropdown-item *ngFor="let item of zoneList" [nxValue]="item.id" >{{item.zone}}
                            </nx-dropdown-item>
                        </nx-dropdown>
                        <nx-error nxFormfieldError>
                            <strong>Please note:</strong> this field is required!
                        </nx-error>
                    </nx-formfield>
                </div>
            <!-- </div>
            <div nxRow> -->
                <div nxCol="12,6" nxAlignSelf="center">
                    <nx-formfield nxLabel="Start date">
                        <input nxDatefield nxInput [nxDatepicker]="startDatepicker"
                            formControlName="startDate" />
                        <!-- <span nxFormfieldHint>MM/DD/YYYY</span> -->
                        <nx-datepicker-toggle [for]="startDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                        <nx-datepicker #startDatepicker></nx-datepicker>
                        <nx-error nxFormfieldError>
                            <strong>Please note:</strong> this field is required!
                        </nx-error>
                    </nx-formfield>
                </div>
                <div nxCol="12,6" nxAlignSelf="center">
                    <nx-formfield nxLabel="End date">
                        <input nxDatefield nxInput [nxDatepicker]="endDatepicker"
                            formControlName="endDate" />
                        <!-- <span nxFormfieldHint>MM/DD/YYYY</span> -->
                        <nx-datepicker-toggle [for]="endDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                        <nx-datepicker #endDatepicker></nx-datepicker>
                        <nx-error nxFormfieldError>
                            <strong>Please note:</strong> this field is required!
                        </nx-error>
                    </nx-formfield>
                </div>
            </div>

            <div nxRow>
                <div nxCol="12" style="text-align: center;">
                    <button type="button" (click)="addOfferDetails()" nxButton="primary">Submit</button>
                    <button type="button" (click)="toggleOfferModal()" nxButton="secondary">Close</button>
                </div>
            </div>
        </div>
        <div *ngIf="isOfferSuccess">
            <div nxRow nxRowJustify='start,around'>
            </div>
            <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
                <div nxCol="12">
                    <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">Offer added
                        successfully</h3>
                </div>
            </div>
            <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
                <div nxCol="12">
                    <button (click)="toggleOfferModal()" nxButton="block primary">
                        close
                    </button>
                </div>
            </div>

        </div>
    </form>

</nx-modal>
<nx-modal  [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="minBalModalOpen" [nxShowCloseIcon]="false">
    <form [formGroup]="minBalForm">
        <div *ngIf="!isminBalSuccess" nxLayout="grid">
            <div nxRow >
                <div nxCol="12" style="text-align: center;">
                    <h1 nxHeadline="page">Add Minimum Balance</h1>
                </div>

            </div>
            <div nxRow>
                <div nxCol="12" >
                    <nx-formfield nxLabel="Minimum Balance">
                        <input nxInput formControlName="MinWalletBalance"  type="number"/>
                        <!-- <nx-error nxFormfieldError>
                            <strong>Please note:</strong> this field is required!
                        </nx-error> -->
                    </nx-formfield>
                </div>
            </div>
            <div nxRow>
                <div nxCol="12" style="text-align: center;">
                    <button type="button"  nxButton="primary" (click)="saveWalletminBalance()">Submit</button>
                    <button type="button" (click)="minBalModalOpen=false"  nxButton="secondary">Close</button>
                </div>
            </div>
        </div>
        <div *ngIf="isminBalSuccess">
            <div nxRow nxRowJustify="start,around">
                <h3 nxHeadline="subsection-small">Confirmation</h3>
            </div>
            <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
                <div nxCol="12">
                    <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">{{responsemessage}}</h3>
                </div>
            </div>
            <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
                <button type="button" (click)="minBalModalOpen=false"  nxButton="secondary">Close</button>
            </div>
        </div>
    </form>
</nx-modal>
<nx-modal #confirmModal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="confirmModalOpen"
    [nxShowCloseIcon]="false">
    <div nxRow nxRowJustify="start,around">
        <h3 nxHeadline="subsection-small">Confirmation</h3>
    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
        <div nxCol="12">
            <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">Are you sure you want to do this action?</h3>
        </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
        <div nxCol="12">
            <button (click)="changePlanStatus()" nxButton="block primary">
                Yes
            </button>
        </div>
        <div nxCol="6">
            <button (click)="confirmModalClose()" nxButton="block primary">
                No
            </button>
        </div>

    </div>
    <!-- <nx-message nxContext="success">
    User is added successfully.
  </nx-message> -->
</nx-modal>
<!-- <app-footer></app-footer> -->
