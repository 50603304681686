import {
  Component,
  OnInit,
  Input,
  Inject,
  Output,
  EventEmitter,
} from "@angular/core";
import * as moment from "moment";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { SortEvent, SortDirection } from "@allianz/ngx-ndbx/table/public-api";
import * as XLSX from "xlsx";

@Component({
  selector: "app-wallet-usage-report",
  templateUrl: "./wallet-usage-report.component.html",
  styleUrls: ["./wallet-usage-report.component.css"],
})
export class WalletUsageReportComponent {
  @Input() policyStartDate: moment.Moment;
  @Input() policyEndDate: moment.Moment;

  fileName: string;
  ReportData: any;
  exportdata: any = [];
  _apiUrl: string;
  clientId: number;
  userTypeId: number;
  userBaseTypeId: number;
  userId: number;

  /** sorting starts here */

  sortTable(sort: SortEvent) {
    this.ReportData = this.ReportData.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection
  ) {
    return (a < b ? -1 : 1) * (direction === "asc" ? 1 : -1);
  }
  /** sorting ends here */
  /**Pagination Start */
  count: number = 0;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
    this.pageNumber--;
    this.getReportData();
  }

  nextPage() {
    this.pageNumber++;
    this.getReportData();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getReportData();
  }
  /**Pagination end */

  constructor(
    private authService: AuthService,
    private appConfig : AppConfig
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userBaseTypeId = parseInt(atob(localStorage.getItem("USER_BASE_TYPE_ID")));
    this.userTypeId = parseInt(atob(localStorage.getItem("USER_TYPE_ID")));
  }

  loadReport() {
    this.pageNumber = 1;
    this.getReportData();
  }

  getReportData() {
    this.ReportData =null;
    var startdate =
      this.policyStartDate == undefined
        ? ""
        : this.policyStartDate.format("LL");
    var enddate =
      this.policyEndDate == undefined ? "" : this.policyEndDate.format("LL");
    this.authService
      .getList(
        this._apiUrl +
          `/api/v1/Report/WalletUsageReport?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}&searchText=${this.searchTerm}&startDatepicker=${startdate}&endDatepicker=${enddate}`
      )
      .subscribe(
        (response) => {
          if (response) {
            this.ReportData = response.body;
            let pagingProp = JSON.parse(response.headers.get("X-Pagination"));
            this.count = pagingProp.TotalCount;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getExportData() {
    var startdate =
      this.policyStartDate == undefined
        ? ""
        : this.policyStartDate.format("LL");
    var enddate =
      this.policyEndDate == undefined ? "" : this.policyEndDate.format("LL");
    this.authService
      .getList(
        this._apiUrl +
          `/api/v1/Report/WalletUsageReport?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}&searchText=${this.searchTerm}&startDatepicker=${startdate}&endDatepicker=${enddate}`
      )
      .subscribe(
        (response) => {
          if (response) {
            this.exportdata = [];
            response.body.forEach((element) => this.setExportData(element));
            this.bindexcel();
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  setExportData(item: any) {
    var data: any = [];
    data.Region = item.region;
    data.State = item.state;
    data.City = item.city;
    data.DealerCode = item.dealerCode;
    data.DealerName = item.dealerName;
    data.CurrentWalletBalance = item.currentWalletBalance;
    data.LastRechargeAmount = item.lastRechargeAmount;
    data.LastWalletRechargeOn = new Date(
      item.lastWalletRecharge
    ).toLocaleDateString();
    this.exportdata.push(data);
  }

  exporttoexcel(){
    this.pageNumber = -1;
    this.getExportData();
  }

  bindexcel(){
    /* json data is passed over here */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportdata);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    ws.A1.v = "Region";
    ws.B1.v = "State";
    ws.C1.v = "City";
    ws.D1.v = "Dealer Code";
    ws.E1.v = "Dealer Name";
    ws.F1.v = "Current Wallet Balance";
    ws.G1.v = "Last Recharge Amount";
    ws.H1.v = "Last Recharge On";
    XLSX.utils.book_append_sheet(wb, ws, "Wallet_Usage_Report");
    /* save to file */
    this.fileName =
      "WalletUsageReport_" +
      new Date(Date.now()).toLocaleString().split(",")[0] +
      ".xlsx";
    XLSX.writeFile(wb, this.fileName);
  }
}
