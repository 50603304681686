import { Component, OnInit } from '@angular/core';
import { slider } from '../animation';
import { RouterOutlet } from '@angular/router';
import { DatastorageService } from '../datastorage.service';
import { Step } from '../core/step.model';

@Component({
  selector: 'app-addpolicy',
  templateUrl: './addpolicy.component.html',
  styleUrls: ['./addpolicy.component.css'],
  animations: [
    slider
  ]
})
export class AddpolicyComponent implements OnInit {

 constructor(private dataService: DatastorageService) { 
  localStorage.setItem("ModalVisible", "0");
 }

  ngOnInit() {
    this.dataService.nextClick.subscribe((step: Step) => {
      this.completeStep(step);
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  multiSteps = [
    {
      name: 'Vehicle Details',
      completed: false
    },
    {
      name: 'Customer Details',
      completed: false
    },
    {
      name: 'Plan Details',
      completed: false
    },
    {
      name: 'Payment Details',
      completed: false
    }
  ];

  completeStep(step: Step) {
    if(step != null){
      this.multiSteps[step.stepNumber].completed = step.completed;
    }
  }

  // ngOnDestroy(): void{
  //   this.dataService.nextClick.unsubscribe();
  // }
}
