import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { NxMessageToastService } from '@allianz/ngx-ndbx/message';
import { throwError } from 'rxjs';
import { PolicyStatus } from '../core/policyStatus.model';
import { SortEvent, SortDirection } from '@allianz/ngx-ndbx/table';

@Component({
  selector: 'app-walletstatement',
  templateUrl: './walletstatement.component.html',
  styleUrls: ['./walletstatement.component.css']
})
export class WalletstatementComponent implements OnInit {
  private _apiUrl;

  userId: number;
  clientId: number;
  errorMessage: string;
  walletStatementList: any;
  PolicyStatus: any;

  constructor(private authService: AuthService, private appConfig : AppConfig, private messageToastService: NxMessageToastService) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.PolicyStatus = PolicyStatus;
  }

  ngOnInit() {
    this.getWalletStatement();
  }

  /**Pagination Start */
  count: number;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
    this.pageNumber--;
    this.getWalletStatement();
  }

  nextPage() {
    this.pageNumber++;
    this.getWalletStatement();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getWalletStatement();
  }
  /**Pagination end */
  /** sorting starts here */

  sortTable(sort: SortEvent) {//Sorts the table data by a certain category.
    this.walletStatementList = this.walletStatementList.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection) {

    return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
  }
  /** sorting ends here */

  getWalletStatement() {

    this.authService.getList(this._apiUrl + `/api/v1/WalletDetails/WalletStatement?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}&searchText=${this.searchTerm}`).subscribe(response => {
      if (response) {
        this.walletStatementList = response.body;
        let pagingProp = JSON.parse(response.headers.get('X-Pagination'));
        this.count = pagingProp.TotalCount;
      }

    }, (error) => {
      console.error(error);
      this.handleError(error, error.error.text);
    });
  }

  handleError(error, custmsg: string = "") {

    if (error.error instanceof ErrorEvent) {
      // client-side error
      this.errorMessage = `Error : ${error.error.message}`;
    } else {
      // server-side error
      this.errorMessage = `Error : ${error.error}`;
    }
    if (custmsg != "" || custmsg != null) {
      this.errorMessage = `Error : ${custmsg}`;
    }
    this.messageToastService.open(this.errorMessage, { context: 'info', duration: 5000 });
    return throwError(this.errorMessage);

  }

}
