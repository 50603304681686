<app-header></app-header>
<app-menu></app-menu>


<div nxLayout="grid" class="nx-margin-top-2m">

    <div nxRow class="nx-margin-left-4m nx-margin-right-4m">
        <!-- <div nxCol="8" > -->
            <div nxCol="12,12,6,2" >
                <nx-formfield nxLabel="Start date">
                    <input nxDatefield nxInput [nxDatepicker]="startDatepicker" [(ngModel)]="policyStartDate" />
                    <!-- <span nxFormfieldHint>MM/DD/YYYY</span> -->
                    <nx-datepicker-toggle [for]="startDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                    <nx-datepicker #startDatepicker></nx-datepicker>
                    <nx-error nxFormfieldError>
                        <strong>Please note:</strong> this field is required!
                    </nx-error>
                </nx-formfield>
            </div>
            <div nxCol="12,12,6,2" nxAlignSelf="center">
                <nx-formfield nxLabel="End date">
                    <input nxDatefield nxInput [nxDatepicker]="endDatepicker" [(ngModel)]="policyEndDate" />
                    <!-- <span nxFormfieldHint>MM/DD/YYYY</span> -->
                    <nx-datepicker-toggle [for]="endDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                    <nx-datepicker #endDatepicker></nx-datepicker>
                    <nx-error nxFormfieldError>
                        <strong>Please note:</strong> this field is required!
                    </nx-error>
                </nx-formfield>
            </div>
            <div nxCol="12,12,6,3" >
                <nx-formfield>
                    <input nxInput placeholder="Policy Number" [(ngModel)]="policyNumber" type="search">
                    <span nxFormfieldPrefix>
                        <nx-icon name="search" size="s"></nx-icon>
                    </span>
                    <span nxFormfieldSuffix *ngIf="policyNumber" aria-hidden="true" (click)="policyNumber='';getPolicyList()">
                        <nx-icon name="close" size="s"></nx-icon>
                    </span>
                </nx-formfield>
            </div>
            <div nxCol="12,12,6,3" >
                <nx-formfield>
                    <input nxInput placeholder="Enter Keyword" [(ngModel)]="searchTerm" type="search">
                    <span nxFormfieldPrefix>
                        <nx-icon name="search" size="s"></nx-icon>
                    </span>
                    <span nxFormfieldSuffix *ngIf="searchTerm" aria-hidden="true" (click)="searchTerm='';getPolicyList()">
                        <nx-icon name="close" size="s"></nx-icon>
                    </span>
                </nx-formfield>
            </div>
        <!-- </div> class="nx-margin-top-5xl nx-margin-left-2xs"-->
         <div nxCol="2" class="nx-margin-top-2xs">
                <button nxButton="primary small-medium" type="button" (click)="getPolicyList()">Search</button>
        </div>
    </div>

    <div nxRow  nxRowJustify="start,start,around" nxRowAlignContent="end,around,stretch" class="nx-margin-left-4m nx-margin-right-4m">
        <div nxCol="12"  *ngIf="policyList.length!=0">
            <table nxTable zebra condensed nxSort (sortChange)="sortTable($event)">
                <thead style="text-align: center;">
                    <tr nxTableRow>
                        <th nxHeaderCell nxSortHeaderCell="policyNo">
                            Policy Number
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="policyName">
                            Policy Name
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="customerFirstName">
                            Customer Name
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="customerMobileNo">
                            Mobile Number
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="customerEmail">
                            Email
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="paymentDate">
                            Created Date
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="policyStatusId">
                            Status
                        </th>
                        <th nxHeaderCell>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody style="text-align: center;">
                    <tr nxTableRow *ngFor="let item of policyList">
                        <td nxTableCell>{{item.policyNo}}</td>
                        <td nxTableCell>{{item.policyName}}</td>
                        <td nxTableCell>{{item.customerFirstName}} {{item.customerLastName}}</td>
                        <td nxTableCell>{{item.customerMobileNo}}</td>
                        <td nxTableCell>{{item.customerEmail}}</td>
                        <td nxTableCell>{{item.paymentDate | date:'MM/dd/yyyy'}}</td>
                        <td nxTableCell *ngIf="item.policyStatusId == policyStatus.Active" >
                            <nx-badge vibrant type="positive" >Active</nx-badge>
                        </td>
                        <td nxTableCell *ngIf="item.policyStatusId == policyStatus.Cancel" >
                            <nx-badge vibrant type="critical" >Cancelled</nx-badge>
                        </td>
                        <td nxTableCell *ngIf="item.policyStatusId == policyStatus.Modified">
                            <nx-badge vibrant type="active" [nxTooltipPosition]="'top'" [nxTooltip]="'This policy is Modified and in Active state'">Modified</nx-badge>
                        </td>
                        <td nxTableCell *ngIf="item.policyStatusId == policyStatus.CancelationApprovalPending">
                            <nx-badge type="critical" [nxTooltipPosition]="'top'" [nxTooltip]="'Cancelation Approval Pending'" >Pending</nx-badge>
                        </td>
                        <td nxTableCell *ngIf="item.policyStatusId == policyStatus.ModificationApprovalPending">
                            <nx-badge vibrant type="negative" [nxTooltipPosition]="'top'" [nxTooltip]="'Modification Approval Pending'">Pending</nx-badge>
                        </td>
                       
                        <td nxTableCell>
                            <nx-context-menu #menu="nxContextMenu">
                                <ng-template nxContextMenuContent>
                                    <button *ngIf="userPermission.editPolicy" nxContextMenuItem type="button"
                                        (click)="toggleModal($event,item.id,item.transactionId)" [disabled]="isModifiable(item.policyStatusId)">Modify</button>
                                    <button *ngIf="userPermission.cancelPolicy" nxContextMenuItem type="button" (click)="confirmModal(item)" [disabled]="calculateDiff(item.paymentDate,item.policyStatusId)">Cancel </button>
                                    <button (click)="download($event,item.transactionId)"nxContextMenuItem type="button">Download</button>
                                    <button *ngIf="eInvoiceStatus(item) ; else EinvoicePending" (click)="downloadEinvoice($event,item.policyNo)"nxContextMenuItem type="button">Download E-Invoice</button>
                                    <ng-template #EinvoicePending>
                                        <button  *ngIf="item.gstDocPathForFinance != null"  disabled nxContextMenuItem type="button">E-Invoice Pending</button>
                                    </ng-template>
                                </ng-template>
                            </nx-context-menu>
                            <button nxIconButton="tertiary medium" type="button" [nxContextMenuTriggerFor]="menu"
                                aria-label="Open menu" [nxTooltipPosition]="'top'" [nxTooltip]="'Please click here'">
                                <nx-icon aria-hidden="true" name="ellipsis-h"></nx-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <div nxRow  class="nx-margin-left-5xl nx-margin-right-4m" style="float: right;">
                <div nxCol="6">
                    <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced" (nxGoPrev)="prevPage()"
                      (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
                    </nx-pagination>
                  </div>
            </div>
        </div>
        <div nxCol="12,10" class="nx-margin-top-2xl" *ngIf="count==0">
            <ng-container>
              <div nxRow nxRowJustify="center">
                <div nxCol="12" class="nosearchresult"></div>
                <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">No Records found !!!</h3>
              </div>
            </ng-container>
        </div>
    </div>
</div>
<app-footer></app-footer>
<app-editpolicy *ngIf="policyEditModalOpen" [id]="transactionId" (closeModal)="toggleModal($event,0,0)"></app-editpolicy>

<nx-modal #confirmModal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="confirmModalOpen"
  [nxShowCloseIcon]="false">
  <div nxRow nxRowJustify="start,around">
    <h2 >Confirmation</h2>
  </div>
  <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
    <div nxCol="12">
      <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">Are you sure you want to do this action?</h3>
    </div>
  </div>
  <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
    <div nxCol="6">
      <button (click)="sendCancelRequest()" nxButton="block primary">
        Yes
      </button>
    </div>
    <div nxCol="6">
      <button (click)="confirmModalClose()" nxButton="block primary">
        No
      </button>
    </div>

  </div>
  <!-- <nx-message nxContext="success">
    User is added successfully.
  </nx-message> -->
</nx-modal>
