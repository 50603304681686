import {
  Component,
  OnInit,
  Input,
  Inject,
  Output,
  EventEmitter,
} from "@angular/core";
import * as moment from "moment";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { SortEvent, SortDirection } from "@allianz/ngx-ndbx/table/public-api";
import * as XLSX from "xlsx";
import { PolicyStatus } from '../core/policyStatus.model';

@Component({
  selector: 'app-policy-sale-report',
  templateUrl: './policy-sale-report.component.html',
  styleUrls: ['./policy-sale-report.component.css']
})
export class PolicySaleReportComponent {
  @Input() policyStartDate: moment.Moment;
  @Input() policyEndDate: moment.Moment;

  fileName: string;
  ReportData: any;
  exportdata: any = [];
  _apiUrl: string;
  clientId: number;
  userTypeId: number;
  userBaseTypeId: number;
  userId: number;
  policyStatus: any;

  /** sorting starts here */

  sortTable(sort: SortEvent) {
    this.ReportData = this.ReportData.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection
  ) {
    return (a < b ? -1 : 1) * (direction === "asc" ? 1 : -1);
  }
  /** sorting ends here */
  /**Pagination Start */
  count: number = 0;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
    this.pageNumber--;
    this.getReportData();
  }

  nextPage() {
    this.pageNumber++;
    this.getReportData();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getReportData();
  }
  /**Pagination end */

  constructor(
    private authService: AuthService,
    private appConfig : AppConfig
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userBaseTypeId = parseInt(atob(localStorage.getItem("USER_BASE_TYPE_ID")));
    this.userTypeId = parseInt(atob(localStorage.getItem("USER_TYPE_ID")));
    this.policyStatus = PolicyStatus;
  }

  loadReport() {
    this.pageNumber =1;
    this.getReportData();
  }

  getReportData() {
    var startdate = this.policyStartDate == undefined ? "" : this.policyStartDate.format('LL');
    var enddate = this.policyEndDate == undefined ? "" : this.policyEndDate.format('LL');
    this.authService
      .getList(this._apiUrl + "/api/v1/PolicyDetails"  +
        "?pageNumber=" + this.pageNumber + "&pageSize=" + this.pageSize + "&searchText=" + this.searchTerm + "&startDatepicker=" + startdate + "&endDatepicker=" + enddate)
      .subscribe(
        (response) => {
          if (response) {
            this.ReportData = response.body;
            var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
            this.count = pagingProp.TotalCount;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getExportData() {
    var startdate = this.policyStartDate == undefined ? "" : this.policyStartDate.format('LL');
    var enddate = this.policyEndDate == undefined ? "" : this.policyEndDate.format('LL');
    this.authService
      .getList(this._apiUrl + "/api/v1/PolicyDetails" +
        "?pageNumber=" + this.pageNumber + "&pageSize=" + this.pageSize + "&searchText=" + this.searchTerm + "&startDatepicker=" + startdate + "&endDatepicker=" + enddate )
      .subscribe(
        (response) => {
          if (response) {
            this.exportdata = [];
            response.body.forEach(element => this.setExportData(element));
            this.bindexcel();
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  setExportData(item: any) {
    var data: any = [];
    data.policyNo = item.policyNo;
    data.vinChassisNo = item.vinChassisNo;
    data.customerFirstName = item.customerFirstName;
    data.invoiceDate = item.invoiceDate;
    data.effectiveDate = item.effectiveDate;
    data.price = item.price;
    

    if (item.policyStatusId == PolicyStatus.Active) {
      data.policyStatus = "Active";
    } else if (item.policyStatusId == PolicyStatus.Cancel) {
      data.policyStatus = "Cancel";
    } else if (item.policyStatusId == PolicyStatus.Modified) {
      data.policyStatus = "Modified";
    } else if (item.policyStatusId == PolicyStatus.CancelationApprovalPending) {
      data.policyStatus = "Cancelation Approval Pending";
    } else if (
      item.policyStatusId == PolicyStatus.ModificationApprovalPending
    ) {
      data.policyStatus = "Modification Approval Pending";
    }
    data.sellingAdvisor = item.soldBy;
    this.exportdata.push(data);
  }

  exporttoexcel(){
    this.pageNumber = -1;
    this.getExportData();
  }

  bindexcel(){
    /* json data is passed over here */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportdata);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    // ws.A1.v = "Region";
    // ws.B1.v = "State";
    // ws.C1.v = "Dealer Code";
    // ws.D1.v = "Dealer Name";
    // ws.E1.v = "Current Wallet Balance";
    // ws.F1.v = "Last Wallet Recharge";
    XLSX.utils.book_append_sheet(wb, ws, "Policy_Sale_Report");
    /* save to file */
    this.fileName =
      "PolicySaleReport_" +
      new Date(Date.now()).toLocaleString().split(",")[0] +
      ".xlsx";
    XLSX.writeFile(wb, this.fileName);
  }
}
