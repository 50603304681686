import { Injectable, Inject } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { UserType } from "../userType.model";
import { AppConfig } from "src/config/app.config";
import { RequestIntegrity } from "../request-integrety";

@Injectable()
export class AuthService {
  isAuthorized = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    public jwtHelper: JwtHelperService,
    private appConfig: AppConfig,
    public requestIntegrity: RequestIntegrity
  ) {}

  public isAuthenticated(): boolean {
    // const token = this.requestIntegrity.decryptUsingAES256(
    //   localStorage.getItem("API_ACCESS_TOKEN")
    // );
    const token = localStorage.getItem("API_ACCESS_TOKEN");
    // Check whether the token is expired and return
    // true or false
    if (token == null) return false;
    return !this.jwtHelper.isTokenExpired(token);
  }

  userPermissions() {
    return this.http
      .get(this.appConfig.baseUrl + "/api/v1/UserRights/userType")
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  export(url: string): Observable<any> {
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          hostname: window.location.host,
        }),
        responseType: "blob",
      })
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getList(url: string): Observable<any> {
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          hostname: window.location.host,
        }),
        observe: "response",
      })
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  get(url: string): Observable<any> {
    return this.http.get(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  put(url: string, data: any): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.put(url, body).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  delete(url: string): Observable<any> {
    return this.http.delete(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  post(url: string, data: any): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.post(url, body).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  PostList(url: string, data: any): Observable<any> {
    const body = JSON.stringify(data);
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          hostname: window.location.host,
        }),
        observe: "response",
      })
      .pipe(
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  private getHeaders() {
    let headers = new HttpHeaders();
    headers = headers
      .set("Content-Type", "application/json")
      .set("hostname", window.location.host);
    return headers;
  }

  login(response: any) {
    //Decrypt Auth Response
    // let decryptedToken = this.requestIntegrity.decryptUsingAES256(
    //   response.user
    // );
    //Decode Jwt Claims
    let jwt = this.jwtHelper.decodeToken(response.user);

    localStorage.setItem("API_ACCESS_TOKEN", response.user);
    localStorage.setItem("USER_NAME", jwt.firstName);
    localStorage.setItem("USER_EMAIL", jwt.emailId);
    localStorage.setItem("userRole", btoa(UserType[jwt.userBaseTypeId]));
    localStorage.setItem("USER_BASE_TYPE_ID", btoa(jwt.userBaseTypeId));
    localStorage.setItem("ISFIRSTLOGIN", jwt.firstLogin);
    localStorage.setItem("IDENTIFIER", jwt.identifier);
    this.router.navigate(["dashboard"]);
  }

  renewToken(response: any) {
    localStorage.setItem("API_ACCESS_TOKEN", response.user);
  }

  resetcredentials() {
    localStorage.removeItem("userRole");
    localStorage.removeItem("API_ACCESS_TOKEN");
    localStorage.removeItem("ISFIRSTLOGIN");
    localStorage.removeItem("USER_NAME");
    localStorage.removeItem("USER_EMAIL");
    localStorage.removeItem("USER_BASE_TYPE_ID");
    localStorage.removeItem("IDENTIFIER");
  }

  Logout(identifier: string): any {
    this.post(
      this.appConfig.baseUrl + "/api/v1/Auth/logout",
      identifier
    ).subscribe(
      (response) => {
        if (response.success) {
          this.router.navigate([""]);
          return true;
        } else {
          return false;
        }
      },
      (error) => {
        console.error(error);
        return false;
      }
    );
  }

  refreshToken(): Observable<any> {
    return this.get(this.appConfig.baseUrl + `/api/v1/Security/RefreshToken`);
  }

  resetAntiforgeryToken(): Observable<any> {
    return this.get(
      this.appConfig.baseUrl + `/api/v1/Security/ResetAntiforgeryToken`
    );
  }

  canActivate(path: string): Observable<boolean> {
    return this.get(
      this.appConfig.baseUrl + `/api/v1/RouteConfigs/GetRouteDetails`
    ).pipe(
      map((result: string[]) => {
        if (result) {
          var route: string[] = this.setRouteData(result);
          if (route.includes(path)) return true;
          this.router.navigate([""]);
          return false;
        }
      }),
      catchError((error) => {
        this.router.navigate([""]);
        return of(false);
      })
    );
  }

  setRouteData(routeData: any) {
    var configuredRoute = [];
    routeData.forEach((item) => {
      configuredRoute.push(item["route"].substring(1));
    });

    return configuredRoute;
  }

  getApiAccessToken(): Observable<any> {
    const body = new HttpParams()
      .set("grant_type", "client_credentials")
      .set("scope", "api1")
      .set("client_id", "client")
      .set("client_secret", "511536EF-F270-4058-80CA-1C89C192F69A");

    return this.http.post(
      this.appConfig.authUrl + "/connect/token",
      body.toString(),
      {
        headers: new HttpHeaders().set(
          "Content-Type",
          "application/x-www-form-urlencoded"
        ),
      }
    );
  }
}
