import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { NxMessageToastService } from '@allianz/ngx-ndbx/message';
import { throwError } from 'rxjs';
import { SortEvent, SortDirection } from '@allianz/ngx-ndbx/table';
import { MESSAGE_CONSTS } from '../ValidationMessages';

@Component({
  selector: 'app-modelmanagement',
  templateUrl: './modelmanagement.component.html',
  styleUrls: ['./modelmanagement.component.css']
})
export class ModelmanagementComponent implements OnInit {
  _apiUrl: string;
  clientId: number;
  modelList: any;
  veichleModalOpen: boolean = false;
  modelForm: FormGroup;
  EDIT_FLAG: boolean = false;
  confirmModalOpen: boolean = false;
  isSuccess: boolean = false;
  modelData: any = {};
  modelId: number = 0;
  errorClosed = true;
  errorMessage: string;
  Messages: any;

  toggleModal() {
    this.EDIT_FLAG = false;
    this.useFormBuilder();
    this.isSuccess = false;
    this.veichleModalOpen = !this.veichleModalOpen;
  }
   /** sorting starts here */
sortTable(sort: SortEvent) {//Sorts the table data by a certain category.
  this.modelList = this.modelList.sort((a, b) => {
    if (sort.active in a && sort.active in b) {
      return this.compare(a[sort.active], b[sort.active], sort.direction);
    }
    return 0;
  });
}

compare(
  a: number | string | Date,
  b: number | string | Date,
  direction: SortDirection) {

  return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
}
/** sorting ends here */

/**Pagination Start */
count: number;
pageNumber: number = 1;
pageSize: number = 10;
searchTerm: any = "";
prevPage() {
  this.pageNumber--;
  this.getModels();
}

nextPage() {
  this.pageNumber++;
  this.getModels();
}

goToPage(n: number) {
  this.pageNumber = n;
    this.getModels();
}
/**Pagination end */

  constructor(private authService: AuthService, private appConfig : AppConfig, private messageToastService: NxMessageToastService) {

    this._apiUrl = appConfig.baseUrl;
    this.useFormBuilder();
  }

  ngOnInit() {

    this.getModels();

  }
  useFormBuilder() {
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.modelForm = new FormBuilder().group({
      modelId: 0,
      modelMake: ['', Validators.required],
      modelName: new FormControl('', [Validators.required,Validators.pattern(MESSAGE_CONSTS.ALLOWED_SPECIALCHAR_REGEX)]),
      modelWarrenty: [null, Validators.required],
      createdBy: localStorage.getItem('USER_NAME'),
      createdDate: new Date(),
      updatedBy: localStorage.getItem('USER_NAME'),
      updatedDate: new Date(),
      isActive: 1,
      clientId: this.clientId
    });

  }
  get f() {
    return this.modelForm.controls;
}
  //get all models
  getModels() {

    this.authService.getList(this._apiUrl + "/api/v1/Models" + "?pageNumber=" + this.pageNumber + "&pageSize=" + this.pageSize + "&searchText=" + this.searchTerm).subscribe(response => {
      if (response) {
        this.modelList = response.body;
        var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
        this.count = pagingProp.TotalCount;
      }
    }, (error) => {
      console.error(error);
    });
  }
  //add model
  onSave() {
    if (this.modelForm.valid) {
      this.authService.post(this._apiUrl + '/api/v1/Models', this.modelForm.value).subscribe(response => {
        if (response) {
          this.getModels();
          this.isSuccess = true;
        }
      }, (error) => {
        this.handleError(error);
      });
    }
  }
  //update model
  onUpdate() {
    this.authService.put(this._apiUrl + '/api/v1/Models/' + this.modelId, this.modelForm.value).subscribe(response => {
      this.getModels();
      this.isSuccess = true;
    }, (error) => {
      this.handleError(error);
    });
  }
  //get data for editing the model
  editModel(id: number) {
    this.modelId = id;
    this.authService.get(this._apiUrl + '/api/v1/Models/' + this.modelId).subscribe(response => {
      if (response) {
        this.modelForm.patchValue({
          modelId: response.modelId,
          modelMake: response.modelMake,
          modelName: response.modelName,
          modelWarrenty: response.modelWarrenty,
          createdBy: response.createdBy,
          createdDate: response.createdDate,
          updatedBy: response.updatedBy,
          updatedDate: response.updatedDate,
          isActive: response.isActive,
          clientId: this.clientId
        });
        this.veichleModalOpen = true;
        this.EDIT_FLAG = true;
      }
    }, (error) => {
      console.error(error);
    });
  }
  //activate deactivate models
  updateStatus() {
    this.confirmModalOpen = false;
    this.authService.put
      (this._apiUrl + '/api/v1/Models/' + this.modelData.modelId, this.modelData).subscribe(response => {
        if (response) {
          this.getModels();
        }
      }, (error) => {
        console.error(error);
      });

  }
  confirmModal(data: any, isChecked: boolean) { //modal yes button click
    this.confirmModalOpen = true;
    data.isActive = isChecked ? 1 : 0;
    this.modelData = data;

  }
  onNoClick() { //modal no button click
    this.confirmModalOpen = false;
    this.modelData = {};
    this.getModels();
  }
  handleError(error) {
    if (error.error instanceof ErrorEvent) {
      // client-side error
      this.errorMessage = `Error : ${error.error.message}`;

    } else {
      // server-side error
      this.errorMessage = `Error : ${error.error}`;

    }
    this.errorClosed = false;
    this.messageToastService.open(this.errorMessage, { context: 'info', duration: 5000 });
    return throwError(this.errorMessage);
  }


}
