export const environment = {
  production: false,
  apiUrl: "https://b2b2c-api-uat.awpassistance.in",
  payuUrl: "https://sboxcheckout-static.citruspay.com/bolt/run/bolt.min.js",
  nsmCount: 1,
  rsmCount: 5,
  asmCount: 25,
  financeCount: 50,
  gCaptchaKey: "6LfVOqsbAAAAAD9vN3yvglsuceceosJ646UoG1Ml",
  gCaptchaSecret: "6LfVOqsbAAAAAHPHDHAgZbMK-jON20WYDv5Evd4E",
  maxvehicleAge: 10,
  onePayuUrl: "https://test.payu.in/_payment",
  onePayuKey: "oZ7oo9",
  onePayuSalt: "UkojH5TS",
};