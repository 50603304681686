import { NxDialogService, NxModalRef } from "@allianz/ngx-ndbx/modal";
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { AppConfig } from "src/config/app.config";
import { AuthService } from "../core/auth/auth.service";
import { CustomErrorHandler } from "../core/custom-error-handler";
import { MESSAGE_CONSTS } from "../ValidationMessages";
import { WalletRefreshService } from "../walletbalance/walletbalance-service";
import { PayUInput } from "../core/online-transaction.model";
import { RequestIntegrity } from "../core/request-integrety";

@Component({
  selector: "app-online-payment",
  templateUrl: "./online-payment.component.html",
  styleUrls: ["./online-payment.component.css"],
})
export class OnlinePaymentComponent implements OnInit {
  payuUrl: string;

  rechargeAmount: number = null;
  mobileNo: string = null;

  errorMessage: string;
  viewPaymentModal: boolean;
  @ViewChild('onePayuForm') onePayuFormElement;
  @ViewChild("template") templateRef: TemplateRef<any>;
  templateDialogRef: NxModalRef<any>;

  phoneFormControl = new FormControl("", [
    Validators.required,
    Validators.pattern(MESSAGE_CONSTS.MOBILE_REGEX),
  ]);
  private _apiUrl: string;
  payuinput: PayUInput;
  @Output() closeModal = new EventEmitter<boolean>();

  constructor(
    private walletRefreshService: WalletRefreshService,
    private authService: AuthService,
    private appConfig: AppConfig,
    private errorHandler: CustomErrorHandler,
    public dialogService: NxDialogService,
    public requestIntegrity: RequestIntegrity
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.payuUrl = appConfig.onePayuUrl;
    this.payuinput = {
      amount: null,
      phone: null,
    };
    this.viewPaymentModal = true;
  }

  ngOnInit(): void {}

  onModalClose(agreed: boolean) {
    this.closeModal.emit(agreed);
  }

  bindPaymentInfo() {
    this.payuinput = {
      key: this.appConfig.onePayuKey,
      txnid: this.requestIntegrity.uuidv4(),
      productinfo: "Online wallet recharge",
      amount: this.rechargeAmount,
      email: localStorage.getItem("USER_EMAIL"),
      firstname: localStorage.getItem("USER_NAME"),
      surl: `${this._apiUrl}/api/v2/PaymentGateway/payu/wallet/success?host=${window.location.host}`,
      furl: `${this._apiUrl}/api/v2/PaymentGateway/payu/wallet/fail?host=${window.location.host}`,
      phone: this.mobileNo,
      hash: null,
    };

    this.payuinput.hash = this.generateHash(this.payuinput);

    this.addPaymentLog();
  }

  addPaymentLog() {
    // To prevent sensitive data tranfer
    var payuLog: PayUInput = {};
    payuLog.txnid = this.payuinput.txnid;
    payuLog.amount = this.payuinput.amount;

    this.authService
      .post(
        this.appConfig.baseUrl + "/api/v2/PaymentGateway/PayuTransactionLogs",
        payuLog
      )
      .subscribe(
        (response) => {
          this.swithview();
          this.onePayuFormSubmit();
        },
        (error) => {
          //this.errorHandler.handleError(error);
          this.errorHandler.handleCustomError("Error occured while updating PayU transaction initiated details.");
        }
      );
  }
  
   //Redirect to One PayU Page after Transaction Initiated in Transaction Wallet Details
   public onePayuFormSubmit(): void { 
    this.onePayuFormElement.nativeElement.submit();
  }

  generateHash(payuinput: PayUInput) {
    return this.requestIntegrity.HashInput(
      `${payuinput.key}|${payuinput.txnid}|${payuinput.amount}|${payuinput.productinfo}|${payuinput.firstname}|${payuinput.email}|||||||||||${this.appConfig.onePayuSalt}`
    );
  }

  onPaymentConfirm() {
    this.walletRefreshService.walletRefresh(true);
    this.onModalClose(false);
  }
  //Workaround as without delay the redirection not works
  async swithview() {
    await delay(300);
    this.viewPaymentModal = false;
  }
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
