<app-header></app-header>
<app-menu></app-menu>

<div nxLayout="grid" class="nx-margin-top-2m">
    <div nxRow class="nx-margin-left-4m nx-margin-right-4m">
        <div nxCol="12,12,6,4" >
            <nx-formfield nxLabel="Select Request Type">
                <nx-dropdown [(ngModel)]="requestType" (selectionChange)="changeRequestType(requestType);">
                    <nx-dropdown-item nxValue={{policyStatus.CancelationApprovalPending}}>Cancellation Request </nx-dropdown-item>
                    <nx-dropdown-item nxValue={{policyStatus.ModificationApprovalPending}}>Modification Request</nx-dropdown-item>
                </nx-dropdown>
                <nx-error nxFormfieldError>
                    Please select an option.
                </nx-error>
            </nx-formfield>
        </div>
    </div>
    <div nxRow class="nx-margin-left-4m nx-margin-right-4m">
        <div nxCol="12,12,3,2" nxAlignSelf="center" >
            <nx-formfield nxLabel="Start date">
                <input nxDatefield nxInput [nxDatepicker]="startDatepicker" [(ngModel)]="policyStartDate" />
                <!-- <span nxFormfieldHint>MM/DD/YYYY</span> -->
                <nx-datepicker-toggle [for]="startDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                <nx-datepicker #startDatepicker></nx-datepicker>
                <nx-error nxFormfieldError>
                    <strong>Please note:</strong> this field is required!
                </nx-error>
            </nx-formfield>
        </div>
        <div nxCol="12,12,3,2" nxAlignSelf="center">
            <nx-formfield nxLabel="End date">
                <input nxDatefield nxInput [nxDatepicker]="endDatepicker" [(ngModel)]="policyEndDate" />
                <!-- <span nxFormfieldHint>MM/DD/YYYY</span> -->
                <nx-datepicker-toggle [for]="endDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                <nx-datepicker #endDatepicker></nx-datepicker>
                <nx-error nxFormfieldError>
                    <strong>Please note:</strong> this field is required!
                </nx-error>
            </nx-formfield>
        </div>
        <div nxCol="12,12,6,4">
            <nx-formfield>
                <input nxInput placeholder="Enter Keyword" [(ngModel)]="searchTerm" type="search">
                <span nxFormfieldPrefix>
                    <nx-icon name="search" size="s"></nx-icon>
                </span>
            </nx-formfield>
        </div>

        <div nxCol="12,12,3,2" nxAlignSelf="center">
            <button nxButton="primary small-medium" type="button" (click)="changeRequestType(requestType);">Search</button>
        </div>
    </div>

    <div nxRow nxRowJustify="start,start,around" nxRowAlignContent="end,around,stretch" class="nx-margin-left-4m nx-margin-right-4m" *ngIf="count!=0">
        <div nxCol="12" >
            <table nxTable condensed>
                <thead>
                    <tr nxTableRow>
                        <th nxHeaderCell>
                            Policy Number
                        </th>
                        <th nxHeaderCell>
                            Vin Number
                        </th>
                        <th nxHeaderCell>
                            Customer Name
                        </th>

                        <th nxHeaderCell>
                            Policy Sale Date
                        </th>
                        <th nxHeaderCell>
                            Policy Start Date
                        </th>
                        <th nxHeaderCell>
                            Status
                        </th>
                        <th nxHeaderCell>
                            Total Amount
                        </th>
                        <th nxHeaderCell>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr nxTableRow *ngFor="let item of policyRequestList">
                        <td nxTableCell>{{item.policyNo}}</td>
                        <td nxTableCell>{{item.vinChassisNo}}</td>
                        <td nxTableCell>{{item.customerFirstName}} {{item.customerLastName}}</td>
                        <td nxTableCell>{{item.invoiceDate | date:'MM/dd/yyyy'}}</td>
                        <td nxTableCell>{{item.effectiveDate | date:'MM/dd/yyyy'}}</td>
                        <td nxTableCell *ngIf="item.policyStatusId == policyStatus.CancelationApprovalPending">
                            <nx-badge type="critical" [nxTooltipPosition]="'top'"
                                [nxTooltip]="'Cancelation Approval Pending'">Pending</nx-badge>
                        </td>
                        <td nxTableCell *ngIf="item.policyStatusId == policyStatus.ModificationApprovalPending">
                            <nx-badge vibrant type="negative" [nxTooltipPosition]="'top'"
                                [nxTooltip]="'Modification Approval Pending'">Pending</nx-badge>
                        </td>
                        <td nxTableCell>{{item.price}}</td>
                        <td nxTableCell>
                            <button nxIconButton="tertiary  small" type="button" [nxTooltipPosition]="'top'"
                                [nxTooltip]="'Edit'" (click)="toggleModal(item.id,item.policyNo,item.transactionId)">
                                <nx-icon name="edit" size="s">
                                </nx-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right;">
        <div nxCol="6">
            <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced" (nxGoPrev)="prevPage()"
              (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
            </nx-pagination>
          </div>
    </div>
    <div nxRow>
        <div nxCol="12,10" class="nx-margin-top-2xl" *ngIf="count==0">
            <ng-container>
            <div nxRow nxRowJustify="center">
                <div nxCol="12" class="nosearchresult"></div>
                <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">No Records found !!!</h3>
            </div>
            </ng-container>
        </div>
    </div>
</div>


<app-footer></app-footer>



<nx-modal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="policyView" [nxShowCloseIcon]="false">
    <div nxLayout='grid'>
        <div *ngIf="!isSuccess">
        <div nxRow nxRowJustify="start,around">
            <h1 nxHeadline="page">Policy Details</h1>
        </div>
        <!-- vehicle details -->
        <div nxRow>
             <nx-card>
                    <h2 class="nx-margin-bottom-0" nxHeadline="subsection-small">Vehicle Details(Existing)</h2>
                    <hr>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="VIN(Chasis Number)">
                                <input nxInput value={{policyDetails.vinChassisNo}} readonly />
                            </nx-formfield>
                        </div>
                        <div nxCol='6'>
                            <nx-formfield nxLabel="Licence Plate NO:">
                                <input nxInput value={{policyDetails.licensePlateNo}} readonly />
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol='6'>
                            <nx-formfield nxLabel="Model">
                                <input nxInput value={{policyDetails.model}} readonly />
                            </nx-formfield>
                        </div>
                        <div nxCol='6'>
                            <nx-formfield nxLabel="Color">
                                <input nxInput value={{policyDetails.colorName}} readonly />
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol='6'>
                            <nx-formfield nxLabel="Vehicle Sale date">
                                <input nxInput value="{{policyDetails.vehicleSaleDate | date:'MM/dd/yyyy'}}" readonly />
                            </nx-formfield>
                        </div>
                        <div nxCol='6'>
                            <nx-formfield nxLabel="Variant">
                                <input nxInput value={{policyDetails.variant}} readonly />
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol='6'>
                            <nx-formfield nxLabel="Invoice Date">
                                <input nxInput value="{{policyDetails.invoiceDate | date:'MM/dd/yyyy'}}" readonly />
                            </nx-formfield>
                        </div>
                        <div nxCol='6'>
                            <nx-formfield nxLabel="Other Details">
                                <textarea nxInput value={{policyDetails.otherDetails}} readonly></textarea>
                            </nx-formfield>
                        </div>
                    </div>
            </nx-card> 
        </div>
        <div nxRow *ngIf="requestType == policyStatus.ModificationApprovalPending">
            <nx-card>
                   <h2 class="nx-margin-bottom-0" nxHeadline="subsection-small">Vehicle Details(Modified)</h2>
                   <hr>
                   <div nxRow>
                       <div nxCol="6">
                           <nx-formfield nxLabel="VIN(Chasis Number)">
                               <input nxInput value={{policyModifiedDetails.vinChassisNo}} readonly />
                           </nx-formfield>
                       </div>
                       <div nxCol='6'>
                           <nx-formfield nxLabel="Licence Plate NO:">
                               <input nxInput value={{policyModifiedDetails.licensePlateNo}} readonly />
                           </nx-formfield>
                       </div>
                   </div>
                   <div nxRow>
                       <div nxCol='6'>
                           <nx-formfield nxLabel="Model">
                               <input nxInput value={{policyModifiedDetails.model}} readonly />
                           </nx-formfield>
                       </div>
                       <div nxCol='6'>
                           <nx-formfield nxLabel="Color">
                               <input nxInput value={{policyModifiedDetails.colorName}} readonly />
                           </nx-formfield>
                       </div>

                   </div>
                   <div nxRow>
                       <div nxCol='6'>
                           <nx-formfield nxLabel="Vehicle Sale date">
                               <input nxInput value="{{policyModifiedDetails.vehicleSaleDate | date:'MM/dd/yyyy'}}" readonly />
                           </nx-formfield>
                       </div>
                       <div nxCol='6'>
                           <nx-formfield nxLabel="Variant">
                               <input nxInput value={{policyDetails.variant}} readonly />
                           </nx-formfield>
                       </div>
                   </div>
                   <div nxRow>
                       <div nxCol='6'>
                           <nx-formfield nxLabel="Invoice Date">
                               <input nxInput value="{{policyDetails.invoiceDate | date:'MM/dd/yyyy'}}" readonly />
                           </nx-formfield>
                       </div>
                       <div nxCol='6'>
                           <nx-formfield nxLabel="Other Details">
                               <textarea nxInput value={{policyDetails.otherDetails}} readonly></textarea>
                           </nx-formfield>
                       </div>
                   </div>
           </nx-card> 
       </div>
        <!-- PLan details -->
        <div nxRow>
            <nx-card>
                    <h2 class="nx-margin-bottom-0" nxHeadline="subsection-small">Plan Details(Existing)</h2>
                    <hr>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Plan Type">
                                <input nxInput value={{policyDetails.policyName}} readonly />
                            </nx-formfield>
                        </div>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Effective Date">
                                <input nxInput value="{{policyDetails.effectiveDate |  date:'MM/dd/yyyy'}}" readonly />
                            </nx-formfield>
                        </div>

                    </div>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Expiry Date">
                                <input nxInput value="{{policyDetails.expiryDate |  date:'MM/dd/yyyy'}}" readonly />
                            </nx-formfield>
                        </div>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Price">
                                <input nxInput value={{policyDetails.price}} readonly />
                            </nx-formfield>
                        </div>

                    </div>
                    <div nxRow>
                        <div nxCol="6" class="nx-margin-top-2xs">
                            <nx-formfield nxLabel="Gst Price">
                                <input nxInput value={{policyDetails.gstPrice}} readonly />
                            </nx-formfield>

                        </div>
                    </div>
            </nx-card>
        </div>
        <div nxRow *ngIf="requestType == policyStatus.ModificationApprovalPending">
            <nx-card >
                <h2 class="nx-margin-bottom-0" nxHeadline="subsection-small">Plan Details(Modified)</h2>
                <hr>
                <div nxRow>
                    <div nxCol="6">
                        <nx-formfield nxLabel="Plan Type">
                            <input nxInput value={{policyDetails.policyName}} readonly />
                        </nx-formfield>
                    </div>
                    <div nxCol="6">
                        <nx-formfield nxLabel="Effective Date">
                            <input nxInput value="{{policyModifiedDetails.effectiveDate |  date:'MM/dd/yyyy'}}" readonly />
                        </nx-formfield>
                    </div>

                </div>
                <div nxRow>
                    <div nxCol="6">
                        <nx-formfield nxLabel="Expiry Date">
                            <input nxInput value="{{policyModifiedDetails.expiryDate |  date:'MM/dd/yyyy'}}" readonly />
                        </nx-formfield>
                    </div>
                    <div nxCol="6">
                        <nx-formfield nxLabel="Price">
                            <input nxInput value={{policyDetails.price}} readonly />
                        </nx-formfield>
                    </div>

                </div>
                <div nxRow>
                    <div nxCol="6" class="nx-margin-top-2xs">
                        <nx-formfield nxLabel="Gst Price">
                            <input nxInput value={{policyDetails.gstPrice}} readonly />
                        </nx-formfield>

                    </div>
                </div>
            </nx-card>
        </div>
        <!-- Payment details -->
        <div nxRow>
            <nx-card>
                <!-- <div nxCol="12"> -->

                    <h2 class="nx-margin-bottom-0" nxHeadline="subsection-small" >Payment Details</h2>
                    <hr>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Sold By">
                                <input nxInput value={{policyDetails.soldBy}} readonly />
                            </nx-formfield>

                        </div>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Payment Date">
                                <input nxInput value="{{policyDetails.paymentDate |  date:'MM/dd/yyyy'}}" readonly />
                            </nx-formfield>

                        </div>

                    </div>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Unit Price">
                                <input nxInput value={{policyDetails.unitPrice}} readonly />
                            </nx-formfield>

                        </div>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Outstanding Balance">
                                <input nxInput value={{policyDetails.outstandingBal}} readonly />
                            </nx-formfield>

                        </div>

                    </div>

                <!-- </div> -->
            </nx-card>
        </div>
        <!-- Customer Details -->
        <div nxRow>
            <nx-card>
                    <h2 class="nx-margin-bottom-0" nxHeadline="subsection-small">Customer Details(Existing)</h2>
                    <hr>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="First Name">
                                <input nxInput value={{policyDetails.customerFirstName}} readonly />
                            </nx-formfield>

                        </div>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Middle Name">
                                <input nxInput value={{policyDetails.customerMiddleName}} readonly />
                            </nx-formfield>
                        </div>

                    </div>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Last Name">
                                <input nxInput value={{policyDetails.customerLastName}} readonly />
                            </nx-formfield>

                        </div>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Home Phone Number">
                                <input nxInput value={{policyDetails.customerPhoneNo}} readonly />
                            </nx-formfield>

                        </div>

                    </div>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Gst Number">
                                <input nxInput value={{policyDetails.custGstNumber}} readonly />
                            </nx-formfield>

                        </div>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Mobile Number">
                                <input nxInput value={{policyDetails.customerMobileNo}} readonly />
                            </nx-formfield>

                        </div>

                    </div>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Email">
                                <input nxInput value={{policyDetails.customerEmail}} readonly />
                            </nx-formfield>

                        </div>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Company Name">
                                <input nxInput value={{policyDetails.customerName}} readonly />
                            </nx-formfield>

                        </div>

                    </div>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="State">
                                <input nxInput value={{stateName}} readonly />
                            </nx-formfield>

                        </div>
                        <div nxCol="6">
                            <nx-formfield nxLabel="City">
                                <input nxInput value={{cityName}} readonly />
                            </nx-formfield>

                        </div>

                    </div>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="PIN Code">
                                <input nxInput value={{policyDetails.pincode}} readonly />
                            </nx-formfield>
                        </div>
                    </div>
            </nx-card>
        </div>
        <div nxRow *ngIf="requestType == policyStatus.ModificationApprovalPending">
            <nx-card >
                    <h2 class="nx-margin-bottom-0" nxHeadline="subsection-small">Customer Details(Modified)</h2>
                    <hr>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="First Name">
                                <input nxInput value={{policyModifiedDetails.customerFirstName}} readonly />
                            </nx-formfield>
                        </div>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Middle Name">
                                <input nxInput value={{policyModifiedDetails.customerFirstName}} readonly />
                            </nx-formfield>
                        </div>

                    </div>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Last Name">
                                <input nxInput value={{policyModifiedDetails.customerLastName}} readonly />
                            </nx-formfield>
                        </div>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Home Phone Number">
                                <input nxInput value={{policyModifiedDetails.customerPhoneNo}} readonly />
                            </nx-formfield>
                        </div>

                    </div>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Gst Number">
                                <input nxInput value={{policyModifiedDetails.custGstNumber}} readonly />
                            </nx-formfield>
                        </div>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Mobile Number">
                                <input nxInput value={{policyModifiedDetails.customerMobileNo}} readonly />
                            </nx-formfield>
                        </div>

                    </div>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Email">
                                <input nxInput value={{policyModifiedDetails.customerEmail}} readonly />
                            </nx-formfield>
                        </div>
                        <div nxCol="6">
                            <nx-formfield nxLabel="Company Name">
                                <input nxInput value={{policyModifiedDetails.customerCompany}} readonly />
                            </nx-formfield>
                        </div>

                    </div>
                    <div nxRow>

                        <div nxCol="6">
                            <nx-formfield nxLabel="State">
                                <input nxInput value={{stateM_Name}} readonly />
                            </nx-formfield>
                        </div>
                        <div nxCol="6">
                            <nx-formfield nxLabel="City">
                                <input nxInput value={{cityM_Name}} readonly />
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield nxLabel="PIN Code">
                                <input nxInput value={{policyModifiedDetails.pincode}} readonly />
                            </nx-formfield>

                        </div>
                    </div>

                <!-- </div> -->
            </nx-card>
        </div>
        <div nxRow class="nx-margin-top-2xs">
            <div nxCol="12" style="text-align: center;">
                <button nxButton="cta primary" type="button" (click)="approveRequest()">Approve</button>
                <button nxButton="emphasis primary" type="button" (click)="rejectRequest()">Reject</button>
                <button nxButton="secondary" type="button" (click)="closeModal()">Close</button>
            </div>
        </div>
        </div>

        <div *ngIf="isSuccess">

            <div nxRow nxRowJustify='start,around'>
                <div nxCol="12" style="text-align: -webkit-center;">
                    <nx-spinner *ngIf="!enableClose" nxSize="large"></nx-spinner>
                    <nx-icon *ngIf="enableClose" name="check-circle" size="m" style="color: green;"></nx-icon>
                </div>
            </div>
            <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
              <div nxCol="12">
                
                <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" >{{successMsg}}</h3>
              </div>
            </div>
            <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
              <div nxCol="12">
                <button *ngIf="enableClose" (click)="closeModal()" nxButton="block primary">
                  close
                </button>
              </div>
            </div>
        </div>

        
    </div>
</nx-modal>


   