<header nx-header>
  <nx-header-row>
    <nx-header-brand>
      <nx-link>
        <a href="#">
          <figure nxFigure>
            <img
              src="assets/APlogo.png"
              alt="Allianz Global Brand Logo"
              role="none"
              style="width: 175px"
            />
          </figure>

          &nbsp; &nbsp;
          <div class="headerDivider"></div>
          &nbsp; &nbsp;

          <figure nxFigure>
            <img
              src="assets/hyundai-logo.png"
              alt="Allianz Global Brand Logo"
              role="none"
              style="width: 150px; height: 20px"
            />
          </figure>
        </a>
      </nx-link>
    </nx-header-brand>
  </nx-header-row>
</header>

<div *ngIf="isPageValid; else expired_page">
  <nx-small-stage style="background-color: #cfe9ee;">
    <h1 nxHeadline="subsection-small" class="nx-font-weight-bold">
      Hello {{ this.payuinput.firstname }} ,
    </h1>
    <h3 nxHeadline="subsection-xsmall">
      Payment for {{ this.transactionDetails?.productInfo }} policy.
    </h3>
    <nx-small-stage-image
      nxSmallStageImageStart
      src="assets/illustrations/small-stage-image-start.svg"
    ></nx-small-stage-image>
    <nx-small-stage-image
      nxSmallStageImageEnd
      src="assets/illustrations/small-stage-image-end.svg"
    ></nx-small-stage-image>
    <nx-small-stage-image
      nxSmallStageImageBottom
      class="nx-hidden-s nx-hidden-xs"
      src="assets/illustrations/small-stage-image-narrow-screen.svg"
    ></nx-small-stage-image>
  </nx-small-stage>

  <div nxLayout="grid">
    <div nxRow>
      <div class="nx-margin-top-s" nxCol="12,12,10,6" nxColOffset="0,0,1,3">
        <nx-formfield nxLabel="Name">
          <input nxInput [(ngModel)]="this.payuinput.firstname" readonly />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="6" nxCol="12,12,10,6" nxColOffset="0,0,1,3">
        <nx-formfield nxLabel="E-Mail">
          <input nxInput [(ngModel)]="this.payuinput.email" readonly />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="6" nxCol="12,12,10,6" nxColOffset="0,0,1,3">
        <nx-formfield nxLabel="Phone number">
          <input nxInput [(ngModel)]="this.payuinput.phone" readonly />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="6" nxCol="12,12,10,6" nxColOffset="0,0,1,3">
        <nx-formfield nxLabel="Product">
          <input
            nxInput
            [(ngModel)]="this.transactionDetails.productInfo"
            readonly
          />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,10,6" nxColOffset="0,0,1,3">
        <nx-formfield nxLabel="Start Date">
          <input
            nxInput
            [(ngModel)]="this.transactionDetails.startDate"
            readonly
          />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="6" nxCol="12,12,10,6" nxColOffset="0,0,1,3">
        <nx-formfield nxLabel="End Date">
          <input
            nxInput
            [(ngModel)]="this.transactionDetails.endDate"
            readonly
          />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="6" nxCol="12,12,10,6" nxColOffset="0,0,1,3">
        <nx-formfield nxLabel="Amount Payable">
          <input nxInput [(ngModel)]="this.payuinput.amount" readonly />
        </nx-formfield>
      </div>
    </div>

    <form ngNoForm [action]="payuUrl" method="POST" target="_blank">
      <div>
        <input type="hidden" name="key" [(ngModel)]="this.payuinput.key" />
        <input type="hidden" name="txnid" [(ngModel)]="this.payuinput.txnid" />
        <input
          type="hidden"
          name="productinfo"
          [(ngModel)]="this.payuinput.productinfo"
        />
        <input
          type="hidden"
          name="amount"
          [(ngModel)]="this.payuinput.amount"
        />
        <input type="hidden" name="email" [(ngModel)]="this.payuinput.email" />
        <input
          type="hidden"
          name="firstname"
          [(ngModel)]="this.payuinput.firstname"
        />
        <!-- <input type="hidden" name="lastname" [(ngModel)]="this.payuinput.key" /> -->
        <input type="hidden" name="surl" [(ngModel)]="this.payuinput.surl" />
        <input type="hidden" name="furl" [(ngModel)]="this.payuinput.furl" />
        <input type="hidden" name="phone" [(ngModel)]="this.payuinput.phone" />
        <input type="hidden" name="hash" [(ngModel)]="this.payuinput.hash" />
      </div>
      <div nxRow class="nx-margin-top-m" nxRowJustify="center">
        <div nxCol="6" nxCol="12,12,10,6" nxColOffset="0,0,1,4">
          <button nxButton type="submit">Proceed To Pay</button>
        </div>
      </div>
    </form>
  </div>
  <app-footer></app-footer>
</div>

<!-- Page Expired -->
<ng-template #expired_page>
  <div nxLayout="grid">
    <div nxRow class="nx-margin-top-m">
      <div nxCol="12,12,10,6" nxColOffset="0,0,1,3">
        <div class="face">
          <div class="band">
            <div class="red"></div>
            <div class="white"></div>
            <div class="blue"></div>
          </div>
          <div class="eyes"></div>
          <div class="dimples"></div>
          <div class="mouth"></div>
        </div>

        <h1>Oops! Something went wrong!</h1>
        <h2>
          The page you are looking for might have been expired or is temporarily
          unavailable.
        </h2>
      </div>
    </div>
  </div>
</ng-template>
