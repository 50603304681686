import { NgModule, Component } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

/**for bd,nsm,rsm */

import { WorkshoplistComponent } from "./workshoplist/workshoplist.component";
import { WorkshopviewComponent } from "./workshopview/workshopview.component";
import { PolicyrequestlistComponent } from "./policyrequestlist/policyrequestlist.component";
import { WalletrequestlistComponent } from "./walletrequestlist/walletrequestlist.component";
import { ReportComponent } from "./report/report.component";
import { AddpolicyComponent } from "./addpolicy/addpolicy.component";
import { PolicylistComponent } from "./policylist/policylist.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { WorkshopregistrationComponent } from "./workshopregistration/workshopregistration.component";
import { ClientmanagementComponent } from "./clientmanagement/clientmanagement.component";
import { PageRoutingConfigComponent } from "./page-routing-config/page-routing-config.component";
import { VehicledetailsComponent } from "./vehicledetails/vehicledetails.component";
import { CustomerdetailsComponent } from "./customerdetails/customerdetails.component";
import { PlandetailsComponent } from "./plandetails/plandetails.component";
import { PaymentdetailsComponent } from "./paymentdetails/paymentdetails.component";
import { DownloadComponent } from "./download/download.component";
import { EditpolicyComponent } from "./editpolicy/editpolicy.component";
import { WorkshopreportComponent } from "./workshopreport/workshopreport.component";
import { UserTypeComponent } from "./user-type/user-type.component";
import { UserPermissionsComponent } from "./user-permissions/user-permissions.component";
import { WalletstatementComponent } from "./walletstatement/walletstatement.component";
import { AuthGuardService as AuthGuard } from "./core/auth/auth-guard.service";
import { RoleGuardService as RoleGuard } from "./core/auth/role-guard.service";
import { ChangepasswordComponent } from "./changepassword/changepassword.component";
import { UserReportsComponent } from "./user-reports/user-reports.component";
import { BulkuploadComponent } from "./bulkupload/bulkupload.component";
import { EInvoiceComponent } from "./e-invoice/e-invoice.component";
import { FinanceDashboardComponent } from "./finance-dashboard/finance-dashboard.component";
import { ConfigEncryptionComponent } from "./config-encryption/config-encryption.component";
import { MaintenancePageComponent } from "./maintenance-page/maintenance-page.component";
import { UserEmailsComponent } from "./user-emails/user-emails.component";
import { PayonlineComponent } from "./payonline/payonline.component";
import { PayOnlineResolve } from "./payonline/payonline.resolve";
import { PayuResponseComponent } from "./payu-response/payu-response.component";
const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "forgotpwd",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "workshoplist",
    component: WorkshoplistComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "workshopedit",
    component: WorkshopviewComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "policyrequestlist",
    component: PolicyrequestlistComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "walletrequestlist",
    component: WalletrequestlistComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "report",
    component: ReportComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "sellpolicy",
    component: AddpolicyComponent,
    children: [
      { path: "", redirectTo: "vehicledetails", pathMatch: "full" },
      {
        path: "vehicledetails",
        component: VehicledetailsComponent,
        data: { animation: "vehicle" },
      },
      {
        path: "customerdetails",
        component: CustomerdetailsComponent,
        data: { animation: "customer" },
      },
      {
        path: "plandetails",
        component: PlandetailsComponent,
        data: { animation: "plan" },
      },
      {
        path: "paymentdetails",
        component: PaymentdetailsComponent,
        data: { animation: "payment" },
      },
      {
        path: "download/:policyNo",
        component: DownloadComponent,
        data: { animation: "download" },
      },
    ],
    canActivate: [RoleGuard],
  },
  {
    path: "policylist",
    component: PolicylistComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "registration",
    component: WorkshopregistrationComponent,
  },
  {
    path: "unauthorized",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "client",
    component: ClientmanagementComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "pageRouteConfig",
    component: PageRoutingConfigComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "userRole",
    component: UserTypeComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "userPermissions",
    component: UserPermissionsComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "workshopreport",
    component: WorkshopreportComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "walletstatement",
    component: WalletstatementComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "changepassword",
    component: ChangepasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "userReports",
    component: UserReportsComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "bulkupload",
    component: BulkuploadComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "einvoice",
    component: EInvoiceComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "financedashboard",
    component: FinanceDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "configencryption",
    component: ConfigEncryptionComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "unavailable",
    component: MaintenancePageComponent,
  },
  {
    path: "useremails",
    component: UserEmailsComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "payonline/:trnId",
    component: PayonlineComponent,
    resolve: {
      trnDetails: PayOnlineResolve,
    },
  },
  {
    path: "payuresponse/:txnstatus/:txnid",
    component: PayuResponseComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "disabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
