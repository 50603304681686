import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NxDialogService } from "@allianz/ngx-ndbx/modal"
import { OnlineTransaction } from "../core/online-transaction.model";
import { AppConfig } from "src/config/app.config";
import { RequestIntegrity } from "../core/request-integrety";

@Component({
  selector: "app-payonline",
  templateUrl: "./payonline.component.html",
  styleUrls: ["./payonline.component.css"],
})
export class PayonlineComponent implements OnInit {
  isPageValid: boolean = true;
  transactionId: string;
  routeResolveData: any;
  transactionDetails: OnlineTransaction;
  private _apiUrl: any;
  payuFormGroup: FormGroup;
  payuinput: {
    key: any;
    txnid: any;
    amount: any;
    firstname: any;
    email: any;
    productinfo: string;
    hash: any;
    surl: any;
    furl: any;
    phone: any;
  };
  payuUrl: string;

  constructor(
    private _activatedroute: ActivatedRoute,
    public dialogService: NxDialogService,
    private appConfig: AppConfig,
    public requestIntegrity: RequestIntegrity
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.payuUrl = appConfig.onePayuUrl;
    this.transactionId = this._activatedroute.snapshot.paramMap.get("trnId");
    //Fetching Trnsaction details from route resolve
    this.routeResolveData = this._activatedroute.snapshot.data;

    this.transactionDetails = this.routeResolveData.trnDetails;
    this.isPageValid = this.transactionDetails.isValidTransaction;
  }

  ngOnInit(): void {
    if (this.transactionDetails.isValidTransaction) {
      this.initPayUForm();
      this.bindPaymentInfo();
    }
  }

  bindPaymentInfo() {
    this.payuinput = {
      key: this.payuFormGroup.controls["key"].value,
      txnid: this.payuFormGroup.controls["txnid"].value,
      productinfo: this.payuFormGroup.controls["productinfo"].value,
      amount: this.payuFormGroup.controls["amount"].value,
      email: this.payuFormGroup.controls["email"].value,
      firstname: this.payuFormGroup.controls["firstname"].value,
      surl: this.payuFormGroup.controls["surl"].value,
      furl: this.payuFormGroup.controls["furl"].value,
      phone: this.payuFormGroup.controls["phone"].value,
      hash: this.payuFormGroup.controls["hash"].value,
    };

    console.log(this.payuinput.phone);
  }

  initPayUForm() {
    this.payuFormGroup = new FormBuilder().group({
      key: this.appConfig.onePayuKey,
      txnid: this.transactionId,
      productinfo: "RSA Policy",
      amount: this.transactionDetails.amount,
      email: this.requestIntegrity.decryptUsingAES256(
        this.transactionDetails.email
      ),
      firstname: this.transactionDetails.name,
      surl: `${this._apiUrl}/api/v2/PaymentGateway/payu/success?host=${window.location.host}`,
      furl: `${this._apiUrl}/api/v2/PaymentGateway/payu/fail?host=${window.location.host}`,
      phone: this.requestIntegrity.decryptUsingAES256(
        this.transactionDetails.phone
      ),
      hash: null,
    });

    const hash = this.generateHash(this.payuFormGroup);
    this.payuFormGroup.controls["hash"].setValue(hash);
  }

  generateHash(formGroup: FormGroup) {
    return this.requestIntegrity.HashInput(
      `${formGroup.controls["key"].value}|${formGroup.controls["txnid"].value}|${formGroup.controls["amount"].value}|${formGroup.controls["productinfo"].value}|${formGroup.controls["firstname"].value}|${formGroup.controls["email"].value}|||||||||||${this.appConfig.onePayuSalt}`
    );
  }
}
