import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../core/auth/auth.service';
import { AppConfig } from 'src/config/app.config';
import { Observable } from 'rxjs';

@Injectable()
export class LoginResolve implements Resolve<any> {
 _apiUrl: string;
 constructor(private  dataservice: AuthService, private appConfig : AppConfig,){
  this._apiUrl = appConfig.baseUrl;
 }

 resolve(route:ActivatedRouteSnapshot,
        state:RouterStateSnapshot,
       ): Observable<any> {
    return this.dataservice.get(this._apiUrl + "/api/v1/Client/ClientConfig/" + window.location.host);
    //return this.dataservice.get("https://localhost:44331/weatherforecast/CheckResponseHash/11");

  }
}
